import React, {useContext, useEffect, useState} from 'react';

import '../styles/CCTV.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faChevronUp,
    faClock,
    faFolder,
    faLocation, faSnowman, faUmbrella
} from "@fortawesome/free-solid-svg-icons";

import {faChartBullet, faChartGantt} from "@fortawesome/pro-duotone-svg-icons"
import RTSPPlayer from "../BaseComponents/RTSPPlayer";
import {GlobalContext} from "../page/Main";
import {VtoL, VtoP} from "../modules/extractLocation";
import HalfDonutChartWithPins from "../BaseComponents/HalfDonutChart";
import Sensor1HourChart from "../BaseComponents/Sensor1HourChart";
import {backendServer} from "../App";
import locationsData from "../modules/locations.json";
import {faSensorAlert, faTimes} from "@fortawesome/pro-regular-svg-icons";
import {BaseButton} from "../BaseComponents/BaseButton";
import {generateNaturalIncreaseData} from "../modules/lib/tool";
import RealTimeWaterLevelChart from "../BaseComponents/RealTimeWaterLevelChart";


const TreeItem = ({ item, onToggle, isOpen, onClicked, focusLocation, subClicked }) => {
    const hasChildren = item.children && item.children.length > 0;

    const handleToggle = (locationValue, e) => {
        e.stopPropagation();

        if(locationValue){
            subClicked(locationValue);
        }
        const path = item.path || item.name; // 고유 경로가 없으면 이름을 사용
        onToggle(path);
    };
    const handleClick = (value, e) => {
        if (!hasChildren && value !== null && value !== undefined) {
            e.stopPropagation(); // 상위 항목으로의 이벤트 전파를 방지
            onClicked(value); // 클릭된 항목에 대한 처리
        }
    };

    return (
        <>
            <div className="row tree-item"
                 style={{alignItems: "center", backgroundColor: focusLocation === item.locationValue ? '#666' : ''}}
                 onClick={hasChildren ? handleToggle.bind(null, item.locationValue) : handleClick.bind(null, item.locationValue)}
            >
                {hasChildren || item.isRoot ? (
                    <FontAwesomeIcon style={{margin: "0 4px 0 0"}} size='2xs' icon={faFolder}/>
                ) : (
                    <FontAwesomeIcon style={{margin: "0 4px"}} size='2xs' icon={item.childIcon}/>
                )}

                {hasChildren || item.isRoot ? (
                    <>
                        {item.isSub ? (
                            <>
                                <p style={{fontSize: 12, margin: 0}}>{item.name}</p>
                            </>
                        ) : (
                            <>
                                <p className="bold" style={{margin: 0}}>{item.name}</p>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        {item.isSub ? (
                            <>
                                <p style={{fontSize: 12, margin: 0}}>{item.name}</p>
                            </>
                        ) : (
                            <>
                                <p style={{fontSize: 10, margin: 0}}>{item.name}</p>
                            </>
                        )}
                    </>
                )}

                {((hasChildren || item.isRoot)) && (
                    <FontAwesomeIcon style={{margin: "0 0 0 16px"}} size='2xs' icon={isOpen ? faChevronDown : faChevronUp}/>
                )}
            </div>
            {isOpen[`${item.path}`] && hasChildren && (
                <div className="tree-children" style={{overflow: 'hidden', transition: 'max-height 0.3s ease-in-out'}}>
                    {item.children.map((child, index) => (
                        <TreeItem
                            key={index}
                            item={{ ...child, path: `${item.path || item.name}/${child.name}` }}
                            onToggle={onToggle}
                            onClicked={onClicked}
                            focusLocation={focusLocation}
                            isOpen={isOpen}
                            subClicked={subClicked}
                        />
                    ))}
                </div>
            )}
        </>
    );
};

function DataChart() {
    const { focusLocation, setFocusLocation, weatherData, setWeatherData, setActiveForm } = useContext(GlobalContext);
    const [hovered, setHovered] = useState(null);

    const [setupValue, setSetupValue] = useState([]);
    const [currentValues, setCurrentValues] = useState([]);
    const [weatherCount, setWeatherCount] = useState(0);
    const [dataCount, setDataCount ] = useState(0);
    const [ offsets , setOffsets ] = useState([]);

    /**
     * 팝업 관련(임시용)
     */
    const [ isPopup, setIsPopup ] = useState(false)
    const [ sampleData, setSampleData ] = useState(generateNaturalIncreaseData(103.2, 140.2))

    const cctvData = [
        {
            isRoot: true,
            name: "대한민국",
            childIcon: faLocation,
            children: [
                {
                    isRoot: true,
                    name: "전라남도",
                    children: [
                        {
                            childIcon: faLocation,
                            name: "월출지하차도",
                            locationValue: "001",
                        },
                    ],
                },
                {
                    isRoot: true,
                    name: "전라북도",
                    children: [
                        {
                            childIcon: faLocation,
                            name: "월드컵지하차도",
                            locationValue: "002",
                        },
                    ],
                },
                {
                    isRoot: true,
                    name: "충청북도",
                },
                {
                    isRoot: true,
                    name: "충청남도",
                },
                {
                    isRoot: true,
                    name: "경상북도",
                },
                {
                    isRoot: true,
                    name: "경상남도",
                    children: [
                        {
                            childIcon: faLocation,
                            name: "남해지하차도",
                            locationValue: "003",
                        },
                    ],
                },
                {
                    isRoot: true,
                    name: "강원특별자치도",
                },
                {
                    isRoot: true,
                    name: "경기도",
                },
                {
                    isRoot: true,
                    name: "제주특별자치도",
                },
            ]
        },
    ];

    useEffect(() => {
        // 서버로부터 데이터를 요청하고 응답을 처리하는 비동기 함수
        const fetchData = async () => {
            try {
                const response = await fetch(`https://${backendServer}/get/sensorDatas`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    // 필요한 경우 요청 본문에 데이터 포함
                    body: JSON.stringify({
                        type: '1h',
                        locations_code: focusLocation, // 예시 데이터, 실제 요구사항에 맞게 수정해야 함
                    }),

                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const result = await response.json(); // 응답 본문을 JSON 형태로 파싱
                if (Object.keys(result).length > 0) {
                    setCurrentValues(result.groupedByCameraCode);
                    setOffsets(result.offsets);
                    setSetupValue(result.limit);
                } else {
                    setCurrentValues([]);
                    setOffsets([]);
                }
            } catch (error) {
                console.error('There was a problem with the fetch operation');
            }
        };

        fetchData(); // 비동기 함수 호출
    }, [focusLocation]);

    useEffect(() => {
        // 서버로부터 데이터를 요청하고 응답을 처리하는 비동기 함수
        const fetchData = async () => {
            try {
                const response = await fetch(`https://${backendServer}/get/sensorDatas/COUNT`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    // 필요한 경우 요청 본문에 데이터 포함
                    body: JSON.stringify({
                        locations_code: focusLocation, // 예시 데이터, 실제 요구사항에 맞게 수정해야 함
                    }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const result = await response.json(); // 응답 본문을 JSON 형태로 파싱
                setDataCount(result.count);
            } catch (error) {
                console.error('There was a problem with the fetch operation');
            }
        };

        const fetchWeatherCount = async () => {
            try {
                const response = await fetch(`https://${backendServer}/getWeather/Count`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const result = await response.json(); // 응답 본문을 JSON 형태로 파싱
                setWeatherCount(result.Count);
            } catch (error) {
                console.error('There was a problem with the fetch operation');
            }
        };

        fetchData(); // 비동기 함수 호출
        fetchWeatherCount();
    }, []);

    const initializeOpenTreeItems = (data, path = '') => {
        const focusLocationPath = VtoP(focusLocation);
        return data.reduce((acc, item) => {
            const currentPath = path ? `${path}/${item.name}` : item.name;
            const isOpen = focusLocationPath && focusLocationPath.startsWith(currentPath);

            acc[currentPath] = isOpen;
            if (item.children) {
                Object.assign(acc, initializeOpenTreeItems(item.children, currentPath));
            }
            return acc;
        }, {});
    };

    const [openTreeItems, setOpenTreeItems] = useState(() => initializeOpenTreeItems(cctvData));

    const onElementClick = (locationValue) => {
        setFocusLocation(locationValue)
    };

    const toggleTreeItem = (path) => {
        setOpenTreeItems(prevOpenTreeItems => ({
            ...prevOpenTreeItems,
            [path]: !prevOpenTreeItems[path],
        }));
    };

    const subClicked = (value) => {
        setFocusLocation(value);
    }

    const DataTree = React.memo(({ data }) => {
        return (
            <div className="cctv-tree">
                {data.map((item, index) => (
                    <TreeItem
                        key={index}
                        item={{ ...item, path: item.name }}
                        onToggle={toggleTreeItem}
                        onClicked={(locationValue) => onElementClick(locationValue)}
                        focusLocation={focusLocation}
                        isOpen={openTreeItems} // 논리 NOT 연산자를 두 번 사용하여 undefined를 false로 변환
                        subClicked={subClicked}
                    />
                ))}
            </div>
        );
    }, (prevProps, nextProps) => {
        return prevProps.data === nextProps.data;
    });

    /**
     * type,
     * 0 : 오늘
     * 1 : 어제
     */
    const getWeatherText = (type, category, hour = -1) => {
        let data;
        if (type === 0 && weatherData.today) {
            data = weatherData.today.filter(d => d.category === category);
        } else if (type === 1 && weatherData.yesterday) {
            data = weatherData.yesterday.filter(d => d.category === category);
        }

        if (hour !== -1 && data) {
            // 특정 시간의 데이터를 찾는 경우
            const specificHourData = data.find(d => d.fcstTime === `${String(hour).padStart(2, '0')}00`);
            if (specificHourData) {
                return specificHourData.fcstValue ? specificHourData.fcstValue : '0';
            }
        } else {
            if(data){
                // 전체 시간에 대한 데이터를 처리하는 경우
                const sum = data.reduce((acc, curr) => {
                    const value = curr.fcstValue === '강수없음' || curr.fcstValue === '적설없음' ? 0 : parseInt(curr.fcstValue, 10);
                    return isNaN(value) ? acc : acc + value;
                }, 0);
                if (sum > 0) {
                    return sum.toString();
                }
            }
        }

        return '0';
    }

    const now = new Date();
    const currentHour = now.getHours();
    const totalNodeCount = locationsData.locations.reduce((acc, location) => acc + location.nodeCount, 0);

    return (
        <div className="row full-size" style={{position: "relative", overflow: "hidden"}}>
            <div className="column full-size" style={{width: "80%", position: "relative", overflow: "hidden"}}>
                <div className="row full-size-width" style={{height: "3%", position: "relative", alignItems: "center", borderBottom: "1px solid #ffffff50"}}>
                    <FontAwesomeIcon icon={faChartGantt} size="xs" style={{margin: 8}} onClick={() => setIsPopup(!isPopup)} />
                    <p className="bold" style={{width: "calc(80% + 32px)", margin: "16px 16px 16px 0", fontSize: 13, color: "white"}}>종합 데이터 보기  - {VtoL(focusLocation)}</p>
                </div>
                <div className="row" style={{background: "#464646", margin: "1rem", padding: "0.5rem"}}>
                    <div className="column full-size">
                        <div className="row full-size-width" style={{fontSize: 14}}>
                            <FontAwesomeIcon icon={faUmbrella}/>
                            <div className="seperator-horz-half"></div>
                            <p style={{margin: 0}} className="full-size bold">강우</p>
                            <div className="row align-center">
                                <FontAwesomeIcon style={{fontSize: 10}} icon={faClock}/>
                                <p style={{fontSize: 10, margin: '0 4px', whiteSpace: "nowrap"}}>현시간 기준</p>
                            </div>
                        </div>
                        <div className="row" style={{margin: "0.5rem"}}>
                            <div className="column full-size-width" style={{textAlign: "center"}}>
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222", padding: "0.4rem", margin: 0, fontSize: 12}}>　</p>
                                <p className="text-align-center" style={{background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>강우량</p>
                            </div>

                            <div className="column full-size-width">
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 12}}>1시간</p>
                                <p className="text-align-center" style={{whiteSpace: "nowrap", background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>{getWeatherText(0, 'PCP', currentHour)}</p>
                            </div>
                            <div className="column full-size-width">
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 12}}>금일</p>
                                <p className="text-align-center" style={{whiteSpace: "nowrap",background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>{getWeatherText(0, 'PCP')}</p>
                            </div>
                            <div className="column full-size-width">
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 12}}>전일</p>
                                <p className="text-align-center" style={{whiteSpace: "nowrap",background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>{getWeatherText(1, 'PCP')}</p>
                            </div>
                            <div className="column full-size-width">
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 12}}>단위</p>
                                <p className="text-align-center" style={{background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>mm</p>
                            </div>
                        </div>
                    </div>
                    <div className="seperator-horz-half"></div>
                    <div className="column full-size">
                        <div className="row full-size-width" style={{fontSize: 14}}>
                            <FontAwesomeIcon icon={faUmbrella}/>
                            <div className="seperator-horz-half"></div>
                            <p style={{margin: 0}} className="full-size bold">초단기예측강우</p>
                            <div className="row align-center">
                                <FontAwesomeIcon style={{fontSize: 10}} icon={faClock}/>
                                <p style={{fontSize: 10, margin: '0 4px', whiteSpace: "nowrap"}}>현시간 기준</p>
                            </div>
                        </div>
                        <div className="row" style={{margin: "0.5rem"}}>
                            <div className="column full-size-width" style={{textAlign: "center"}}>
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222", padding: "0.4rem", margin: 0, fontSize: 12}}>{String(currentHour).padStart(2, '0')}시</p>
                                <p className="text-align-center" style={{whiteSpace: "nowrap",background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>{getWeatherText(0, 'PCP', currentHour)}</p>
                            </div>
                            <div className="column full-size-width">
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 12}}>{String(currentHour + 1).padStart(2, '0')}시</p>
                                <p className="text-align-center" style={{whiteSpace: "nowrap",background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>{getWeatherText(0, 'PCP', currentHour + 1)}</p>
                            </div>
                            <div className="column full-size-width">
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 12}}>{String(currentHour + 2).padStart(2, '0')}시</p>
                                <p className="text-align-center" style={{whiteSpace: "nowrap",background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>{getWeatherText(0, 'PCP', currentHour + 2)}</p>
                            </div>
                            <div className="column full-size-width">
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 12}}>{String(currentHour + 3).padStart(2, '0')}시</p>
                                <p className="text-align-center" style={{whiteSpace: "nowrap",background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>{getWeatherText(0, 'PCP', currentHour + 3)}</p>
                            </div>
                            <div className="column full-size-width">
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 12}}>{String(currentHour + 4).padStart(2, '0')}시</p>
                                <p className="text-align-center" style={{whiteSpace: "nowrap",background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>{getWeatherText(0, 'PCP', currentHour + 4)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="seperator-horz-half"></div>
                    <div className="column full-size">
                        <div className="row full-size-width" style={{fontSize: 14}}>
                            <FontAwesomeIcon icon={faSnowman}/>
                            <div className="seperator-horz-half"></div>
                            <p style={{margin: 0}} className="full-size bold">적설</p>
                            <div className="row align-center">
                                <FontAwesomeIcon style={{fontSize: 10}} icon={faClock}/>
                                <p style={{fontSize: 10, margin: '0 4px', whiteSpace: "nowrap"}}>현시간 기준</p>
                            </div>
                        </div>
                        <div className="row" style={{margin: "0.5rem"}}>
                            <div className="column full-size-width" style={{textAlign: "center"}}>
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222", padding: "0.4rem", margin: 0, fontSize: 12}}>　</p>
                                <p className="text-align-center" style={{background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>적설량</p>
                            </div>
                            <div className="column full-size-width">
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 12}}>1시간</p>
                                <p className="text-align-center" style={{whiteSpace: "nowrap",background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>{getWeatherText(0, 'SNO', currentHour)}</p>
                            </div>
                            <div className="column full-size-width">
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 12}}>금일</p>
                                <p className="text-align-center" style={{whiteSpace: "nowrap",background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>{getWeatherText(0, 'SNO')}</p>
                            </div>
                            <div className="column full-size-width">
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 12}}>전일</p>
                                <p className="text-align-center" style={{whiteSpace: "nowrap",background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>{getWeatherText(1, 'SNO')}</p>
                            </div>
                            <div className="column full-size-width">
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 12}}>단위</p>
                                <p className="text-align-center" style={{background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>cm</p>
                            </div>
                        </div>
                    </div>
                    <div className="seperator-horz-half"></div>
                    <div className="column full-size">
                        <div className="row full-size-width" style={{fontSize: 14}}>
                            <FontAwesomeIcon icon={faSnowman}/>
                            <div className="seperator-horz-half"></div>
                            <p style={{margin: 0}} className="full-size bold">초단기예측적설</p>
                            <div className="row align-center">
                                <FontAwesomeIcon style={{fontSize: 10}} icon={faClock}/>
                                <p style={{fontSize: 10, margin: '0 4px', whiteSpace: "nowrap"}}>현시간 기준</p>
                            </div>
                        </div>
                        <div className="row" style={{margin: "0.5rem"}}>
                            <div className="column full-size-width" style={{textAlign: "center"}}>
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222", padding: "0.4rem", margin: 0, fontSize: 12}}>{String(currentHour).padStart(2, '0')}시</p>
                                <p className="text-align-center" style={{whiteSpace: "nowrap",background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>{getWeatherText(0, 'SNO', currentHour)}</p>
                            </div>
                            <div className="column full-size-width">
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 12}}>{String(currentHour + 1).padStart(2, '0')}시</p>
                                <p className="text-align-center" style={{whiteSpace: "nowrap",background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>{getWeatherText(0, 'SNO', currentHour + 1)}</p>
                            </div>
                            <div className="column full-size-width">
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 12}}>{String(currentHour + 2).padStart(2, '0')}시</p>
                                <p className="text-align-center" style={{whiteSpace: "nowrap",background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>{getWeatherText(0, 'SNO', currentHour + 2)}</p>
                            </div>
                            <div className="column full-size-width">
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 12}}>{String(currentHour + 3).padStart(2, '0')}시</p>
                                <p className="text-align-center" style={{whiteSpace: "nowrap",background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>{getWeatherText(0, 'SNO', currentHour + 3)}</p>
                            </div>
                            <div className="column full-size-width">
                                <p className="text-align-center" style={{background: "#525252", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 12}}>{String(currentHour + 4).padStart(2, '0')}시</p>
                                <p className="text-align-center" style={{whiteSpace: "nowrap",background: "#8c8c8c", border: "1px solid #222222",padding: "0.4rem", margin: 0, fontSize: 10}}>{getWeatherText(0, 'SNO', currentHour + 4)}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <p className="bold" style={{width: "calc(80% + 32px)", marginLeft: 16, marginTop: 0, marginBottom: 0, fontSize: 14, color: "white"}}>개소별 데이터(최근 1개)</p>
                <div className="column full-size-width" style={{position: "relative"}}>

                    <div className="row align-center" style={{maxWidth: "100%", position: "relative", overflowX: 'auto', overflowY: 'hidden'}}>
                        {
                            Object.entries(currentValues).map(([cameraCode, sensorDatas], index) => {
                                // cameraCode에 해당하는 오프셋 값을 찾습니다.
                                const offset = offsets.find(offset => offset.camera_code === cameraCode);

                                // sensorDatas에 오프셋 값을 추가합니다.
                                const sensorDatasWithOffset = sensorDatas.map(sensorData => ({
                                    sensor_1: sensorData.sensor1,
                                    sensor_2: sensorData.sensor2,
                                    dateStr: sensorData.create_at,
                                    raider_offset: offset ? offset.raider_offset : 0 // 오프셋이 없는 경우 null 처리
                                }));

                                return (
                                    <HalfDonutChartWithPins
                                        key={cameraCode}
                                        setupValue={setupValue[index]}
                                        sensorDatas={sensorDatasWithOffset}
                                        sensorNo={cameraCode}
                                    />
                                );
                            })
                        }
                    </div>
                    <Sensor1HourChart setupValue={setupValue} cameraData={{currentValues, offsets}} />
                    <div className="row">
                        <div className="seperator-horz-half"/>
                        <div className="row full-size-width" style={{background: "rgba(52,64,73,0.7)", padding: "0.4rem"}}>
                            <p className="text-align-left full-size-width" style={{padding: "0.4rem", margin: 0, fontSize: 12}}>총 개소 데이터</p>
                            <p className="text-align-right full-size-width" style={{padding: "0.4rem", margin: 0, fontSize: 12}}>{dataCount} 개</p>
                        </div>
                        <div className="seperator-horz-half"/>
                        <div className="row full-size-width" style={{background: "rgba(52,64,73,0.7)", padding: "0.4rem"}}>
                            <p className="text-align-left full-size-width" style={{padding: "0.4rem", margin: 0, fontSize: 12}}>기상청 연계 데이터</p>
                            <p className="text-align-right full-size-width" style={{padding: "0.4rem", margin: 0, fontSize: 12}}>{weatherCount} 건</p>
                        </div>
                        <div className="seperator-horz-half"/>
                        <div className="row full-size-width" style={{background: "rgba(52,64,73,0.7)", padding: "0.4rem"}}>
                            <p className="text-align-left full-size-width" style={{padding: "0.4rem", margin: 0, fontSize: 12}}>총 개소</p>
                            <p className="text-align-right full-size-width" style={{padding: "0.4rem", margin: 0, fontSize: 12}}>{totalNodeCount} 개</p>
                        </div>
                        <div className="seperator-horz-half"/>
                    </div>
                </div>
                {(isPopup) && (
                    <div className="column" style={{
                        position: "absolute",
                        width: "35%",
                        height: "auto",
                        left: "2.5rem",
                        top: "50%",
                        transform: "translateY(-50%)",
                        borderRadius: "1rem",
                        border: "1px solid #dddddd",
                        background: "#4f4f4f",
                        zIndex: 10,
                    }}>
                        <div className="column"
                             style={{
                                 background: "#cccccc",
                                 borderTopLeftRadius: "1rem",
                                 borderTopRightRadius: "1rem"
                             }}>
                            <div className="row align-center-left" style={{padding: "0.5rem 1rem"}}>
                                <FontAwesomeIcon style={{color: "black"}} icon={faSensorAlert}/>
                                <p className="row full-size-width" style={{
                                    margin: "0 0.5rem",
                                    color: "black",
                                    fontSize: 14
                                }}>월출지하차도(2번개소)<p
                                    style={{margin: 0, padding: 0, fontWeight: "bold", color: "#d39726"}}>[경계]</p></p>
                                <FontAwesomeIcon style={{color: "black", cursor: "pointer"}} icon={faTimes} onClick={() => setIsPopup(false)}/>
                            </div>
                        </div>
                        <div className="seperator-half"/>
                        <div className="column"
                             style={{width: "100%", padding: "0.5rem", boxSizing: "border-box"}}>
                            <div className="row align-center full-size-width">
                                <div style={{margin: "0.3rem"}}>
                                    <RTSPPlayer
                                        streamUrl={`https://${backendServer}/live/001/002/stream.m3u8`}
                                        style={{
                                            objectFit: 'cover',
                                            border: "2px solid #00000080",
                                            borderRadius: "0.5rem", width: "100%", height: 150
                                        }}/>
                                </div>
                            </div>
                            <div className="column" style={{width: "100%", boxSizing: "border-box"}}>
                                <RealTimeWaterLevelChart data={sampleData} />
                            </div>
                            <div className="row align-center full-size-width" style={{marginTop: 24}}>
                                <BaseButton className="full-size-width" style={{margin: "0 0.5rem"}} onClick={
                                    () => {
                                        setActiveForm({form: 'CCTV 보기', param: { target: 2, data: sampleData }})
                                    }
                                }>위치로
                                    이동</BaseButton>
                                <BaseButton className="full-size-width" style={{margin: "0 0.5rem"}}
                                            onClick={() => setIsPopup(false)}>닫기</BaseButton>
                            </div>
                            <div className="seperator-half"/>
                        </div>
                    </div>
                )}
            </div>
            <div className="column full-size" style={{width: "20%", position: "relative", overflow: 'hidden'}}>
                <div className="row full-size-width" style={{height: "3%", position: "relative", alignItems: "center", borderBottom: "1px solid #ffffff50"}}>
                    <p className="bold" style={{width: "20%",marginLeft: 16, fontSize: 13, color: "white"}}>지역별</p>
                </div>
                <DataTree data={cctvData} />
            </div>
        </div>
    );
}

export default DataChart;
