import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons";
import {Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import React from "react";


/**
 * 임시용 수위차트
 */
const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{ fontSize: "14px", backgroundColor: 'white', border: '1px solid #ccc', borderRadius: "0.5rem", padding: '10px' }}>
                <p className="label" style={{color: "black"}}>수위</p>
                <div className="column" style={{ fontSize: "10px", backgroundColor: 'white', border: '1px solid #ccc', borderRadius: "0.5rem", padding: '5px' }}>
                    <div className="row align-center" >
                        <FontAwesomeIcon icon={faCircle} style={{ fontSize: "6px", color: payload[0].fill }}/>
                        <p className="intro" style={{ margin: "4px", color: "purple" }}>{`수위: `}</p>
                        <p className="intro" style={{ margin: "4px", color: "purple" }}>{payload[0].value} mm</p>
                    </div>
                </div>
            </div>
        );
    }
    return null;
};
function RealTimeWaterLevelChart({ data }) {
    // 데이터가 시간순으로 정렬되어 있다고 가정합니다.
    const sortedData = [...data].sort((a, b) => new Date(a.Time) - new Date(b.Time));

    // Water Level 중 최대값 찾기
    const maxWaterLevel = Math.max(...sortedData.map(item => item["Water Level"]));

    return (
        <div style={{ position: 'relative', marginTop: 30, width: "100%", height: 'auto' }}>
            <p style={{ margin: "0 0 0.5rem 0", fontSize: 14 }}>실시간 수위</p>
            <ResponsiveContainer width="100%" height={100} style={{ display: "flex", borderRadius: "0.5rem", padding: "1rem 0", background: "#676767" }} className="align-center">
                <LineChart data={sortedData} style={{ display: "flex", right: "18px" }} className="align-center">
                    <XAxis stroke="#ffffff" dataKey="Time" tick={{ fontSize: 10 }} />
                    <YAxis
                        yAxisId="water_level"
                        stroke="#ffffff"
                        tick={{ fontSize: 10 }}
                        tickFormatter={(value, entry) => {
                            const valueStr = value.toFixed(1);
                            return valueStr;
                        }}
                        domain={[90, maxWaterLevel * 1.1]}
                    />
                    <ReferenceLine
                        yAxisId="water_level"
                        y={120}
                        stroke={"#ffc658"}
                        label={{
                            value: "경계값",
                            position: 'insideRight',
                            dx: 0 - (45),
                            dy: 10,
                            fontSize: 10,
                            fill: "#ffc658"
                        }}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend
                        iconType="circle"
                        iconSize={4}
                        wrapperStyle={{ fontSize: '12px', position: 'absolute', right: "calc(50% - 48px)" }}
                        align="right"
                        formatter={(value, entry, index) => {
                            return <span style={{ color: entry.color }}>수위</span>;
                        }}
                    />
                    <Line
                        yAxisId="water_level"
                        type="monotone"
                        dataKey="Water Level"
                        stroke="#8884d8"
                        dot={(props) => {
                            const { payload } = props;
                            if (parseFloat(payload['Water Level']) > 120.0) {
                                return <circle cx={props.cx} cy={props.cy} r={3} fill={"#ffc658"} />;
                            }else {
                                return <></>;
                            }
                        }}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
}

export default RealTimeWaterLevelChart