import React, {useContext, useState} from 'react';

import temp1 from '../temp/images/다운로드.jpg';
import temp2 from '../temp/images/2023072701002809800155501.jpg';
import temp3 from '../temp/images/417397_429506_4251.jpg';
import temp4 from '../temp/images/346389_239633_5246.jpg';
import '../styles/CCTV.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCamera,
    faChevronDown,
    faChevronUp,
    faFolder, faLocation
} from "@fortawesome/free-solid-svg-icons";
import {faCameraCctv} from "@fortawesome/pro-duotone-svg-icons"
import RTSPPlayer from "../BaseComponents/RTSPPlayer";
import {GlobalContext} from "../page/Main";
import {value} from "lodash/seq";
import {VtoL, VtoP} from "../modules/extractLocation";
import UsageDoughnutChart from "../BaseComponents/UsageDoughnutChart";
import locationsData from '../modules/locations.json';
import {backendServer} from "../App";
import RealTimeWaterLevelChart from "../BaseComponents/RealTimeWaterLevelChart";
import {faSensorAlert, faTimes} from "@fortawesome/pro-regular-svg-icons";
import {BaseButton} from "../BaseComponents/BaseButton";

const TreeItem = ({ item, onToggle, isOpen, onClicked, selectedImage, subClicked }) => {
    const hasChildren = item.children && item.children.length > 0;

    const handleToggle = (locationValue, e) => {
        e.stopPropagation();

        if(locationValue){
            subClicked(locationValue);
        }
        const path = item.path || item.name; // 고유 경로가 없으면 이름을 사용
        onToggle(path, locationValue);
    };
    const handleClick = (value, locationValue, e) => {
        if (!hasChildren && value !== null && value !== undefined) {
            e.stopPropagation(); // 상위 항목으로의 이벤트 전파를 방지
            onClicked(value, locationValue); // 클릭된 항목에 대한 처리
        }
    };

    return (
        <>
            <div className="row tree-item"
                 style={{alignItems: "center", backgroundColor: selectedImage === item.value ? '#666' : ''}}
                 onClick={hasChildren ? handleToggle.bind(null, item.locationValue) : handleClick.bind(null, item.value, item.locationValue)}
            >
                {hasChildren || item.isRoot ? (
                    <FontAwesomeIcon style={{margin: "0 4px 0 0"}} size='2xs' icon={faFolder}/>
                ) : (
                    <FontAwesomeIcon style={{margin: "0 4px"}} size='2xs' icon={item.childIcon}/>
                )}

                {hasChildren || item.isRoot ? (
                    <>
                        {item.isSub ? (
                            <>
                                <p style={{fontSize: 12, margin: 0}}>{item.name}</p>
                            </>
                        ) : (
                            <>
                                <p className="bold" style={{margin: 0}}>{item.name}</p>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        {item.isSub ? (
                            <>
                                <p style={{fontSize: 12, margin: 0}}>{item.name}</p>
                            </>
                        ) : (
                            <>
                                <p style={{fontSize: 10, margin: 0}}>{item.name}</p>
                            </>
                        )}
                    </>
                )}

                {((hasChildren || item.isRoot)) && (
                    <FontAwesomeIcon style={{margin: "0 0 0 16px"}} size='2xs' icon={isOpen ? faChevronDown : faChevronUp}/>
                )}
            </div>
            {isOpen[`${item.path}`] && hasChildren && (
                <div className="tree-children" style={{overflow: 'hidden', transition: 'max-height 0.3s ease-in-out'}}>
                    {item.children.map((child, index) => (
                        <TreeItem
                            key={index}
                            item={{ ...child, path: `${item.path || item.name}/${child.name}` }}
                            onToggle={onToggle}
                            onClicked={onClicked}
                            selectedImage={selectedImage}
                            isOpen={isOpen}
                            subClicked={subClicked}
                        />
                    ))}
                </div>
            )}
        </>
    );
};

function CCTV({ param = { target : null } }) {
    const { focusLocation, setFocusLocation } = useContext(GlobalContext);
    const [hovered, setHovered] = useState(null);
    const [selectedImage, setSelectedImage] = useState(param.target ? param.target : null);

    // focusLocation에 해당하는 로케이션 정보 찾기
    const currentLocation = locationsData.locations.find(location => location.code === focusLocation);

    // 카메라 노드를 2개씩 그룹화
    const cameraGroups = currentLocation?.cameraNodes ? currentLocation.cameraNodes.reduce((result, value, index, array) => {
        if (index % 2 === 0) result.push(array.slice(index, index + 2));
        return result;
    }, []) : [];

    const cctvData = [
        {
            isRoot: true,
            name: "대한민국",
            childIcon: faLocation,
            children: [
                {
                    isRoot: true,
                    name: "전라남도",
                    children: [
                        {
                            isSub: true,
                            name: "월출지하차도",
                            locationValue: "001",
                            children: [
                                {
                                    childIcon: faCamera,
                                    name: "CCTV1",
                                    locationValue: "001",
                                    value: 1
                                },
                                {
                                    childIcon: faCamera,
                                    name: "CCTV2",
                                    locationValue: "001",
                                    value: 2
                                },
                                {
                                    childIcon: faCamera,
                                    name: "CCTV3",
                                    locationValue: "001",
                                    value: 3
                                },
                                {
                                    childIcon: faCamera,
                                    name: "CCTV4",
                                    locationValue: "001",
                                    value: 4
                                }
                            ],
                        },
                    ],
                },
                {
                    isRoot: true,
                    name: "전라북도",
                    children: [
                        {
                            isSub: true,
                            name: "월드컵지하차도",
                            locationValue: "002",
                            children: [
                                {
                                    childIcon: faCamera,
                                    name: "CCTV1",
                                    locationValue: "002",
                                    value: 5
                                },
                                {
                                    childIcon: faCamera,
                                    name: "CCTV2",
                                    locationValue: "002",
                                    value: 6
                                },
                                {
                                    childIcon: faCamera,
                                    name: "CCTV3",
                                    locationValue: "002",
                                    value: 7
                                },
                                {
                                    childIcon: faCamera,
                                    name: "CCTV4",
                                    locationValue: "002",
                                    value: 8
                                }
                            ],
                        },
                    ],
                },
                {
                    isRoot: true,
                    name: "충청북도",
                },
                {
                    isRoot: true,
                    name: "충청남도",
                },
                {
                    isRoot: true,
                    name: "경상북도",
                },
                {
                    isRoot: true,
                    name: "경상남도",
                    children: [
                        {
                            isSub: true,
                            name: "남해지하차도",
                            locationValue: "003",
                            children: [
                                {
                                    childIcon: faCamera,
                                    name: "CCTV1",
                                    locationValue: "003",
                                    value: 9
                                },
                                {
                                    childIcon: faCamera,
                                    name: "CCTV2",
                                    locationValue: "003",
                                    value: 10
                                },
                                {
                                    childIcon: faCamera,
                                    name: "CCTV3",
                                    locationValue: "003",
                                    value: 11
                                },
                                {
                                    childIcon: faCamera,
                                    name: "CCTV4",
                                    locationValue: "003",
                                    value: 12
                                }
                            ],
                        },
                    ],
                },
                {
                    isRoot: true,
                    name: "강원특별자치도",
                },
                {
                    isRoot: true,
                    name: "경기도",
                },
                {
                    isRoot: true,
                    name: "제주특별자치도",
                },
            ]
        },
    ];

    const initializeOpenTreeItems = (data, path = '') => {
        const focusLocationPath = VtoP(focusLocation);
        return data.reduce((acc, item) => {
            const currentPath = path ? `${path}/${item.name}` : item.name;
            const isOpen = focusLocationPath && focusLocationPath.startsWith(currentPath);

            acc[currentPath] = isOpen;
            if (item.children) {
                Object.assign(acc, initializeOpenTreeItems(item.children, currentPath));
            }
            return acc;
        }, {});
    };

    const [openTreeItems, setOpenTreeItems] = useState(() => initializeOpenTreeItems(cctvData));

    const onImageClick = (id, locationValue) => {
        if(locationValue !== focusLocation){
            setFocusLocation(locationValue);
        }

        if(id === selectedImage){
            setSelectedImage(null)
        }else {
            setSelectedImage(id)
        }
    };

    const toggleTreeItem = (path, locationValue) => {
        setOpenTreeItems(prevOpenTreeItems => ({
            ...prevOpenTreeItems,
            [path]: prevOpenTreeItems[path] && (locationValue !== focusLocation) ? prevOpenTreeItems[path] : !prevOpenTreeItems[path],
        }));
    };

    const subClicked = (value) => {
        setFocusLocation(value);
    }

    const CCTVTree = React.memo(({ data }) => {
        return (
            <div className="cctv-tree">
                {data.map((item, index) => (
                    <TreeItem
                        key={index}
                        item={{ ...item, path: item.name }}
                        onToggle={toggleTreeItem}
                        onClicked={(index, locationValue) => onImageClick(index, locationValue)}
                        selectedImage={selectedImage}
                        isOpen={openTreeItems} // 논리 NOT 연산자를 두 번 사용하여 undefined를 false로 변환
                        subClicked={subClicked}
                    />
                ))}
            </div>
        );
    }, (prevProps, nextProps) => {
        return prevProps.data === nextProps.data;
    });

    return (
      <div className="column full-size" style={{position: "relative", overflow: "hidden"}}>
          <div className="row full-size-width" style={{height: "3%", position: "relative", alignItems: "center", borderBottom: "1px solid #ffffff50"}}>
              <FontAwesomeIcon icon={faCameraCctv} size="xs" style={{margin: 8}}/>
              <p className="bold" style={{width: "calc(80% + 32px)", margin: "16px 16px 16px 0", fontSize: 13, color: "white"}}>실시간 현황 모니터  - {VtoL(focusLocation)}</p>
              <p className="bold" style={{width: "20%",marginLeft: 16, fontSize: 13, color: "white"}}>CCTV</p>
          </div>
          <div className="row full-size-width" style={{height: "auto", position: "relative"}}>
              <div className="column full-size" style={{width: "80%",height: "auto", position: "relative", overflow: 'hidden'}}>
                  {selectedImage ? (
                      <>
                          <div className={`image-container`} style={{width: "100%", height: "100%"}}
                               onClick={() => onImageClick(selectedImage, focusLocation)}>
                              <RTSPPlayer sleep={0} key={`stream0`}
                                          streamUrl={`https://${backendServer}/live/${focusLocation}/${'00' + (selectedImage)}/stream.m3u8`}/>
                              {(param.data && param.target === selectedImage) && (
                                  <div className="column" style={{
                                      position: "absolute",
                                      width: "35%",
                                      height: "auto",
                                      right: "2.5rem",
                                      bottom: "-4rem",
                                      transform: "translateY(-50%)",
                                      borderRadius: "1rem",
                                      border: "1px solid #dddddd",
                                      background: "#4f4f4f",
                                      zIndex: 10,
                                      transition: "opacity 0.3s",
                                      opacity: 1
                                  }}
                                   onMouseEnter={(e) => {
                                       e.currentTarget.style.opacity = 0.4;
                                   }}
                                   onMouseLeave={(e) => {
                                       e.currentTarget.style.opacity = 1;
                                   }}
                                  >
                                      <div className="column"
                                           style={{width: "100%", padding: "0 1rem 2rem 1rem", boxSizing: "border-box"}}>
                                          <div className="column"
                                               style={{width: "100%", boxSizing: "border-box"}}>
                                              <RealTimeWaterLevelChart data={param.data}/>
                                          </div>
                                      </div>
                                  </div>

                              )}
                          </div>
                      </>
                  ) : (
                      <>
                          {cameraGroups.map((group, index) => (
                              <div key={index} className="row align-center" style={{ height: 'auto', position: "relative", overflow: 'hidden' }}>
                                  {group.map((cameraNode, idx) => (
                                      <div key={idx} className="column align-center image-container" onClick={() => {
                                          onImageClick(parseInt(cameraNode), focusLocation)
                                      }}>
                                          <p style={{ width: "100%", boxSizing: "border-box", padding: "0.5rem", margin: 0, fontSize: 14, zIndex: 11 }}>CAM{cameraNode}</p>
                                          <RTSPPlayer sleep={0} key={`stream${idx}`} streamUrl={`https://${backendServer}/live/${focusLocation}/${cameraNode}/stream.m3u8`} />
                                      </div>
                                  ))}
                              </div>
                          ))}
                      </>
                  )}
              </div>
              <div className="column full-size" style={{width: "20%", position: "relative", overflow: 'hidden'}}>
                  <CCTVTree data={cctvData} />
              </div>
          </div>
      </div>
    );
}

export default CCTV;
