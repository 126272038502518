import React, {useContext, useEffect, useState} from 'react';

import temp1 from '../temp/images/다운로드.jpg';
import temp2 from '../temp/images/2023072701002809800155501.jpg';
import temp3 from '../temp/images/417397_429506_4251.jpg';
import temp4 from '../temp/images/346389_239633_5246.jpg';
import '../styles/CCTV.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCamera,
    faChevronDown,
    faChevronUp,
    faFolder, faLocation, faTint
} from "@fortawesome/free-solid-svg-icons";
import {faCameraCctv} from "@fortawesome/pro-duotone-svg-icons"
import RTSPPlayer from "../BaseComponents/RTSPPlayer";
import {GlobalContext} from "../page/Main";
import {value} from "lodash/seq";
import {VtoL, VtoP} from "../modules/extractLocation";
import UsageDoughnutChart from "../BaseComponents/UsageDoughnutChart";
import locationsData from '../modules/locations.json';
import {backendServer} from "../App";
import {BaseAlert, BaseAlertDanger, BaseAlertWarn} from "../BaseComponents/BaseAlert";
import {faBuildings, faRuler} from "@fortawesome/pro-regular-svg-icons";
import {BaseLoadingInContainer} from "../BaseComponents/BaseLoading";

const TreeItem = ({ item, onToggle, isOpen, onClicked, selectedPosition, selectedLocation, subClicked }) => {
    const hasChildren = item.children && item.children.length > 0;

    const handleToggle = (locationValue, e) => {
        e.stopPropagation();

        if(locationValue){
            subClicked(locationValue);
        }
        const path = item.path || item.name; // 고유 경로가 없으면 이름을 사용
        onToggle(path, locationValue);
    };
    const handleClick = (value, locationValue, e) => {
        if (!hasChildren && value !== null && value !== undefined) {
            e.stopPropagation(); // 상위 항목으로의 이벤트 전파를 방지
            onClicked(value, locationValue); // 클릭된 항목에 대한 처리
        }
    };

    return (
        <>
            <div className="row tree-item"
                 style={{alignItems: "center", backgroundColor: (item.locationValue === selectedLocation)&& (selectedPosition === item.value) ? '#666' : ''}}
                 onClick={hasChildren ? handleToggle.bind(null, item.locationValue) : handleClick.bind(null, item.value, item.locationValue)}
            >
                {hasChildren || item.isRoot ? (
                    <FontAwesomeIcon style={{margin: "0 4px 0 0"}} size='2xs' icon={faFolder}/>
                ) : (
                    <FontAwesomeIcon style={{margin: "0 4px"}} size='2xs' icon={item.childIcon}/>
                )}

                {hasChildren || item.isRoot ? (
                    <>
                        {item.isSub ? (
                            <>
                                <p style={{fontSize: 12, margin: 0}}>{item.name}</p>
                            </>
                        ) : (
                            <>
                                <p className="bold" style={{margin: 0}}>{item.name}</p>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        {item.isSub ? (
                            <>
                                <p style={{fontSize: 12, margin: 0}}>{item.name}</p>
                            </>
                        ) : (
                            <>
                                <p style={{fontSize: 10, margin: 0}}>{item.name}</p>
                            </>
                        )}
                    </>
                )}

                {((hasChildren || item.isRoot)) && (
                    <FontAwesomeIcon style={{margin: "0 0 0 16px"}} size='2xs' icon={isOpen ? faChevronDown : faChevronUp}/>
                )}
            </div>
            {isOpen[`${item.path}`] && hasChildren && (
                <div className="tree-children" style={{overflow: 'hidden', transition: 'max-height 0.3s ease-in-out'}}>
                    {item.children.map((child, index) => (
                        <TreeItem
                            key={index}
                            item={{ ...child, path: `${item.path || item.name}/${child.name}` }}
                            onToggle={onToggle}
                            onClicked={onClicked}
                            selectedLocation={selectedLocation}
                            selectedPosition={selectedPosition}
                            isOpen={isOpen}
                            subClicked={subClicked}
                        />
                    ))}
                </div>
            )}
        </>
    );
};

function AlertSetting() {
    const cctvData = [
        {
            isRoot: true,
            name: "대한민국",
            childIcon: faLocation,
            children: [
                {
                    isRoot: true,
                    name: "전라남도",
                    children: [
                        {
                            isSub: true,
                            name: "월출지하차도",
                            locationValue: "001",
                            children: [
                                {
                                    childIcon: faLocation,
                                    name: "1번 개소",
                                    locationValue: "001",
                                    value: 1
                                },
                                {
                                    childIcon: faLocation,
                                    name: "2번 개소",
                                    locationValue: "001",
                                    value: 2
                                },
                                {
                                    childIcon: faLocation,
                                    name: "3번 개소",
                                    locationValue: "001",
                                    value: 3
                                },
                                {
                                    childIcon: faLocation,
                                    name: "4번 개소",
                                    locationValue: "001",
                                    value: 4
                                },
                                {
                                    childIcon: faLocation,
                                    name: "5번 개소",
                                    locationValue: "001",
                                    value: 5
                                }
                            ],
                        },
                    ],
                },
                {
                    isRoot: true,
                    name: "전라북도",
                    children: [
                        {
                            isSub: true,
                            name: "월드컵지하차도",
                            locationValue: "002",
                            children: [
                                {
                                    childIcon: faLocation,
                                    name: "1번 개소",
                                    locationValue: "002",
                                    value: 1
                                },
                                {
                                    childIcon: faLocation,
                                    name: "2번 개소",
                                    locationValue: "002",
                                    value: 2
                                },
                                {
                                    childIcon: faLocation,
                                    name: "3번 개소",
                                    locationValue: "002",
                                    value: 3
                                },
                                {
                                    childIcon: faLocation,
                                    name: "4번 개소",
                                    locationValue: "002",
                                    value: 4
                                }
                            ],
                        },
                    ],
                },
                {
                    isRoot: true,
                    name: "충청북도",
                },
                {
                    isRoot: true,
                    name: "충청남도",
                },
                {
                    isRoot: true,
                    name: "경상북도",
                },
                {
                    isRoot: true,
                    name: "경상남도",
                    children: [
                        {
                            isSub: true,
                            name: "남해지하차도",
                            locationValue: "003",
                            children: [
                                {
                                    childIcon: faLocation,
                                    name: "1번 개소",
                                    locationValue: "003",
                                    value: 1
                                },
                                {
                                    childIcon: faLocation,
                                    name: "2번 개소",
                                    locationValue: "003",
                                    value: 2
                                },
                                {
                                    childIcon: faLocation,
                                    name: "3번 개소",
                                    locationValue: "003",
                                    value: 3
                                },
                                {
                                    childIcon: faLocation,
                                    name: "4번 개소",
                                    locationValue: "003",
                                    value: 4
                                }
                            ],
                        },
                    ],
                },
                {
                    isRoot: true,
                    name: "강원특별자치도",
                },
                {
                    isRoot: true,
                    name: "경기도",
                },
                {
                    isRoot: true,
                    name: "제주특별자치도",
                },
            ]
        },
    ];

    const [ alertTimeout, setAlertTimeout ] = useState(null);
    const [ isAlert, setIsAlert] = useState(-1);
    const [ alertMsg, setAlertMsg] = useState("");
    const setIsAlertWithTimeout = (alertType, timeOut = 3500) => {
        // 이미 설정된 setTimeout이 있다면 취소합니다.
        if (alertTimeout) {
            clearTimeout(alertTimeout);
        }

        setIsAlert(alertType);

        // 3.5초 후에 알림을 사라지게 만듭니다.
        const timeoutId = setTimeout(() => {
            setIsAlert(-1);
        }, timeOut);

        // 새로 설정된 setTimeout의 ID를 저장합니다.
        setAlertTimeout(timeoutId);
    }
    const initializeOpenTreeItems = (data, path = '') => {
        const focusLocationPath = VtoP(focusLocation);
        return data.reduce((acc, item) => {
            const currentPath = path ? `${path}/${item.name}` : item.name;
            const isOpen = focusLocationPath && focusLocationPath.startsWith(currentPath);

            acc[currentPath] = isOpen;
            if (item.children) {
                Object.assign(acc, initializeOpenTreeItems(item.children, currentPath));
            }
            return acc;
        }, {});
    };

    const { focusLocation, setFocusLocation } = useContext(GlobalContext);
    const [ selectedLocation, setSelectedLocation ] = useState(focusLocation);
    const [ selectedPosition, setSelectedPosition ] = useState(null);

    const [openTreeItems, setOpenTreeItems] = useState(() => initializeOpenTreeItems(cctvData));

    const [ settings, setSettings ] = useState([]);
    const [displayData, setDisplayData] = useState([]);

    const [ batchValue, setBatchValue ] = useState({
        limit: "",
        warning: "",
        alert: "",
        danger: "",
        pos: "",
    })
    const [ isLoading, setIsLoading ] = useState(false)

    const handleBatchChange = (e) => {
        const name = e.target.name;
        let newAlertPosition = e.target.value;

        if(name !== "pos"){
            if(name === "warning" || name === "alert" || name === "danger" ){
                let numsStr = newAlertPosition.replace(/\D/g, "");
                numsStr = numsStr.substring(0, 3);
                const nums = isNaN(parseInt(numsStr, 10)) ? 0 : parseInt(numsStr, 10)
                newAlertPosition = nums > 100 ? 100 : nums;
            }else{
                let numsStr = newAlertPosition.replace(/\D/g, "");
                numsStr = numsStr.substring(0, 5);
                newAlertPosition = isNaN(parseInt(numsStr, 10)) ? 0 : parseInt(numsStr, 10);
            }
        }

        setBatchValue(prevValues => ({
            ...prevValues,
            [name]: newAlertPosition
        }));
    }

    const getSettings = async () => {
        setIsLoading(true)
        try {
            const response = await fetch(`https://${backendServer}/alert/get/settings`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('File upload failed');
            }

            const result = await response.json()
            setSettings(result)
        } catch (error) {
            setAlertMsg("서버와 통신중 에러가 발생했습니다.")
            setIsAlertWithTimeout(2)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getSettings();
    }, [])

    useEffect(() => {
        if (selectedLocation) {
            const filteredData = settings.filter(item => item.LocationCode === selectedLocation);

            if (selectedPosition) {
                setDisplayData(filteredData.filter(item => parseInt(item.CameraCode) === selectedPosition));
            } else {
                setDisplayData(filteredData);
            }
        } else {
            setDisplayData([]);
        }
    }, [selectedPosition, selectedLocation, settings]);

    const toggleTreeItem = (path, locationValue) => {
        setOpenTreeItems(prevOpenTreeItems => ({
            ...prevOpenTreeItems,
            [path]: prevOpenTreeItems[path] && (locationValue === selectedLocation) ? prevOpenTreeItems[path] : !prevOpenTreeItems[path],
        }));
    };
    const subClicked = (value) => {
        setSelectedLocation(value)
        setSelectedPosition(null);
    }
    const elementClicked = (index, locationValue) => {
        if(index === selectedPosition){
            setSelectedPosition(null);
        }else{
            setSelectedPosition(index);
        }
        setSelectedLocation(locationValue)
    }

    const handleChange = (item, attr, e) => {
        // 선택된 항목의 새로운 AlertPosition 값을 가져옵니다.
        let newAlertPosition = e.target.value;

        if(attr !== "AlertPosition"){
            if(attr === "AlertPercent" || attr === "WarningPercent" || attr === "DangerPercent" ){
                let numsStr = newAlertPosition.replace(/\D/g, "");
                numsStr = numsStr.substring(0, 3);
                const nums = isNaN(parseInt(numsStr, 10)) ? 0 : parseInt(numsStr, 10)
                newAlertPosition = nums > 100 ? 100 : nums;
            }else{
                let numsStr = newAlertPosition.replace(/\D/g, "");
                numsStr = numsStr.substring(0, 5);
                newAlertPosition = isNaN(parseInt(numsStr, 10)) ? 0 : parseInt(numsStr, 10);
            }
        }

        // settings 배열을 업데이트합니다.
        const newSettings = settings.map(setting => {
            if (setting.LocationCode === selectedLocation && setting.CameraCode === item.CameraCode) { // 여기서는 CameraCode를 기준으로 특정 아이템을 식별합니다.
                return {
                    ...setting,
                    [attr]: newAlertPosition
                };
            }
            return setting;
        });

        // 업데이트된 settings 배열로 상태를 설정합니다.
        setSettings(newSettings);
    }

    const handleApplyBatch = () => {
        // 새로운 settings 배열을 생성
        const newSettings = settings.map(setting => {
            // 현재 설정이 displayData에 포함되는지 확인
            const found = displayData.find(item => item.CameraCode === setting.CameraCode && setting.LocationCode === selectedLocation);
            if (found) {
                // batchValue를 사용하여 설정 업데이트
                return {
                    ...setting,
                    Limit: batchValue.limit ? batchValue.limit : setting.Limit,
                    WarningPercent: batchValue.warning ? batchValue.warning : setting.WarningPercent,
                    AlertPercent: batchValue.alert ? batchValue.alert : setting.AlertPercent,
                    DangerPercent: batchValue.danger ? batchValue.danger : setting.DangerPercent,
                    AlertPosition: batchValue.pos ? batchValue.pos : setting.AlertPosition,
                };
            }
            return setting; // 변경하지 않은 설정 반환
        });

        // 업데이트된 settings 배열로 상태를 설정
        setSettings(newSettings);
        setBatchValue({
            limit: "",
            warning: "",
            alert: "",
            danger: "",
            pos: "",
        })
    }

    const handleRefresh = async () => {
        getSettings()
    }

    const handleSave = async () => {
        setIsLoading(true)
        try {
            const response = await fetch(`https://${backendServer}/alert/set/settings`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(settings),
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('File upload failed');
            }

            setAlertMsg("성공적으로 저장 하였습니다.")
            setIsAlertWithTimeout(0)
        } catch (error) {
            setAlertMsg("서버와 통신중 에러가 발생했습니다.")
            setIsAlertWithTimeout(2)
        } finally {
            setIsLoading(false)
        }
    }

    const CCTVTree = React.memo(({ data }) => {
        return (
            <div className="cctv-tree">
                {data.map((item, index) => (
                    <TreeItem
                        key={index}
                        item={{ ...item, path: item.name }}
                        onToggle={toggleTreeItem}
                        onClicked={elementClicked}
                        selectedPosition={selectedPosition}
                        selectedLocation={selectedLocation}
                        isOpen={openTreeItems} // 논리 NOT 연산자를 두 번 사용하여 undefined를 false로 변환
                        subClicked={subClicked}
                    />
                ))}
            </div>
        );
    }, (prevProps, nextProps) => {
        return prevProps.data === nextProps.data;
    });

    return (
        <>
            <div className="column full-size" style={{position: "relative", overflow: "hidden"}}>
                <div className="row full-size-width" style={{height: "3%", position: "relative", alignItems: "center", borderBottom: "1px solid #ffffff50"}}>
                    <FontAwesomeIcon icon={faTint} size="xs" style={{margin: 8}}/>
                    <p className="bold" style={{width: "calc(80% + 32px)", margin: "16px 16px 16px 0", fontSize: 13, color: "white"}}>임계점 설정</p>
                    <p className="bold" style={{width: "20%",marginLeft: 16, fontSize: 13, color: "white"}}>탐색기</p>
                </div>
                <div className="row full-size-width" style={{height: "auto", position: "relative"}}>
                    <div className="column full-size"
                         style={{width: "80%", height: "auto", position: "relative", overflow: 'hidden'}}>
                        <div className="seperator-half"/>
                        <div className="row">
                            <div className="seperator-horz-half"/>
                            <div className="row full-size-width align-center"
                                 style={{background: "rgba(52,64,73,0.7)", padding: "0.4rem"}}>
                                <p className="text-align-left full-size-width"
                                   style={{padding: "0.4rem", margin: 0, fontSize: 16}}>{VtoL(selectedLocation)}</p>
                                <p className="text-align-right full-size-width"
                                   style={{
                                       padding: "0.4rem",
                                       margin: 0,
                                       fontSize: 16
                                   }}>{selectedPosition ? selectedPosition + "번 개소" : "전체"}</p>
                            </div>
                            <div className="seperator-horz-half"/>
                            <div className="row full-size-width align-center"
                                 style={{background: "rgba(52,64,73,0.7)", padding: "0.4rem"}}>
                                <button
                                    onClick={handleRefresh}
                                    className="full-size-width align-center bold upload-button">
                                    새로고침
                                </button>
                                <button
                                    onClick={handleSave}
                                    className="full-size-width align-center bold upload-button">
                                    저장하기
                                </button>
                            </div>
                            <div className="seperator-horz-half"/>
                        </div>
                        <div className="seperator-half"/>
                        <div className="row">
                            <div className="seperator-horz-half"/>
                            <div className="row full-size-width align-center"
                                 style={{background: "rgba(52,64,73,0.7)", padding: "0.4rem"}}>
                                <div className="column full-size-width">
                                    <p className="full-size-width"
                                       style={{
                                           whiteSpace: "nowrap",
                                           color: "#cccccc",
                                           fontSize: 10,
                                           margin: 0,
                                           padding: 0,
                                       }}>임계값</p>
                                    <div
                                        className="row input-container align-center full-size-width"
                                        style={{
                                            background: "white",
                                            borderRadius: "4px",
                                            border: "1px solid #ffffffb0",
                                            margin: "0.5rem 0"
                                        }}>
                                        <FontAwesomeIcon
                                            style={{color: "#aaaaaa", marginLeft: "1rem"}}
                                            fontSize={12} icon={faRuler}/>
                                        <input
                                            style={{
                                                padding: "0.5rem",
                                                fontSize: 12,
                                                color: "black",
                                            }}
                                            autoComplete={"off"}
                                            name="limit"
                                            value={batchValue.limit}
                                            onChange={handleBatchChange}
                                            className="full-size-width"
                                        />
                                    </div>
                                </div>
                                <div className="seperator-horz-half"/>
                                <div className="column full-size-width">
                                    <p className="full-size-width"
                                       style={{
                                           whiteSpace: "nowrap",
                                           color: "#cccccc",
                                           fontSize: 10,
                                           margin: 0,
                                           padding: 0,
                                       }}>주의 지점</p>
                                    <div
                                        className="row input-container align-center full-size-width"
                                        style={{
                                            background: "white",
                                            borderRadius: "4px",
                                            border: "1px solid #ffffffb0",
                                            margin: "0.5rem 0"
                                        }}>
                                        <FontAwesomeIcon
                                            style={{color: "#aaaaaa", marginLeft: "1rem"}}
                                            fontSize={12} icon={faRuler}/>
                                        <input
                                            style={{
                                                padding: "0.5rem",
                                                fontSize: 12,
                                                color: "black",
                                            }}
                                            autoComplete={"off"}
                                            name="warning"
                                            value={batchValue.warning}
                                            onChange={handleBatchChange}
                                            className="full-size-width"
                                        />
                                    </div>
                                </div>
                                <div className="seperator-horz-half"/>
                                <div className="column full-size-width">
                                    <p className="full-size-width"
                                       style={{
                                           whiteSpace: "nowrap",
                                           color: "#cccccc",
                                           fontSize: 10,
                                           margin: 0,
                                           padding: 0,
                                       }}>경계 지점</p>
                                    <div
                                        className="row input-container align-center full-size-width"
                                        style={{
                                            background: "white",
                                            borderRadius: "4px",
                                            border: "1px solid #ffffffb0",
                                            margin: "0.5rem 0"
                                        }}>
                                        <FontAwesomeIcon
                                            style={{color: "#aaaaaa", marginLeft: "1rem"}}
                                            fontSize={12} icon={faRuler}/>
                                        <input
                                            style={{
                                                padding: "0.5rem",
                                                fontSize: 12,
                                                color: "black",
                                            }}
                                            autoComplete={"off"}
                                            name="alert"
                                            value={batchValue.alert}
                                            onChange={handleBatchChange}
                                            className="full-size-width"
                                        />
                                    </div>
                                </div>
                                <div className="seperator-horz-half"/>
                                <div className="column full-size-width">
                                    <p className="full-size-width"
                                       style={{
                                           whiteSpace: "nowrap",
                                           color: "#cccccc",
                                           fontSize: 10,
                                           margin: 0,
                                           padding: 0,
                                       }}>위험 지점</p>
                                    <div
                                        className="row input-container align-center full-size-width"
                                        style={{
                                            background: "white",
                                            borderRadius: "4px",
                                            border: "1px solid #ffffffb0",
                                            margin: "0.5rem 0"
                                        }}>
                                        <FontAwesomeIcon
                                            style={{color: "#aaaaaa", marginLeft: "1rem"}}
                                            fontSize={12} icon={faRuler}/>
                                        <input
                                            style={{
                                                padding: "0.5rem",
                                                fontSize: 12,
                                                color: "black",
                                            }}
                                            autoComplete={"off"}
                                            name="danger"
                                            value={batchValue.danger}
                                            onChange={handleBatchChange}
                                            className="full-size-width"
                                        />
                                    </div>
                                </div>
                                <div className="seperator-horz-half"/>
                                <div className="column full-size-width">
                                    <p className="full-size-width"
                                       style={{
                                           whiteSpace: "nowrap",
                                           color: "#cccccc",
                                           fontSize: 10,
                                           margin: 0,
                                           padding: 0,
                                       }}>알람 지점</p>
                                    <div
                                        className="row input-container align-center full-size-width"
                                        style={{
                                            background: "white",
                                            borderRadius: "4px",
                                            border: "1px solid #ffffffb0",
                                            margin: "0.5rem 0"
                                        }}>
                                        <FontAwesomeIcon
                                            style={{color: "#aaaaaa", marginLeft: "1rem"}}
                                            fontSize={12} icon={faRuler}/>
                                        <select
                                            className="full-size-width"
                                            style={{
                                                border: "none",
                                                padding: "0.5rem",
                                                fontSize: 12,
                                                color: "black",
                                            }}
                                            name="pos"
                                            value={batchValue.pos}
                                            onChange={handleBatchChange}
                                        >
                                            <option value="W">주의</option>
                                            <option value="A">경계</option>
                                            <option value="D">위험</option>
                                        </select>
                                    </div>
                                </div>
                                <button
                                    onClick={handleApplyBatch}
                                    className="full-size-width align-center bold upload-button">
                                    일괄변경
                                </button>
                            </div>
                            <div className="seperator-horz-half"/>
                        </div>
                        {isLoading ? (
                            <>
                                <div className="column full-size align-center">
                                    <BaseLoadingInContainer/>
                                </div>
                            </>
                        ) : (
                            <>
                                {displayData.map((item, index) => (
                                    <div key={selectedLocation + "-" + selectedPosition + "-" + index} className="row"
                                         style={{margin: "0.5rem 1rem"}}>
                                        <div className="column align-center"
                                             style={{
                                                 borderTopLeftRadius: "0.5rem",
                                                 borderBottomLeftRadius: "0.5rem",
                                                 background: "linear-gradient(to top right, #222222 0%, #364D79FF 100%)",
                                                 borderTop: "1px solid #222222",
                                                 borderLeft: "1px solid #222222",
                                                 borderBottom: "1px solid #222222",
                                                 textAlign: "center"
                                             }}>
                                            <p className="text-align-center" style={{
                                                margin: 0,
                                                fontSize: 14,
                                                padding: "0.5rem 2rem",
                                                whiteSpace: "nowrap"
                                            }}>{parseInt(item.CameraCode)}번 개소</p>
                                        </div>
                                        <div className="column full-size-width" style={{textAlign: "center"}}>
                                            <p className="text-align-center" style={{
                                                background: "#525252",
                                                border: "1px solid #222222",
                                                padding: "0.4rem",
                                                margin: 0,
                                                fontSize: 14
                                            }}>임계값(mm)</p>
                                            <input
                                                value={item.Limit}
                                                onChange={(e) => handleChange(item, "Limit", e)}
                                                className="text-align-center"
                                                style={{
                                                    background: "#8c8c8c",
                                                    border: "1px solid #222222",
                                                    padding: "0.8rem 0.4rem",
                                                    margin: 0,
                                                    color: '#ffffff',
                                                    fontSize: 12
                                                }}/>
                                            <p className="text-align-center" style={{
                                                whiteSpace: "nowrap",
                                                background: "#8c8c8c",
                                                border: "1px solid #222222",
                                                padding: "0.4rem 0.4rem",
                                                margin: 0,
                                                fontSize: 10
                                            }}>{"최솟값 : 60mm"}</p>
                                        </div>
                                        <div className="column full-size-width" style={{textAlign: "center"}}>
                                            <p className="text-align-center" style={{
                                                background: "#525252",
                                                border: "1px solid #222222",
                                                padding: "0.4rem",
                                                margin: 0,
                                                fontSize: 14
                                            }}>주의 지점(%)</p>
                                            {item.AlertPosition === "W" &&
                                                <div className="full-size" style={{
                                                    background: "rgba(255,0,0,0.11)",
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    zIndex: 100,
                                                    pointerEvents: "none"
                                                }}/>
                                            }
                                            <input
                                                value={item.WarningPercent}
                                                onChange={(e) => handleChange(item, "WarningPercent", e)}
                                                className="text-align-center"
                                                style={{
                                                    background: "#8c8c8c",
                                                    border: "1px solid #222222",
                                                    padding: "0.8rem 0.4rem",
                                                    margin: 0,
                                                    color: '#ffffff',
                                                    fontSize: 12
                                                }}/>
                                            <p className="text-align-center" style={{
                                                whiteSpace: "nowrap",
                                                background: "#8c8c8c",
                                                border: "1px solid #222222",
                                                padding: "0.4rem 0.4rem",
                                                margin: 0,
                                                fontSize: 10
                                            }}>{Math.floor(item.Limit * (item.WarningPercent / 100))}mm</p>
                                        </div>
                                        <div className="column full-size-width" style={{textAlign: "center"}}>
                                            <p className="text-align-center" style={{
                                                background: "#525252",
                                                border: "1px solid #222222",
                                                padding: "0.4rem",
                                                margin: 0,
                                                fontSize: 14
                                            }}>경계 지점(%)</p>
                                            {item.AlertPosition === "A" &&
                                                <div className="full-size" style={{
                                                    background: "rgba(255,0,0,0.11)",
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    zIndex: 100,
                                                    pointerEvents: "none"
                                                }}/>
                                            }
                                            <input
                                                value={item.AlertPercent}
                                                onChange={(e) => handleChange(item, "AlertPercent", e)}
                                                className="text-align-center"
                                                style={{
                                                    background: "#8c8c8c",
                                                    border: "1px solid #222222",
                                                    padding: "0.8rem 0.4rem",
                                                    margin: 0,
                                                    color: '#ffffff',
                                                    fontSize: 12
                                                }}/>
                                            <p className="text-align-center" style={{
                                                whiteSpace: "nowrap",
                                                background: "#8c8c8c",
                                                border: "1px solid #222222",
                                                padding: "0.4rem 0.4rem",
                                                margin: 0,
                                                fontSize: 10
                                            }}>{Math.floor(item.Limit * (item.AlertPercent / 100))}mm</p>
                                        </div>
                                        <div className="column full-size-width" style={{textAlign: "center"}}>
                                            <p className="text-align-center" style={{
                                                background: "#525252",
                                                border: "1px solid #222222",
                                                padding: "0.4rem",
                                                margin: 0,
                                                fontSize: 14
                                            }}>위험 지점(%)</p>
                                            {item.AlertPosition === "D" &&
                                                <div className="full-size" style={{
                                                    background: "rgba(255,0,0,0.11)",
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    zIndex: 100,
                                                    pointerEvents: "none"
                                                }}/>
                                            }
                                            <input
                                                value={item.DangerPercent}
                                                onChange={(e) => handleChange(item, "DangerPercent", e)}
                                                className="text-align-center"
                                                style={{
                                                    background: "#8c8c8c",
                                                    border: "1px solid #222222",
                                                    padding: "0.8rem 0.4rem",
                                                    margin: 0,
                                                    color: '#ffffff',
                                                    fontSize: 12
                                                }}/>
                                            <p className="text-align-center" style={{
                                                whiteSpace: "nowrap",
                                                background: "#8c8c8c",
                                                border: "1px solid #222222",
                                                padding: "0.4rem 0.4rem",
                                                margin: 0,
                                                fontSize: 10
                                            }}>{Math.floor(item.Limit * (item.DangerPercent / 100))}mm</p>
                                        </div>
                                        <div className="column full-size-width" style={{textAlign: "center"}}>
                                            <p className="text-align-center" style={{
                                                background: "#525252",
                                                border: "1px solid #222222",
                                                padding: "0.4rem",
                                                margin: 0,
                                                fontSize: 14
                                            }}>알람 지점</p>
                                            <select
                                                value={item.AlertPosition}
                                                onChange={(e) => handleChange(item, "AlertPosition", e)}
                                                style={{
                                                    fontFamily: "NanumSquareNeo-Variable",
                                                    height: "100%",
                                                    background: "#8c8c8c",
                                                    border: "1px solid #222222",
                                                    padding: "0.8rem 0.4rem",
                                                    margin: 0,
                                                    textAlign: "center",
                                                    fontSize: 14,
                                                    color: item.AlertPosition === "D" ? '#ff2215' : item.AlertPosition === "A" ? '#FFD700' : "#FDFD96", // 텍스트 색상을 흰색으로 설정
                                                    appearance: 'none' // 기본 브라우저 스타일 제거
                                                }}
                                            >
                                                <option value="W">주의</option>
                                                <option value="A">경계</option>
                                                <option value="D">위험</option>
                                            </select>
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                    <div className="column full-size" style={{width: "20%", position: "relative", overflow: 'hidden'}}>
                        <CCTVTree data={cctvData}/>
                    </div>
                </div>
            </div>
            {isAlert === 0 && (
                <>
                    <BaseAlert msg={alertMsg}></BaseAlert>
                </>
            )}
            {isAlert === 1 && (
                <>
                    <BaseAlertWarn msg={alertMsg}></BaseAlertWarn>
                </>
            )}
            {isAlert === 2 && (
                <>
                    <BaseAlertDanger msg={alertMsg}></BaseAlertDanger>
                </>
            )}
        </>
    );
}

export default AlertSetting;
