import Locations from '../modules/locations.json'

const ITEM_SIZE = 36;
const widthPercentage = ['50px', '100px','150px','200px','250px','300px', '350px', '400px','450px','500px'];

function getLocationNameByCode(locationCode) {
    const location = Locations.locations.find(location => location.code === locationCode);

    return location ? location.name : undefined;
}

function formatCameraCode(cameraCode) {
    const codeNumber = parseInt(cameraCode, 10);

    return `${codeNumber}번 개소`;
}

function formatDate(dateString) {
    const date = new Date(dateString);

    // ISO 문자열로 변환 후, 'T'를 기준으로 날짜와 시간 분리
    let isoString = date.toISOString();
    let [datePart, timePart] = isoString.split('T');

    // 시간 부분에서 초 뒤의 'Z'와 소수점 부분 제거
    timePart = timePart.split('.')[0];

    // 최종 문자열 조합
    const formattedDate = `${datePart} ${timePart}`;
    return formattedDate;
}

function getHeaderFixedColumnStyle(widthPercentage) {
    return {
        width: widthPercentage,
        margin: 0,
        padding: 0
    };
}

function getStyle(widthPercentage, isFirst, index = 2) {
    if(isFirst){
        return { width: widthPercentage,
            backgroundColor: index % 2 === 1 ? '#646464' : '#a2a2a2',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap' };
    }else{
        return { width: widthPercentage,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap' };
    }
}

function TableRow({ index, style, data }) {
    const { datas, containerWidth, page, page_size, offset } = data;
    const rowData = datas[index];

    const rowStyle = {
        ...style,
        top: ITEM_SIZE * index,
    };

    const headerStyle = {
        height: '24px'
    };

    const bodyStyle = {
        backgroundColor: index % 2 === 1 ? '#646464' : '#a2a2a2',
    }

    if (containerWidth < 1110) {
        headerStyle.width = '1100px';
        bodyStyle.width = '1100px';
    }


    const location = getLocationNameByCode(rowData.locations_code);
    const sub_loc = formatCameraCode(rowData.camera_code);
    const date = formatDate(rowData.create_at);

    return (
        <>
            <div style={rowStyle}>
                <div className="row full-size-width table-item" style={bodyStyle}>
                    <div className="row first-column"
                         style={getHeaderFixedColumnStyle(widthPercentage[0] + widthPercentage[2] + widthPercentage[2])}>
                        <div
                            className="column align-center" style={getStyle(widthPercentage[0], true, index)}>
                            <p>{((page - 1) * page_size) + index + 1}</p>
                        </div>
                        <div
                            className="column align-center" style={getStyle(widthPercentage[2], true, index)}>
                            <p>{location}</p>
                        </div>
                        <div
                            className="column align-center" style={getStyle(widthPercentage[2], true, index)}>
                            <p>{sub_loc}</p>
                        </div>
                    </div>
                    <div className="column align-center" style={getStyle(widthPercentage[3], false, index)}>
                        <p>{date}</p>
                    </div>
                    <div className="column align-center" style={getStyle(widthPercentage[2], false, index)}>
                        <p>{rowData.sensor1}mm</p>
                    </div>
                    <div className="column align-center" style={getStyle(widthPercentage[2], false, index)}>
                        <p>{offset - rowData.sensor1}mm</p>
                    </div>
                    <div className="column align-center" style={getStyle(widthPercentage[2], false, index)}>
                        <p>{rowData.sensor2}mm</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export {ITEM_SIZE, TableRow, widthPercentage, getHeaderFixedColumnStyle, getStyle};
