import React, {useEffect, useState} from 'react';
import {
    PieChart,
    Pie,
    Cell,
    BarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Bar,
    LabelList,
    ReferenceLine
} from 'recharts';
import Needle from "./Needle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBarChart,
    faBolt, faCheckCircle, faClock,
    faExclamation,
    faExclamationCircle,
    faExclamationTriangle, faTimesCircle, faTint, faWater
} from "@fortawesome/free-solid-svg-icons";

const data = [
    { name: 'Group A', value: 0.3 },
    { name: 'Group B', value: 0.25 },
    { name: 'Group C', value: 0.25 },
    { name: 'Group D', value: 0.2 }
];

const COLORS = ['rgba(60, 179, 113, 0.8)', 'rgba(255, 165, 0, 0.8)', 'rgba(255, 215, 0, 0.8)', 'rgba(204, 0, 0, 0.8)'];

const HalfDonutChartWithPins = ({setupValue, sensorDatas, sensorNo}) => {
    const radius = 80;
    const center = { x: 81, y: 93 };

    // sensor_1과 sensor_2의 값을 setupValue를 넘지 않도록 조정
    const latestSensorData = sensorDatas[0];
    // raider sensor 오프셋 조정 값
    //const latestSensor1 = latestSensorData ? Math.max((latestSensorData.raider_offset || 0) - latestSensorData.sensor_1, 0) : 0;
    const latestSensor1 = latestSensorData ? Math.max(latestSensorData.sensor_2, 0) : 0;
    const latestSensor2 = latestSensorData ? Math.max(latestSensorData.sensor_2, 0) : 0;
    const latestDateStr = latestSensorData ? latestSensorData.dateStr : 0;
    const adjustedSensor1 = Math.min(latestSensor1, setupValue.Limit);
    const adjustedSensor2 = Math.min(latestSensor2, setupValue.Limit);

    // 차트에 표시할 데이터
    const referenceData = [
        {
            name: 'dump',
            value: 0,
            originalValue: setupValue.Limit // 임계값을 데이터에 포함
        },
        {
            name: '센서 1',
            value: adjustedSensor1,
            originalValue: latestSensor1 // 툴팁에서 실제 값을 보여주기 위해
        },
        {
            name: '센서 2',
            value: adjustedSensor2,
            originalValue: latestSensor2 // 툴팁에서 실제 값을 보여주기 위해
        },
        {
            name: 'dump',
            value: 0,
            originalValue: setupValue.Limit // 임계값을 데이터에 포함
        }
    ];

    const calculatePosition = (angle, distance) => {
        const radian = (angle * Math.PI) / 180;
        return {
            x: center.x + distance * Math.cos(radian),
            y: center.y + distance * Math.sin(radian)
        };
    };

    const formatDate = (dateStr) => {
        // 'Z'를 제거하여 UTC로 처리하지 않도록 함
        const dateStrAdjusted = dateStr.replace('Z', '');

        // 'Z' 제거된 시간으로 Date 객체 생성
        const now = new Date(dateStrAdjusted);
        const year = now.getFullYear().toString().substr(-2); // 년도의 마지막 두 자리
        const month = (now.getMonth() + 1).toString().padStart(2, '0'); // 월 (0부터 시작하므로 +1 필요)
        const date = now.getDate().toString().padStart(2, '0'); // 일
        const hours = now.getHours().toString().padStart(2, '0'); // 시
        const minutes = now.getMinutes().toString().padStart(2, '0'); // 분

        return `${year}-${month}-${date} ${hours}:${minutes}`;
    }

    const getLabel = () => {
        const ratio1 = (latestSensor1 / setupValue.Limit) * 100;
        const ratio2 = (latestSensor2 / setupValue.Limit) * 100;

        const baseValue = Math.max(latestSensor1, latestSensor2);
        const difference = Math.abs(latestSensor1 - latestSensor2);

        let errorRate = Math.floor((difference / baseValue) * 100);
        if (isNaN(errorRate)) {
            errorRate = 0;
        }

        let level = 0;
        if (ratio1 >= setupValue.DangerPercent) {
            level += 4;
        } else if (ratio1 >= setupValue.AlertPercent && ratio1 < setupValue.DangerPercent) {
            level += 3;
        } else if (ratio1 >= setupValue.WarningPercent && ratio1 < setupValue.AlertPercent) {
            level += 2;
        } else {
            level += 1;
        }

        if (ratio2 >= setupValue.DangerPercent) {
            level += 4;
        } else if (ratio2 >= setupValue.AlertPercent && ratio2 < setupValue.DangerPercent) {
            level += 3;
        } else if (ratio2 >= setupValue.WarningPercent && ratio2 < setupValue.AlertPercent) {
            level += 2;
        } else {
            level += 1;
        }

        if (level >= 8) {
            return { message: "위험", color: 'rgba(204, 0, 0, 0.8)', icon: faExclamationTriangle, errorRate: errorRate }; // 더 채도 높은 붉은색 톤
        } else if (level >= 6 && level < 8) {
            return { message: "주의", color: 'rgba(255, 215, 0, 0.8)', icon: faExclamationCircle, errorRate: errorRate }; // 더 채도 높고 노란색에 가까운 주황색 톤
        } else if (level >= 4 && level < 6) {
            return { message: "경계", color: 'rgba(255, 165, 0, 0.8)', icon: faExclamation, errorRate: errorRate }; // 더 채도 높은 주황색 톤
        } else {
            return { message: "안전", color: 'rgba(60, 179, 113, 0.8)', icon: faCheckCircle, errorRate: errorRate }; // 더 채도 높은 연한 녹색 톤
        }
    }

    const getPersent = () => {
        const ratio = ((Math.max(latestSensor1,latestSensor2)) / setupValue.Limit) * 100;
        return Math.min((Math.floor(ratio * 10) / 10), 100);
    }

    const label = getLabel();
    let datas = { low: 0, high: setupValue.Limit };
    const step = [0, setupValue.Limit * (setupValue.WarningPercent / 100), setupValue.Limit * (setupValue.AlertPercent / 100), setupValue.Limit * (setupValue.DangerPercent / 100), setupValue.Limit] // 10개의 값을 생성하기 위한 간격

    const minimun = 0;
    const maximun = setupValue.Limit;

    const values = Array.from({ length: 5 }, (_, index) => {
        return Math.round(datas.low + step[index]);
    });


    const posStep = [0, 180 * 0.30, 180 * 0.55, 180 * 0.80, 180];
    const positions = Array.from({ length: 5 }, (_, index) => {
        return calculatePosition(Math.round(180 + (posStep[index])), radius);
    });

    const getRotationFromValue = (value, min = minimun, max = maximun) => {
        if (value <= min) return 270;
        if (value >= max) return 90;
        const ratio = (value - min) / (max - min);
        return 270 + (ratio * 180);
    }

    const getFillColor = (name) => {
        if (name === '센서 1') return 'rgba(0, 128, 255, 0.5)';
        if (name === '센서 2') return 'rgba(225,59,59,0.5)';
        return 'transparent'; // 임계값의 경우
    };

    function renderCustomLabel(props) {
        const { x, y, width, value, viewBox } = props;
        const centerY = y + (viewBox.height / 2);
        // 가로 위치는 바의 오른쪽 끝에 맞추되, 텍스트가 바 밖으로 나가지 않도록 조정합니다.
        const posX = x + width - 5; // 오른쪽 여백을 위해 width에서 약간 빼줍니다.

        return (
            <text x={posX} y={centerY} fill={value === 0 ? 'transparent' : '#ffffff'} textAnchor="end" dominantBaseline="middle" fontSize="10">
                {value}
            </text>
        );
    }

    return (
        <div className="column grid-container">
            <div className="column grid-form" style={{width: 240, position: "relative", margin: "0.5rem"}}>
                <div className="row grid-title">
                    <FontAwesomeIcon icon={faBarChart}/>
                    <div className="seperator-horz-half"></div>
                    <p className="full-size bold">{sensorNo}번 개소</p>
                    <div className="row align-center">
                        <FontAwesomeIcon icon={faWater}/>
                        <p className="bold" style={{fontSize: 12, margin: '0 4px'}}>mm</p>
                    </div>
                </div>
                <div className="row grow-size align-center" style={{background: "#444444", boxSizing: "border-box"}}>
                    <div className="row full-size align-center" style={{position: "relative"}}>
                        <div style={{ position: "relative", width: 160, height: 130 }}>
                            <PieChart width={160} height={100}>
                                <Pie
                                    data={data}
                                    cx={75}
                                    cy={95}
                                    startAngle={180}
                                    endAngle={0}
                                    innerRadius={60}
                                    outerRadius={70}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {data.map((entry, index) => (
                                        <Cell key={`${Math.random()}-cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                            </PieChart>
                            {values.map((value, index) => {
                                return (
                                    <div style={{color: index !== 0 ? COLORS[index - 1] : 'white', fontWeight: "bold", fontSize: "10px", position: "absolute", top: `${positions[index].y}px`, left: `${positions[index].x}px`, transform: "translate(-50%, 0)" }}>
                                        {value}
                                    </div>
                                );
                            })}
                            <Needle top={`${center.y - 45}px`} left={`${center.x}px`} length={50} rotation={getRotationFromValue(latestSensor1)} color={"rgba(0, 128, 255, 0.8)"}/>
                            {latestSensor1 !== null && latestSensor1 !== undefined && <Needle top={`${center.y - 45}px`} left={`${center.x}px`} rotation={getRotationFromValue(latestSensor2)} color={"rgba(225,59,59,0.8)"} length={50} />}
                            {latestSensor1 !== null && latestSensor1 !== undefined && <p style={{position: "absolute", color: "rgba(0, 128, 255, 0.8)", fontSize: "10px", fontWeight: "bold", top: "0px", left: "-30px"}}>센서1</p>}
                            {latestSensor2 !== null && latestSensor2 !== undefined && <p style={{position: "absolute", color: "rgba(225,59,59,0.8)", fontSize: "10px", fontWeight: "bold", top: "20px", left: "-30px"}}>센서2</p>}
                            <p style={{position: "absolute", color: label.color, fontSize: "15px", fontWeight: "bold", bottom: -5, left: "50%", transform: "translate(-50%, 0)"}}>{label.message}</p>
                        </div>
                    </div>
                </div>
                <div className="column grid-footer">
                    <div className="row">
                        <FontAwesomeIcon style={{fontSize: 14}} icon={faClock}/>
                        <p className="full-size bold">{formatDate(latestDateStr)}</p>
                        <div className="row align-center">
                            <FontAwesomeIcon style={{fontSize: 14}} icon={faTint}/>
                            <p className="bold" style={{fontSize: 10, margin: '0 4px', whiteSpace: "nowrap"}}>{getPersent()}% / 임계값</p>
                        </div>
                    </div>
                    <div className="seperator-half" />
                    <div className="column" style={{padding: 8}}>
                        <div className="row">
                            <p style={{fontSize: 10, color: "rgba(0, 128, 255, 0.8)", margin: '4px 4px', whiteSpace: "nowrap"}} className="full-size bold text-align-center">센서1</p>
                            <p style={{fontSize: 10, color: "rgba(225,59,59,0.8)", margin: '4px 4px', whiteSpace: "nowrap"}} className="full-size bold text-align-center">센서2</p>
                            <p style={{fontSize: 10, color: "rgba(255,255,255,1)", margin: '4px 4px', whiteSpace: "nowrap"}} className="full-size bold text-align-center">오차율</p>
                        </div>
                        <div className="row">
                            <p style={{fontSize: 12, margin: '4px 4px', whiteSpace: "nowrap"}} className="full-size bold text-align-center">{latestSensor1}mm</p>
                            <p style={{fontSize: 12, margin: '4px 4px', whiteSpace: "nowrap"}} className="full-size bold text-align-center">{latestSensor2}mm</p>
                            <p style={{fontSize: 12, margin: '4px 4px', whiteSpace: "nowrap"}} className="full-size bold text-align-center">{label.errorRate}%</p>
                        </div>
                    </div>
                    <div className="column align-center" style={{padding: 8}}>
                        <BarChart width={190} height={80} data={referenceData} layout="vertical" >
                            <XAxis type="number" domain={[0, setupValue.Limit]} tick={{ fill: "#9d9d9d", fontSize: 10 }}/>
                            <ReferenceLine x={0} stroke="#9d9d9d80" label={{ value: '' }}  />
                            <ReferenceLine x={setupValue.Limit} stroke="red" label={{ value: '', position: 'insideTop', dy: -10, fontSize: 10, fill: 'white' }}  />
                            <Bar dataKey="value" fill="#8884d8" minPointSize={5}>
                                {referenceData.map((entry, index) => (
                                    <Cell key={`hdc-cell-${index}`} fill={getFillColor(entry.name)} />
                                ))}
                                <LabelList dataKey="value" position="insideRight" content={renderCustomLabel} />
                            </Bar>
                        </BarChart>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HalfDonutChartWithPins;
