import React, { useEffect, useRef } from 'react';
import Hls from 'hls.js';

const RTSPPlayer = React.memo(({ streamUrl, style, sleep = 2 }) => {
    const videoRef = useRef(null);
    let hls;

    useEffect(() => {
        const loadAndPlayVideo = () => {
            if (Hls.isSupported()) {
                hls = new Hls();
                hls.loadSource(streamUrl);
                hls.attachMedia(videoRef.current);
                hls.on(Hls.Events.MANIFEST_PARSED, function () {
                    videoRef.current.play().catch(error => {
                        console.error('Playback failed', error);
                    });
                });
                // 스트림 에러 감지
                hls.on(Hls.Events.ERROR, function (event, data) {
                    if (data.fatal) {
                        switch(data.type) {
                            case Hls.ErrorTypes.NETWORK_ERROR:
                                // 네트워크 에러 처리, 스트림 재시작 시도
                                console.error("network error encountered, trying to recover");
                                hls.startLoad();
                                break;
                            case Hls.ErrorTypes.MEDIA_ERROR:
                                console.error("media error encountered, trying to recover");
                                hls.recoverMediaError();
                                break;
                            default:
                                // 다른 치명적인 에러는 HLS 인스턴스 파괴 후 재시작
                                console.error("fatal error encountered, destroying & recreating hls instance");
                                hls.destroy();
                                loadAndPlayVideo(); // 재시작 로직
                                break;
                        }
                    }
                });

            } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
                videoRef.current.src = streamUrl;
                videoRef.current.play().catch(error => {
                    console.error('Playback failed', error);
                });
            }
        };

        const timerId = setTimeout(loadAndPlayVideo, sleep * 1000);

        return () => {
            clearTimeout(timerId);
            if (hls) {
                hls.destroy();
            }
        };
    }, [streamUrl, sleep]);

    return (
        <video ref={videoRef} playsInline style={style} />
    );
}, (prevProps, nextProps) => {
    return prevProps.streamUrl === nextProps.streamUrl;
});

export default RTSPPlayer;
