import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    faCameraRetro, faCheckCircle,
    faChevronDown,
    faChevronUp, faCircle, faCloud, faCloudMeatball, faCloudRain, faCloudShowersHeavy, faCloudSun, faDesktop,
    faFolder,
    faImage,
    faLocation, faLocationPin, faLocationPinLock, faSnowflake, faSun, faTruckLoading, faWater
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import '../styles/CCTV.css'
import geojsonData from '../temp3.json';
import temp1 from '../temp/images/다운로드.jpg';
import temp2 from '../temp/images/2023072701002809800155501.jpg';
import temp3 from '../temp/images/417397_429506_4251.jpg';
import temp4 from '../temp/images/346389_239633_5246.jpg';
import MobileCardSlider from "../BaseComponents/MobileCardSlider";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    ReferenceLine
} from 'recharts';
import RTSPPlayer from "../BaseComponents/RTSPPlayer";
import {backendServer} from "../App";
import {GlobalContext} from "../page/Main";
import {BaseLoadingInContainer} from "../BaseComponents/BaseLoading";
import {faSensorAlert, faTimes} from "@fortawesome/pro-regular-svg-icons";
import {generateDummyData, generateDummyData2, generateNaturalIncreaseData} from "../modules/lib/tool";
import {BaseButton} from "../BaseComponents/BaseButton";
import {faWarning} from "@fortawesome/pro-solid-svg-icons";
import RealTimeWaterLevelChart from "../BaseComponents/RealTimeWaterLevelChart";

const TreeItem = ({ item, onToggle, isOpen, selectedLocation, onMapData, onWeather }) => {
    const hasChildren = item.children && item.children.length > 0;

    const handleToggle = (location, e) => {
        e.stopPropagation();
        const path = item.path || item.name; // 고유 경로가 없으면 이름을 사용
        onToggle(path);
    };

    const handleClick = (location, e) => {
        e.stopPropagation(); // 상위 항목으로의 이벤트 전파를 방지
        if(selectedLocation.name !== location.name){
            onMapData(location);
        }
    };

    return (
        <>
            <div className="row tree-item" style={{alignItems: "center", backgroundColor: selectedLocation.name === item.name ? '#666' : ''}} onClick={handleClick.bind(null, item)}>
                {hasChildren || item.isRoot ? (
                    <FontAwesomeIcon style={{margin: "0 4px 0 0"}} size='2xs' icon={faFolder}/>
                ) : (
                    <FontAwesomeIcon style={{margin: "0 4px"}} size='2xs' icon={item.childIcon}/>
                )}

                {hasChildren || item.isRoot ? (
                    <p className="bold" style={{margin: 0}}>{item.name}</p>
                ) : (
                    <p style={{fontSize: 12, margin: 0}}>{item.name}</p>
                )}

                {((hasChildren || item.isRoot)) &&  (
                    <FontAwesomeIcon style={{margin: "0 0 0 16px"}} size='2xs' icon={isOpen ? faChevronDown : faChevronUp} onClick={handleToggle.bind(null, item)}/>
                )}
            </div>
            {isOpen[`${item.path}`] && hasChildren && (
                <div className="tree-children" style={{overflow: 'hidden', transition: 'max-height 0.3s ease-in-out'}}>
                    {item.children.map((child, index) => (
                        <TreeItem
                            key={index}
                            item={{ ...child, path: `${item.path || item.name}/${child.name}` }}
                            onToggle={onToggle}
                            onMapData={onMapData}
                            selectedLocation={selectedLocation}
                            isOpen={isOpen}
                        />
                    ))}
                </div>
            )}
        </>
    );
};

function Home() {
    const { weatherData, setWeatherData, isWeatherLoading, kakaoLoaded } = useContext(GlobalContext);
    const locationData = [
        {
            isRoot: true,
            name: "대한민국",
            childIcon: faLocation,
            dx: 36.2002,
            dy: 127.054,
            level: 12,
            children: [
                {
                    isRoot: true,
                    name: "전라남도",
                    dx: 35.061480,
                    dy: 126.988566,
                    level: 11,
                    children: [
                        {
                            isRoot: false,
                            childIcon: faLocation,
                            name: "월출지하차도",
                            nx: 59,
                            ny: 76,
                            dx: 35.244108,
                            dy: 126.881600,
                            locationCode: '001',
                            level: 2,
                        },
                    ],
                },
                {
                    isRoot: true,
                    name: "전라북도",
                    dx: 35.842969, // 충청북도 청주 좌표
                    dy: 127.149597,
                    level: 11,
                    children: [
                        {
                            isRoot: false,
                            childIcon: faLocation,
                            name: "월드컵지하차도",
                            nx: 62,
                            ny: 90,
                            dx: 35.8739913,
                            dy: 127.0614241,
                            locationCode: '001',
                            level: 2,
                        },
                    ],
                },
                {
                    isRoot: true,
                    name: "충청북도",
                    dx: 36.9910113,
                    dy: 127.9259497,
                    level: 11,
                    childIcon: faLocation,
                },
                {
                    isRoot: true,
                    name: "충청남도",
                    dx: 36.7058118, // 충청북도 청주 좌표
                    dy: 126.6594254,
                    level: 11,
                    childIcon: faLocation,
                },
                {
                    isRoot: true,
                    name: "경상북도",
                    dx: 36.5683543,
                    dy: 128.729357,
                    level: 11,
                    childIcon: faLocation,
                },
                {
                    isRoot: true,
                    name: "경상남도",
                    dx: 35.56361667, // 경상북도 안동 좌표
                    dy: 128.1679306,
                    level: 11,
                    children: [
                        {
                            isRoot: false,
                            childIcon: faLocation,
                            name: "남해지하차도",
                            nx: 77,
                            ny: 68,
                            dx: 34.8372682,
                            dy: 127.8992329,
                            locationCode: '001',
                            level: 2,
                        },
                    ],
                },
                {
                    isRoot: true,
                    name: "강원특별자치도",
                    dx: 37.69442222, // 강원도 춘천 좌표
                    dy: 127.8908417,
                    level: 11,
                    childIcon: faLocation,
                },
                {
                    isRoot: true,
                    name: "경기도",
                    dx: 37.48893611, // 경기도 수원 좌표
                    dy: 127.4898861,
                    level: 11,
                    childIcon: faLocation,
                },
                {
                    isRoot: true,
                    name: "제주특별자치도",
                    dx: 33.2141, // 강원도 춘천 좌표
                    dy: 126.3148,
                    level: 10,
                    childIcon: faLocation,
                },
            ]
        },
    ];

    const initializeOpenTreeItems = (data, path = '') => {
        return data.reduce((acc, item) => {
            const currentPath = path ? `${path}/${item.name}` : item.name;
            acc[currentPath] = false; // 기본적으로 닫힌 상태로 설정
            if (item.children) {
                Object.assign(acc, initializeOpenTreeItems(item.children, currentPath));
            }
            return acc;
        }, {});
    };

    const [openTreeItems, setOpenTreeItems] = useState(() => initializeOpenTreeItems(locationData));
    const [selectedLocation, setSelectedLocation] = useState(locationData[0]);

    const [ weatherIcon, setWeatherIcon ] = useState(faTruckLoading);
    const [ temp, setTemp ] = useState(0);
    const [ pop, setPop ] = useState(0);
    const [ weatherText, setWeatherText ] = useState("");

    /**
     * 팝업 관련(임시용)
     */
    const [ isPopup, setIsPopup ] = useState(true)
    const [ isSimulation, setIsSimulation ] = useState(false)
    const [ sampleData, setSampleData ] = useState(generateNaturalIncreaseData())
    useEffect(() => {
        const sim_data = generateNaturalIncreaseData(isSimulation ? 103.2 : 0, isSimulation ? 140.2 : 0)
        setSampleData(sim_data)
    }, [isSimulation]);


    const toggleTreeItem = (path) => {
        setOpenTreeItems(prevOpenTreeItems => ({
            ...prevOpenTreeItems,
            [path]: !prevOpenTreeItems[path],
        }));
    };

    const drawPolygonFromGeoJSON = (map, geojsonData, name) => {
        geojsonData.features.forEach((feature) => {
            if(feature.properties.CTP_KOR_NM === name){
                const coordinates = feature.geometry.coordinates[0].map(([lng, lat]) => new window.kakao.maps.LatLng(lat, lng));

                const polygon = new window.kakao.maps.Polygon({
                    path: coordinates,
                    strokeWeight: 2, // 선의 두께
                    strokeColor: '#ff3232', // 선의 색상
                    strokeOpacity: 1, // 선의 불투명도
                    fillColor: '#ff3232', // 채우기 색상
                    fillOpacity: 0.15 // 채우기 불투명도
                });

                polygon.setMap(map);
            }
        })
    };

    const drawMarker = (map, children) => {
        children.forEach((child) => {
            const markerPosition  = new window.kakao.maps.LatLng(child.dx, child.dy);

            const marker = new window.kakao.maps.Marker({
                position: markerPosition,
                title: child.name
            });
            // 마커가 지도 위에 표시되도록 설정합니다
            marker.setMap(map);

            const iwContent = `<div style="width: 100%; padding:5px; color: black; white-space: nowrap; text-align: center;">${child.name}</div>`; // 인포윈도우에 표출될 내용으로 HTML 문자열이나 document element가 가능합니다

            // 인포윈도우를 생성합니다
            const infowindow = new window.kakao.maps.InfoWindow({
                content : iwContent
            });

            // 마커에 마우스오버 이벤트를 등록합니다
            window.kakao.maps.event.addListener(marker, 'mouseover', function() {
                // 마커에 마우스오버 이벤트가 발생하면 인포윈도우를 마커위에 표시합니다
                infowindow.open(map, marker);
            });

            // 마커에 마우스아웃 이벤트를 등록합니다
            window.kakao.maps.event.addListener(marker, 'mouseout', function() {
                // 마커에 마우스아웃 이벤트가 발생하면 인포윈도우를 제거합니다
                infowindow.close();
            });

            if(!child.isRoot){
                window.kakao.maps.event.addListener(marker, 'click', function() {
                    getMapData(child);
                });
            }
        })
    };

    const getMapData = (location) => {
        const { dx, dy, level, name } = location;
        if(window.kakao){
            const container = document.getElementById('map');
            const options = {
                center: new window.kakao.maps.LatLng(dx, dy),
                level: level
            };
            const map = new window.kakao.maps.Map(container, options);

            if(name !== '대한민국'){
                drawPolygonFromGeoJSON(map, geojsonData, name);

                if(location.children && location.children.length > 0){
                    drawMarker(map, location.children);
                }else{
                    if(!location.isRoot){
                        drawMarker(map, [location]);
                    }

                }
            }

            map.setZoomable(false);
            setSelectedLocation(location);
        }
    }

    useEffect(() => {
        if(window.kakao) {
            getMapData(selectedLocation)
        }
    }, [kakaoLoaded]);

    useEffect(() => {
        if(weatherData && weatherData.today){
            const now = new Date();

            // UTC 시간에 9시간을 더해 KST 시간으로 변환
            const kstOffset = 9 * 60 * 60 * 1000; // 9시간을 밀리초로 변환
            const kstDate = new Date(now.getTime() + kstOffset);

            const currentHour = kstDate.getHours();

            const todayItems = weatherData.today.filter(item => item.fcstTime === (String(currentHour).padStart(2, '0') + '00'));

            todayItems.forEach(item => {
                switch (item.category) {
                    case 'TMP':
                        setTemp(item.fcstValue);
                        break;
                    case 'SKY':
                        if(item.fcstValue === "1"){ // 맑은 날씨 아이콘
                            setWeatherIcon(faSun);
                            setWeatherText("맑음");
                        } else if(item.fcstValue === "3"){ // 구름 많음 아이콘
                            setWeatherIcon(faCloudSun);
                            setWeatherText("구름");
                        } else if(item.fcstValue === "4"){ // 흐림 아이콘
                            setWeatherIcon(faCloud);
                            setWeatherText("흐림");
                        }
                        break;
                    case 'PTY':
                        if(item.fcstValue === "1"){ // 비
                            setWeatherIcon(faCloudShowersHeavy);
                            setWeatherText("비");
                        } else if(item.fcstValue === "2"){ // 비와 눈
                            setWeatherIcon(faCloudMeatball);
                            setWeatherText("비와 눈");
                        } else if(item.fcstValue === "3"){ // 눈
                            setWeatherIcon(faSnowflake);
                            setWeatherText("눈");
                        } else if(item.fcstValue === "4"){ // 소나기
                            setWeatherIcon(faCloudRain);
                            setWeatherText("소나기");
                        }
                        break;
                    case 'POP':
                        setPop(item.fcstValue);
                        break;
                }
            });
        }
    }, [weatherData])

    const LocationTree = ({ data }) => {
        return (
            <div className="cctv-tree">
                {data.map((item, index) => (
                    <TreeItem
                        key={index}
                        item={{ ...item, path: item.name }}
                        onToggle={toggleTreeItem}
                        onMapData={getMapData}
                        selectedLocation={selectedLocation}
                        isOpen={openTreeItems} // 논리 NOT 연산자를 두 번 사용하여 undefined를 false로 변환
                    />
                ))}
            </div>
        );
    };

    return (
        <div className="column full-size">
            <div className="row full-size-width" style={{height: "3%", position: "relative", alignItems: "center", borderBottom: "1px solid #ffffff50"}}>
                <FontAwesomeIcon size="xs" style={{margin: 8}} icon={faDesktop} onClick={() => setIsSimulation(!isSimulation)} />
                <p className="bold" style={{width: "calc(80% + 32px)", margin: "8px 8px 8px 0px", fontSize: 13, color: "white"}}>통합 관제 모니터</p>
                <p className="bold" style={{width: "20%",marginLeft: 16, fontSize: 13, color: "white"}}>지역별</p>
            </div>
            <div className="row full-size-width" style={{height: "97%", position: "relative"}}>
                <div className="column full-size" style={{width: "80%", position: "relative", overflow: 'hidden'}}>
                    <div id="map" style={{ width: '100%', height: '100%' }}></div>
                    {(selectedLocation && !selectedLocation.isRoot) && (
                        <div className="column" style={{
                            position: "absolute",
                            width: "25%",
                            height: "90%",
                            right: "1rem",
                            top: "50%",
                            transform: "translateY(-50%)",
                            borderRadius: "1rem",
                            background: "#4f4f4f",
                            zIndex: 10,
                        }}>
                            <div className="column" style={{background: "#cccccc", borderTopLeftRadius: "1rem", borderTopRightRadius: "1rem"}}>
                                <div className="row align-center-left" style={{padding: "0.5rem 1rem"}}>
                                    <FontAwesomeIcon style={{color: "black"}} icon={faLocationPinLock}/>
                                    <p style={{margin: "0 0.5rem", color: "black", fontWeight: "bold"}}>{selectedLocation.name}</p>
                                </div>
                            </div>
                            <div name="weatherGrid" className="column full-size-width align-center" style={{ width: "100%", padding: "0.5rem 0", boxSizing: "border-box"}}>
                                <div style={{position: "relative"}}>
                                    {!isWeatherLoading ? (
                                        <>
                                            <div>
                                                <div className="column full-size align-center"
                                                     style={{padding: "0.5rem 0"}}>
                                                    <FontAwesomeIcon fontSize={58} icon={weatherIcon}/>
                                                    <p style={{margin: 4}}>{weatherText}</p>
                                                </div>
                                                <div className="column full-size-width align-center"
                                                     style={{padding: "0.5rem 0"}}>
                                                    <p style={{margin: 4, fontSize: 18}}>{temp}˚C</p>
                                                    <p style={{margin: 4, fontSize: 12}}>강수확률 {pop}%</p>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <BaseLoadingInContainer height={164}/>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="column"
                                 style={{width: "100%", padding: "0 0.5rem 0.5rem 0.5rem", boxSizing: "border-box"}}>
                                <p style={{margin: "0 0 0.5rem 0", fontSize: 14}}>실시간 현황</p>
                                <MobileCardSlider>
                                    <div style={{height: "10px"}}>
                                        <RTSPPlayer
                                            streamUrl={`https://${backendServer}/live/${selectedLocation.locationCode}/001/stream.m3u8`}
                                            style={{
                                                objectFit: 'cover',
                                                border: "2px solid #00000080",
                                                borderRadius: "0.5rem",
                                                width: "100%",
                                                height: 150
                                            }}/>
                                    </div>
                                    <div style={{height: "10%"}}>
                                        <RTSPPlayer
                                            streamUrl={`https://${backendServer}/live/${selectedLocation.locationCode}/002/stream.m3u8`}
                                            style={{
                                                objectFit: 'cover',
                                                border: "2px solid #00000080",
                                                borderRadius: "0.5rem",
                                                width: "100%",
                                                height: 150
                                            }}/>
                                    </div>
                                    <div style={{height: "10%"}}>
                                        <RTSPPlayer
                                            streamUrl={`https://${backendServer}/live/${selectedLocation.locationCode}/003/stream.m3u8`}
                                            style={{
                                                objectFit: 'cover',
                                                border: "2px solid #00000080",
                                                borderRadius: "0.5rem", width: "100%", height: 150}} />
                                    </div>
                                    <div style={{height: "10%"}}>
                                        <RTSPPlayer streamUrl={`https://${backendServer}/live/${selectedLocation.locationCode}/004/stream.m3u8`} style={{objectFit: 'cover', border: "2px solid #00000080", borderRadius: "0.5rem", width: "100%", height: 150}} />
                                    </div>
                                </MobileCardSlider>
                                <RealTimeWaterLevelChart data={sampleData} />
                                <div className="column full-size-width align-center" style={{margin: "2rem 0"}}>
                                    <FontAwesomeIcon style={{color: isSimulation? "#ffc658" : "#a8e367"}} size='4x' icon={isSimulation ? faWarning : faCheckCircle}/>
                                    <div className="row">
                                        {(isSimulation) ? (
                                            <>
                                                <span style={{margin: "0.5rem 0 0.5rem 0", fontSize: 14}}>현재 수위가 <span
                                                    style={{
                                                        color: "#ffc658",
                                                        margin: 0,
                                                        fontSize: 16
                                                    }}>경계</span>입니다.</span>
                                            </>
                                        ) : (
                                            <>
                                                <span style={{margin: "0.5rem 0 0.5rem 0", fontSize: 14}}>현재 추이가 <span
                                                    style={{
                                                        color: "#a8e367",
                                                        margin: 0,
                                                        fontSize: 16
                                                    }}>안전</span>합니다.</span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {(isPopup) && isSimulation && (
                        <div className="column" style={{
                            position: "absolute",
                            width: "35%",
                            height: "auto",
                            left: "1rem",
                            top: "50%",
                            transform: "translateY(-50%)",
                            borderRadius: "1rem",
                            background: "#4f4f4f",
                            zIndex: 10,
                        }}>
                            <div className="column"
                                 style={{
                                     background: "#cccccc",
                                     borderTopLeftRadius: "1rem",
                                     borderTopRightRadius: "1rem"
                                 }}>
                                <div className="row align-center-left" style={{padding: "0.5rem 1rem"}}>
                                    <FontAwesomeIcon style={{color: "black"}} icon={faSensorAlert}/>
                                    <p className="row full-size-width" style={{
                                        margin: "0 0.5rem",
                                        color: "black",
                                        fontSize: 14
                                    }}>월출지하차도(3번개소)<p
                                        style={{margin: 0, padding: 0, fontWeight: "bold", color: "#d39726"}}>[경계]</p></p>
                                    <FontAwesomeIcon style={{color: "black", cursor: "pointer"}} icon={faTimes} onClick={() => setIsPopup(false)}/>
                                </div>
                            </div>
                            <div className="seperator-half"/>
                            <div className="column"
                                 style={{width: "100%", padding: "0.5rem", boxSizing: "border-box"}}>
                                <div className="row align-center full-size-width">
                                    <div style={{margin: "0.3rem"}}>
                                        <RTSPPlayer
                                            streamUrl={`https://${backendServer}/live/001/004/stream.m3u8`}
                                            style={{
                                                objectFit: 'cover',
                                                border: "2px solid #00000080",
                                                borderRadius: "0.5rem", width: "100%", height: 150
                                            }}/>
                                    </div>
                                </div>
                                <div className="column" style={{width: "100%", boxSizing: "border-box"}}>
                                    <RealTimeWaterLevelChart data={sampleData} />
                                </div>

                                <div className="row align-center full-size-width" style={{marginTop: 64}}>
                                    <BaseButton className="full-size-width" style={{margin: "0 0.5rem"}} onClick={
                                        () => {
                                            setOpenTreeItems(prevOpenTreeItems => ({
                                                ...prevOpenTreeItems,
                                                "대한민국": true,
                                                "대한민국/전라남도": true,
                                            }));
                                            getMapData({
                                                isRoot: false,
                                                childIcon: faLocation,
                                                name: "월출지하차도",
                                                nx: 59,
                                                ny: 76,
                                                dx: 35.244108,
                                                dy: 126.881600,
                                                locationCode: '001',
                                                level: 2,
                                            },)
                                        }
                                    }>위치로
                                        이동</BaseButton>
                                    <BaseButton className="full-size-width" style={{margin: "0 0.5rem"}}
                                                onClick={() => setIsPopup(false)}>닫기</BaseButton>
                                </div>
                                <div className="seperator-half"/>
                            </div>
                        </div>
                    )}
                </div>
                <div className="column full-size" style={{width: "20%", position: "relative", overflow: 'hidden'}}>
                    <LocationTree data={locationData} />
                </div>
            </div>
        </div>
    )
}
export default Home;
