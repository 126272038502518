function formatPhoneNumber(input) {
    const nums = input.replace(/\D/g, "");  // 숫자만 추출

    // 휴대폰 번호 형식
    if ((nums.startsWith("010") || nums.startsWith("011") || nums.startsWith("019")) && nums.length > 3) {
        return `${nums.slice(0, 3)}-${nums.slice(3, 7)}-${nums.slice(7)}`;
    }

    return nums;  // 기본적인 형태를 유지
}

const formatDate = (option, date = new Date()) => {
    if(option === "YYYY-MM-dd"){
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    return null;
}

function generateNaturalIncreaseData(startLevel = 0, endLevel = 0) {
    const data = [];
    let currentTime = new Date();

    const totalPoints = 30;

    let currentLevel = startLevel;

    for (let i = 0; i < totalPoints; i++) {
        const time = new Date(currentTime.getTime() - i * 60000);
        const randomIncrease = (Math.random() * 0.3) + ((endLevel - currentLevel) / (totalPoints - i));
        currentLevel += randomIncrease;

        if (i === totalPoints - 1 && currentLevel > endLevel) {
            currentLevel = endLevel;
        }

        data.push({
            Time: time.getHours() + ':' + time.getMinutes().toString().padStart(2, '0'),
            "Water Level": currentLevel.toFixed(1)
        });
    }

    // 시간순으로 배열을 정렬
    data.sort((a, b) => new Date(a.Time) - new Date(b.Time));

    return data; // 최신 데이터가 오른쪽 끝에 배치되도록 시간순으로 정렬된 데이터를 반환
}


export { formatPhoneNumber, formatDate, generateNaturalIncreaseData }