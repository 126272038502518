import React, {useContext, useEffect, useRef, useState} from 'react';

import '../styles/CCTV.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChevronDown,
    faChevronUp,
    faFolder, faLocationPin,
} from "@fortawesome/free-solid-svg-icons";
import {GlobalContext} from "../page/Main";

import {BaseAutocompleteInput} from "../BaseComponents/BaseAutocompleteInput";
import locationsData from "../modules/locations.json";
import {faChartCandlestick, faLocationPen} from "@fortawesome/pro-duotone-svg-icons";
import {backendServer} from "../App";
import {BaseButton, BaseButtonAwesome, BaseButtonAwesomeTrans} from "../BaseComponents/BaseButton";
import BaseInput from "../BaseComponents/BaseInput";
import {BaseSelector} from "../BaseComponents/BaseSelector";
import {faCars, faLocationArrow, faPeople, faSearch} from "@fortawesome/pro-regular-svg-icons";
import { FixedSizeList as List } from 'react-window';
import BaseNoData from "../BaseComponents/BaseNoData";
import {BaseLoading, BaseLoadingInContainer} from "../BaseComponents/BaseLoading";
import {
    getHeaderFixedColumnStyle,
    getStyle,
    ITEM_SIZE,
    TableRow,
    widthPercentage
} from "../tablerows/sensorData_TableRow";
import {faLeft} from "@fortawesome/sharp-thin-svg-icons";
import {
    faChevronLeft,
    faChevronRight,
    faChevronsLeft,
    faChevronsRight,
    faPlateUtensils
} from "@fortawesome/pro-thin-svg-icons";
import {BaseAlert, BaseAlertDanger, BaseAlertWarn} from "../BaseComponents/BaseAlert";
import {faLocationCircle} from "@fortawesome/pro-light-svg-icons";
import MyCalendar from "../modules/MyCalendar";
import * as XLSX  from 'xlsx-js-style';

function toKSTDate(date) {
    const KST_OFFSET = 9 * 60 * 60 * 1000; // KST와 UTC의 시차를 밀리초로 환산 (9시간)
    const localDate = new Date(date.getTime() + KST_OFFSET);
    return localDate.toISOString().split('T')[0];
}

function SensorData() {
    const [ alertTimeout, setAlertTimeout ] = useState(null);
    const [ isAlert, setIsAlert] = useState(-1);
    const [ alertMsg, setAlertMsg] = useState("");
    const setIsAlertWithTimeout = (alertType, timeOut = 3500) => {
        // 이미 설정된 setTimeout이 있다면 취소합니다.
        if (alertTimeout) {
            clearTimeout(alertTimeout);
        }

        setIsAlert(alertType);

        // 3.5초 후에 알림을 사라지게 만듭니다.
        const timeoutId = setTimeout(() => {
            setIsAlert(-1);
        }, timeOut);

        // 새로 설정된 setTimeout의 ID를 저장합니다.
        setAlertTimeout(timeoutId);
    }

    const currentDate = new Date();
    const end_date = toKSTDate(currentDate); // KST 기준 오늘 날짜

    currentDate.setDate(currentDate.getDate() - 7);
    const start_date = toKSTDate(currentDate); // KST 기준 7일 전 날짜
    const initLocation = {
        code: -99,
        name: '',
        node: -99,
        dx: -99,
        dy: -99
    };

    const { focusLocation, setFocusLocation } = useContext(GlobalContext);
    const [ selectLocation, setSelectLocation ] = useState(initLocation);

    const [ searchValues, setSearchValues ] = useState({
        start_date: start_date,
        end_date: end_date,
        camera_code: 1,
        name: '',
        page_size: 100,
    });

    const [ searchedValues, setSearchedValues ] = useState({
        start_date: start_date,
        end_date: end_date,
        camera_code: 1,
        name: '',
        page_size: 100,
    });

    const [ page, setPage ] = useState(1);
    const [ sensorDatas, setSensorDatas ] = useState([]);
    const [sortDirection, setSortDirection] = useState('desc');

    const [ isLoading, setIsLoading ] = useState(false);
    const divRef = useRef(null);
    const containerRef = useRef(null);
    const [listHeight, setListHeight] = useState(428);
    const [containerWidth, setContainerWidth] = useState(0);

    const [startPage, setStartPage] = useState(1);
    const maxPageButtons = 10;

    const [ offset, setOffset ] = useState(0)

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                setContainerWidth(entry.contentRect.width);
            }
        });

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [containerRef]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                setListHeight(entry.contentRect.height);
            }
        });

        if (divRef.current) {
            resizeObserver.observe(divRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [divRef]);

    const datas = locationsData.locations.reduce((acc, location) => {
        for (let i = 1; i <= location.nodeCount; i++) {
            acc.push({
                code: location.code,
                name: location.name,
                node: i,
                dx: location.dx,
                dy: location.dy
            });
        }
        return acc;
    }, []);

    const toggleSortOrder = () => {
        const sortedDatas = [...sensorDatas.datas].sort((a, b) => {
            const timeA = new Date(a.create_at); // 'time' 필드가 날짜-시간을 나타냄, 적절히 변경 필요
            const timeB = new Date(b.create_at);

            return sortDirection === 'asc' ? timeB - timeA : timeA - timeB;
        });

        // 정렬 방향 토글 및 정렬된 데이터로 상태 업데이트
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        setSensorDatas({ ...sensorDatas, datas: sortedDatas });
    };

    const SearchDatas = async (old = false) => {
        if(selectLocation.code === -99){
            setAlertMsg("검색할 개소를 선택해주세요.");
            setIsAlertWithTimeout(2);
            return;
        }

        try {
            const searchCondition = old ? searchedValues : searchValues;
            setIsLoading(true);
            const response = await fetch(`https://${backendServer}/get/sensorDatas`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                // 필요한 경우 요청 본문에 데이터 포함
                body: JSON.stringify({
                    type: 'paging',
                    ...searchCondition,
                    locations_code: selectLocation.code,
                    camera_code: selectLocation.node,
                    page: page,
                    sort: sortDirection
                }),
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json(); // 응답 본문을 JSON 형태로 파싱
            if(result.datas){
                const data_offset = result.offsets.find(offset => offset.camera_code === `00${selectLocation.node}`.slice(-3))?.raider_offset || 0
                setOffset(data_offset);
                setSensorDatas(result);
            }else{
                setSensorDatas([]);
            }

        } catch (error) {
            console.error('There was a problem with the fetch operation');
        } finally {
            setIsLoading(false);
        }
    }

    const handleSearch = async () => {
        setPage(1);
        setStartPage(1);
        if(selectLocation.code !== -99){
            setSearchedValues({...searchValues, name: selectLocation.name, camera_code: selectLocation.node});
        }

        await SearchDatas();
    };

    useEffect(() => {
        if(selectLocation.code !== -99){
            SearchDatas(true);
        }
    }, [page, sortDirection]);

    const handleExportExcel = () => {
        if(sensorDatas.datas.length === 0){
            setAlertMsg("출력할 데이터가 없습니다.");
            setIsAlertWithTimeout(1);
            return;
        }

        function formatDate(isoString) {
            if (!isoString || isNaN(new Date(isoString).getTime())) {
                return "";
            }

            const date = new Date(isoString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');  // 월은 0부터 시작하기 때문에 1을 더해줍니다.
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        }

        const borderStyle = {
            style: 'thick',
            color: { rgb: '548235' }
        };

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([[]]);

        const columnWidths = [
            { wch: 1.86 },
            { wch: 3.00 },
            { wch: 19.43 },
            { wch: 5.29 },
            { wch: 14.14 },
            { wch: 24.14 },
            { wch: 4.71 },
            { wch: 4.71 },
            { wch: 4.71 },
            { wch: 4.71 },
            { wch: 9.51 },
            { wch: 10.71 },
            { wch: 3.29 },
            { wch: 4.00 },
            { wch: 2.71 },
            { wch: 4.43 },
            { wch: 5.41 },
            { wch: 9.57 },
            { wch: 8.00 },
        ];

        ws['!cols'] = columnWidths;

        ws['!rows'] = [
            {}, // 인덱스 0
            {}, // 인덱스 1
            {}, // 인덱스 2
            { hpx: 24.75 }, // B4, 인덱스 3
            { hpx: 24.75 }, // B5, 인덱스 4
            { hpx: 24.75 }, // B6, 인덱스 5
            { hpx: 24.75 }, // B7, 인덱스 6
            { hpx: 10.50 }, // B8, 인덱스 7
            { hpx: 10.50 }, // B9, 인덱스 8
        ];

        // A1부터 S1까지의 범위를 하나의 셀로 병합
        ws['!merges'] = [
            { s: { r: 1, c: 1 }, e: { r: 1, c: 18 } },
            { s: { r: 3, c: 1 }, e: { r: 3, c: 2 } },
            { s: { r: 4, c: 1 }, e: { r: 4, c: 2 } },
            { s: { r: 5, c: 1 }, e: { r: 5, c: 2 } },
            { s: { r: 6, c: 1 }, e: { r: 6, c: 2 } },
            { s: { r: 3, c: 3 }, e: { r: 3, c: 18 } }, // D4-S4
            { s: { r: 4, c: 3 }, e: { r: 4, c: 18 } }, // D5-S5
            { s: { r: 5, c: 3 }, e: { r: 5, c: 18 } }, // D6-S6
            { s: { r: 6, c: 3 }, e: { r: 6, c: 18 } }, // D7-S7
        ];

        const data = [
            [searchedValues.name, '', '', '', '', '', '', '', ''],
            [searchedValues.camera_code + "번 개소", '', '', '', '', '', '', '', ''],
            [searchedValues.start_date, '', '', '', '', '', '', '', ''],
            [searchedValues.end_date, '', '', '', '', '', '', '', ''],
        ];

        const header = [
            ['No', '위치', '개소', '', '시간', '레이더센서(측정값)', '', '', '레이더센서(실제값)' , '' , '접촉식센서', '', '', '', '', '', '', ''],
        ];

        const body = [];

        for (let i = 0; i < sensorDatas.datas.length + 1; i++) {
            ws['!merges'].push(
                { s: { r: 9+i, c: 3 }, e: { r: 9+i, c: 4 } },
                { s: { r: 9+i, c: 6 }, e: { r: 9+i, c: 8 } },
                { s: { r: 9+i, c: 9 }, e: { r: 9+i, c: 10 } },
                { s: { r: 9+i, c: 11 }, e: { r: 9+i, c: 13 } },
                { s: { r: 9+i, c: 14 }, e: { r: 9+i, c: 16 } },
                { s: { r: 9+i, c: 17 }, e: { r: 9+i, c: 18 } },
            )

            ws['!rows'].push(
                { hpx: 23.25 }
            )

            const history = sensorDatas.datas[i];
            if(history){
                const row = [
                    i+1,
                    searchedValues.name,
                    parseInt(history.camera_code) + "번 개소",
                    '',
                    formatDate(history.create_at),
                    history.sensor1 + "mm",
                    '',
                    '',
                    (history.sensor1 - offset) + "mm",
                    '',
                    history.sensor2 + "mm",
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                ];
                body.push(row);
            }
        }

        // 수정된 부분: B2 셀에 데이터 설정
        XLSX.utils.sheet_add_aoa(ws, [['개소별 데이터차트', '개소별 데이터차트']], { origin: 'B2' });

        XLSX.utils.sheet_add_aoa(ws, [['위치', '위치']], { origin: 'B4' });
        XLSX.utils.sheet_add_aoa(ws, [['개소', '개소']], { origin: 'B5' });
        XLSX.utils.sheet_add_aoa(ws, [['검색범위 (시작)', '검색범위 (시작)']], { origin: 'B6' });
        XLSX.utils.sheet_add_aoa(ws, [['검색범위 (끝)', '검색범위 (끝)']], { origin: 'B7' });

        XLSX.utils.sheet_add_aoa(ws, data, { origin: 'D4' });
        XLSX.utils.sheet_add_aoa(ws, header, { origin: 'B10' });
        XLSX.utils.sheet_add_aoa(ws, body, { origin: 'B11' });

        // 스타일 적용
        let cell = ws['B2'];
        cell.s = {
            font: { name: '맑은 고딕', bold: true, sz: 22, color: { rgb: '548235' } },
            alignment: { horizontal: 'center' },
        };

        let range = XLSX.utils.decode_range('B4:C7');
        for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
                const cell = ws[cellRef];

                if (!cell) {
                    ws[cellRef] = {
                        s: {
                            font: { name: '맑은 고딕', bold: true, sz: 9 },
                            alignment: { horizontal: 'center', vertical: 'center' },
                            fill: { fgColor: { rgb: 'F2F2F2' } },
                            border: {
                                bottom: {
                                    style: 'thin',
                                    color: { rgb: 'C7CCD9' },
                                }
                            }
                        }
                    };
                } else {
                    if (!cell.s) {
                        cell.s = {
                            font: { name: '맑은 고딕', bold: true, sz: 9 },
                            alignment: { horizontal: 'center', vertical: 'center' },
                            fill: { fgColor: { rgb: 'F2F2F2' } },
                            border: {
                                bottom: {
                                    style: 'thin',
                                    color: { rgb: 'C7CCD9' },
                                }
                            }
                        };
                    }
                }
            }
        }


        for (let col = 1; col <= 18; col++) {
            const cellRef = XLSX.utils.encode_cell({ r: 3, c: col });
            const cell = ws[cellRef];
            if (!cell) {
                ws[cellRef] = {
                    s: {
                        border: {
                            top: borderStyle
                        }
                    }
                };
            } else {
                if (!cell.s) {
                    cell.s = {};
                }
                if (!cell.s.border) {
                    cell.s.border = {};
                }
                cell.s.border.top = borderStyle;
            }
        }

        range = XLSX.utils.decode_range('D4:S7');
        for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
                const cell = ws[cellRef];
                if(!cell){
                    ws[cellRef] = {
                        s: {
                            font: { name: '맑은 고딕', sz: 9 },
                            alignment: { horizontal: 'left', vertical: 'center' },
                            border: {
                                bottom: {
                                    style: 'thin',
                                    color: { rgb: 'C7CCD9' }
                                },
                            }
                        }
                    };
                } else {
                    if (!cell.s) {
                        cell.s = {
                            font: { name: '맑은 고딕', sz: 9 },
                            alignment: { horizontal: 'left', vertical: 'center' },
                        };
                    }else{
                        cell.s.font = { name: '맑은 고딕', sz: 9 };
                        cell.s.alignment = { horizontal: 'left', vertical: 'center' };
                    }
                    if (!cell.s.border) {
                        cell.s.border = {};
                    }
                    cell.s.border.bottom = {
                        style: 'thin',
                        color: { rgb: 'C7CCD9' }
                    };
                }
            }
        }

        range = XLSX.utils.decode_range('B10:S10');
        for (let R = range.s.r; R <= range.e.r; ++R) {
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
                const cell = ws[cellRef];
                if(!cell){
                    ws[cellRef] = {
                        s: {
                            font: { name: '맑은 고딕', bold: true, sz: 9 },
                            alignment: { horizontal: 'center', vertical: 'center' },
                            fill: { fgColor: { rgb: 'F2F2F2' } },
                            border: {
                                top: borderStyle,
                                right: {
                                    style: 'thin',
                                    color: { rgb: 'C7CCD9' },
                                },
                                bottom: {
                                    style: 'thin',
                                    color: { rgb: 'C7CCD9' },
                                }
                            }
                        }
                    }
                }else{
                    if (!cell.s) {
                        cell.s = {
                            font: { name: '맑은 고딕', bold: true, sz: 9 },
                            alignment: { horizontal: 'center', vertical: 'center' },
                            fill: { fgColor: { rgb: 'F2F2F2' } },
                            border: {
                                top: borderStyle,
                                right: {
                                    style: 'thin',
                                    color: { rgb: 'C7CCD9' },
                                },
                                bottom: {
                                    style: 'thin',
                                    color: { rgb: 'C7CCD9' },
                                }
                            }
                        }
                    }
                }
            }
        }

        if(sensorDatas.datas.length > 0){
            for(let i=0; i<sensorDatas.datas.length; i++){
                let cellRef = XLSX.utils.encode_cell({ r: 10 + i, c: 1 });
                let cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'center', vertical: 'center' },
                };
                cell.s.border = {
                    right: { style: 'thin', color: { rgb: 'C7CCD9' } },
                    bottom: (i+1) === sensorDatas.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };

                cellRef = XLSX.utils.encode_cell({ r: 10 + i, c: 2 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'left', vertical: 'center' },
                };
                cell.s.border = {
                    right: { style: 'thin', color: { rgb: 'C7CCD9' } },
                    bottom: (i+1) === sensorDatas.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };

                cellRef = XLSX.utils.encode_cell({ r: 10 + i, c: 3 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'left', vertical: 'center' },
                };
                cell.s.border = {
                    bottom: (i+1) === sensorDatas.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };
                cellRef = XLSX.utils.encode_cell({ r: 10 + i, c: 4 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'left', vertical: 'center' },
                };
                cell.s.border = {
                    right: { style: 'thin', color: { rgb: 'C7CCD9' } },
                    bottom: (i+1) === sensorDatas.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };

                cellRef = XLSX.utils.encode_cell({ r: 10 + i, c: 5 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'left', vertical: 'center' },
                };
                cell.s.border = {
                    right: { style: 'thin', color: { rgb: 'C7CCD9' } },
                    bottom: (i+1) === sensorDatas.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };

                cellRef = XLSX.utils.encode_cell({ r: 10 + i, c: 6 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'left', vertical: 'center' },
                };
                cell.s.border = {
                    bottom: (i+1) === sensorDatas.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };
                cellRef = XLSX.utils.encode_cell({ r: 10 + i, c: 7 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'left', vertical: 'center' },
                };
                cell.s.border = {
                    bottom: (i+1) === sensorDatas.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };
                cellRef = XLSX.utils.encode_cell({ r: 10 + i, c: 8 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'left', vertical: 'center' },
                };
                cell.s.border = {
                    bottom: (i+1) === sensorDatas.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };
                cellRef = XLSX.utils.encode_cell({ r: 10 + i, c: 9 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'left', vertical: 'center' },
                };
                cell.s.border = {
                    bottom: (i+1) === sensorDatas.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };
                cellRef = XLSX.utils.encode_cell({ r: 10 + i, c: 10 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'left', vertical: 'center' },
                };
                cell.s.border = {
                    right: { style: 'thin', color: { rgb: 'C7CCD9' } },
                    bottom: (i+1) === sensorDatas.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };

                cellRef = XLSX.utils.encode_cell({ r: 10 + i, c: 11 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'left', vertical: 'center' },
                };
                cell.s.border = {
                    bottom: (i+1) === sensorDatas.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };
                cellRef = XLSX.utils.encode_cell({ r: 10 + i, c: 12 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'left', vertical: 'center' },
                };
                cell.s.border = {
                    bottom: (i+1) === sensorDatas.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };
                cellRef = XLSX.utils.encode_cell({ r: 10 + i, c: 13 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'left', vertical: 'center' },
                };
                cell.s.border = {
                    right: { style: 'thin', color: { rgb: 'C7CCD9' } },
                    bottom: (i+1) === sensorDatas.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };

                cellRef = XLSX.utils.encode_cell({ r: 10 + i, c: 14 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'center', vertical: 'center' },
                };
                cell.s.border = {
                    bottom: (i+1) === sensorDatas.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };
                cellRef = XLSX.utils.encode_cell({ r: 10 + i, c: 15 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'center', vertical: 'center' },
                };
                cell.s.border = {
                    bottom: (i+1) === sensorDatas.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };
                cellRef = XLSX.utils.encode_cell({ r: 10 + i, c: 16 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'center', vertical: 'center' },
                };
                cell.s.border = {
                    right: { style: 'thin', color: { rgb: 'C7CCD9' } },
                    bottom: (i+1) === sensorDatas.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };

                cellRef = XLSX.utils.encode_cell({ r: 10 + i, c: 17 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'center', vertical: 'center' },
                };
                cell.s.border = {
                    bottom: (i+1) === sensorDatas.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };
                cellRef = XLSX.utils.encode_cell({ r: 10 + i, c: 18 });
                cell = ws[cellRef];
                if (!cell) {
                    ws[cellRef] = { s: {} };
                    cell = ws[cellRef]; // 재할당하여 cell에 업데이트된 객체를 할당합니다.
                }
                cell.s = {
                    font: { name: '맑은 고딕', sz: 9 },
                    alignment: { horizontal: 'center', vertical: 'center' },
                };
                cell.s.border = {
                    right: { style: 'thin', color: { rgb: 'C7CCD9' } },
                    bottom: (i+1) === sensorDatas.datas.length ? borderStyle : { style: 'thin', color: { rgb: 'C7CCD9' } }
                };
            }
        }

        // 워크북에 시트 추가
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        const baseFileName = `[${searchedValues.name}-${searchedValues.camera_code}번 개소]_${searchedValues.page_size}_데이터 차트 (${searchedValues.start_date}~${searchedValues.end_date})`
        // 엑셀 파일로 저장
        XLSX.writeFile(wb, `${baseFileName}.xlsx`);
    };

    return (
        <>
            <div className="row full-size" style={{position: "relative", overflow: "hidden"}}>
                <div className="column full-size" style={{width: "80%", position: "relative", overflow: "hidden"}}>
                    <div className="row full-size-width" style={{
                        height: "3%",
                        position: "relative",
                        alignItems: "center",
                        borderBottom: "1px solid #ffffff50"
                    }}>
                        <FontAwesomeIcon icon={faChartCandlestick} size="xs" style={{margin: 8}}/>
                        <p className="bold"
                           style={{
                               width: "calc(80% + 32px)",
                               margin: "16px 16px 16px 0",
                               fontSize: 13,
                               color: "white"
                           }}>수위
                            데이터 보기</p>
                    </div>
                    <div className="seperator-half-top"/>
                    <div className="row full-size-width align-center">
                        <div className="column full-size-width">
                            <div className="row full-size-width category-texts align-center">
                                <p>
                                    페이지 당 갯수
                                </p>
                                <p>
                                    검색 범위(시작)
                                </p>
                                <p>
                                    검색 범위(끝)
                                </p>
                                <p>
                                    검색할 개소
                                </p>
                            </div>
                            <div className="row full-size-width">
                                <BaseSelector
                                    options={[
                                        {value: '100', label: '100'},
                                        {value: '200', label: '200'},
                                        {value: '300', label: '300'},
                                        {value: '600', label: '600'},
                                        {value: '1200', label: '1200'},
                                        {value: '2400', label: '2400'},
                                        {value: '4800', label: '4800'},
                                    ]}
                                    onChange={(e) => {
                                        setSearchValues(prevValues => ({
                                            ...prevValues,
                                            page_size: e.target.value
                                        }));
                                    }}
                                />
                                <BaseInput
                                    name="start_date"
                                    value={searchValues.start_date}
                                    onChange={(e) => {
                                        setSearchValues(prevValues => ({
                                            ...prevValues,
                                            start_date: e.target.value
                                        }));
                                    }}
                                    type="date"
                                    data-placeholder="검색시작일"
                                    aria-description="condition"
                                />
                                <BaseInput
                                    name="end_date"
                                    value={searchValues.end_date}
                                    onChange={(e) => {
                                        setSearchValues(prevValues => ({
                                            ...prevValues,
                                            end_date: e.target.value
                                        }));
                                    }}
                                    type="date"
                                    data-placeholder="검색종료일"
                                    aria-description="condition"
                                />
                                <BaseAutocompleteInput
                                    data={datas}
                                    displayFunc={data => {
                                        return `${data.name}(${data.node}번 개소)`;
                                    }}
                                    suggestionFunc={data => {
                                        return `${data.name}\t${data.node}번 개소`;
                                    }}
                                    suggestionClick={(data) => {
                                        if (data) {
                                            setSelectLocation(data);
                                        } else {
                                            setSelectLocation(initLocation);
                                        }
                                    }}
                                    name="location"
                                    value={selectLocation.name}
                                    onChange={() => {
                                    }}
                                    placeholder="지역명으로 검색해보세요."
                                />
                                <div style={{
                                    borderBottom: "1px solid #cccccc50",
                                    marginLeft: "1rem",
                                    marginRight: "1rem",
                                    boxSizing: "border-box"
                                }}/>
                            </div>
                        </div>
                        <BaseButtonAwesome icon={faSearch} onClick={handleSearch}/>
                    </div>
                    <div style={{borderBottom: "1px solid #ffffff50", margin: "0.5rem"}}/>
                    <div className="row">
                        <div className="seperator-horz-half"/>
                        <div className="row full-size-width align-center"
                             style={{background: "rgba(52,64,73,0.7)", padding: "0.4rem"}}>
                            <FontAwesomeIcon icon={faLocationArrow} size={"xs"}/>
                            <p className="text-align-left full-size-width"
                               style={{padding: "0.4rem", margin: 0, fontSize: 12}}>개소명</p>
                            <p className="text-align-right full-size-width"
                               style={{
                                   padding: "0.4rem",
                                   margin: 0,
                                   fontSize: 12
                               }}>{searchedValues.name}({searchedValues.node}번 개소)</p>
                        </div>
                        <div className="seperator-horz-half"/>
                        <div className="row full-size-width align-center"
                             style={{background: "rgba(52,64,73,0.7)", padding: "0.4rem"}}>
                            <FontAwesomeIcon icon={faSearch} size={"xs"}/>
                            <p className="text-align-left full-size-width"
                               style={{padding: "0.4rem", margin: 0, fontSize: 12}}>검색된 데이터</p>
                            <p className="text-align-right full-size-width"
                               style={{padding: "0.4rem", margin: 0, fontSize: 12}}>{sensorDatas.total_count} 개</p>
                        </div>
                        <div className="seperator-horz-half"/>

                    </div>
                    <div className="seperator-half-top"/>
                    <div className="column main-table-body" ref={containerRef}>
                        {isLoading ? (
                            <>
                                <div className="column full-size align-center">
                                    <BaseLoadingInContainer/>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="table-container-h1" ref={divRef}>
                                    <>
                                        {sensorDatas.length === 0 ? (
                                            <>
                                                <BaseNoData/>
                                            </>
                                        ) : (
                                            <>
                                                <div className="column table-body-container">
                                                    <div className="row full-size-width table-header"
                                                         style={{height: 24}}>
                                                        <div className="column align-center"
                                                             style={{
                                                                 width: widthPercentage[0],
                                                                 backgroundColor: '#ffffff',
                                                                 overflow: 'hidden',
                                                                 textOverflow: 'ellipsis',
                                                                 whiteSpace: 'nowrap'
                                                             }}>
                                                            <p className="text-align-center">순번</p>
                                                        </div>
                                                        <div className="column align-center"
                                                             style={{
                                                                 width: widthPercentage[2],
                                                                 backgroundColor: '#ffffff',
                                                                 overflow: 'hidden',
                                                                 textOverflow: 'ellipsis',
                                                                 whiteSpace: 'nowrap'
                                                             }}>
                                                            <p className="text-align-center">위치</p>
                                                        </div>
                                                        <div className="column align-center"
                                                             style={{
                                                                 width: widthPercentage[2],
                                                                 backgroundColor: '#ffffff',
                                                                 overflow: 'hidden',
                                                                 textOverflow: 'ellipsis',
                                                                 whiteSpace: 'nowrap'
                                                             }}>
                                                            <p className="text-align-center">개소</p>
                                                        </div>
                                                        <div className="column align-center"
                                                             style={{
                                                                 cursor: "pointer",
                                                                 width: widthPercentage[3],
                                                                 backgroundColor: '#ffffff',
                                                                 overflow: 'hidden',
                                                                 textOverflow: 'ellipsis',
                                                                 whiteSpace: 'nowrap'
                                                             }}
                                                             onClick={toggleSortOrder}
                                                        >
                                                            <p className="text-align-center">시간</p>
                                                            <FontAwesomeIcon
                                                                icon={sortDirection === 'asc' ? faChevronUp : faChevronDown}
                                                                size={"xs"} style={{
                                                                color: "black",
                                                                position: "absolute",
                                                                right: 8,
                                                                top: "50%",
                                                                transform: "translateY(-50%)"
                                                            }}/>
                                                        </div>
                                                        <div className="column align-center"
                                                             style={{
                                                                 width: widthPercentage[2],
                                                                 backgroundColor: '#ffffff',
                                                                 overflow: 'hidden',
                                                                 textOverflow: 'ellipsis',
                                                                 whiteSpace: 'nowrap'
                                                             }}>
                                                            <p className="text-align-center">레이더센서(측정값)</p>
                                                        </div>
                                                        <div className="column align-center"
                                                             style={{
                                                                 width: widthPercentage[2],
                                                                 backgroundColor: '#ffffff',
                                                                 overflow: 'hidden',
                                                                 textOverflow: 'ellipsis',
                                                                 whiteSpace: 'nowrap'
                                                             }}>
                                                            <p className="text-align-center">레이더센서(실제값)</p>
                                                        </div>
                                                        <div className="column align-center"
                                                             style={{
                                                                 width: widthPercentage[2],
                                                                 backgroundColor: '#ffffff',
                                                                 overflow: 'hidden',
                                                                 textOverflow: 'ellipsis',
                                                                 whiteSpace: 'nowrap'
                                                             }}>
                                                            <p className="text-align-center">접촉식센서</p>
                                                        </div>
                                                    </div>
                                                    <List
                                                        width={containerWidth}
                                                        height={listHeight - 25}
                                                        className="table full-size-width"
                                                        itemCount={sensorDatas.datas.length}
                                                        itemSize={ITEM_SIZE}
                                                        itemData={{
                                                            datas: sensorDatas.datas,
                                                            containerWidth,
                                                            page,
                                                            page_size: searchedValues.page_size,
                                                            offset: offset
                                                        }}
                                                    >
                                                        {TableRow}
                                                    </List>
                                                </div>
                                            </>
                                        )}
                                    </>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="seperator-half-top"/>
                    {sensorDatas.length !== 0 && (
                        <>
                            <div className="row full-size-width align-center page-buttons">
                                {startPage > 1 && (
                                    <>
                                        <button className="column align-center"
                                                onClick={() => {
                                                    setStartPage(1)
                                                    setPage(1)
                                                }}
                                                style={{
                                                    margin: "0 0.25rem",
                                                    width: 30,
                                                    height: 30,
                                                    boxSizing: "border-box",
                                                    border: "1px solid black",
                                                    borderRadius: "0.5rem",
                                                }}>
                                            <FontAwesomeIcon icon={faChevronsLeft}/>
                                        </button>
                                        <button className="column align-center"
                                                onClick={() => {
                                                    setStartPage(startPage - maxPageButtons)
                                                }}
                                                style={{
                                                    margin: "0 0.25rem",
                                                    width: 30,
                                                    height: 30,
                                                    boxSizing: "border-box",
                                                    border: "1px solid black",
                                                    borderRadius: "0.5rem",
                                                }}>
                                            <FontAwesomeIcon icon={faChevronLeft}/>
                                        </button>
                                    </>
                                )}


                                {Array.from({length: Math.min(maxPageButtons, sensorDatas.page_count - startPage + 1)}, (_, i) => startPage + i).map(pageNumber => (
                                    <button
                                        key={pageNumber}
                                        style={{
                                            margin: "0 0.25rem",
                                            width: 30, height: 30,
                                            boxSizing: "border-box",
                                            backgroundColor: pageNumber === page ? "#3e7ee5" : "#cccccc",
                                            color: pageNumber === page ? "white" : "black",
                                            border: "1px solid #00000080",
                                            borderRadius: "0.5rem",
                                        }}
                                        onClick={() => setPage(pageNumber)}
                                    >
                                        {pageNumber}
                                    </button>
                                ))}

                                {startPage + maxPageButtons - 1 < sensorDatas.page_count && (
                                    <>
                                        <button className="column align-center"
                                                onClick={() => {
                                                    setStartPage(startPage + maxPageButtons)
                                                }}
                                                style={{
                                                    margin: "0 0.25rem",
                                                    width: 30,
                                                    height: 30,
                                                    boxSizing: "border-box",
                                                    border: "1px solid black",
                                                    borderRadius: "0.5rem",
                                                }}>
                                            <FontAwesomeIcon icon={faChevronRight}/>
                                        </button>
                                        <button className="column align-center"
                                                onClick={() => {
                                                    const remainder = sensorDatas.page_count % maxPageButtons;
                                                    const startPageForLastSet = remainder === 0 ? sensorDatas.page_count - maxPageButtons + 1 : sensorDatas.page_count - remainder + 1;

                                                    setPage(sensorDatas.page_count)
                                                    setStartPage(startPageForLastSet)
                                                }}
                                                style={{
                                                    margin: "0 0.25rem",
                                                    width: 30,
                                                    height: 30,
                                                    boxSizing: "border-box",
                                                    border: "1px solid black",
                                                    borderRadius: "0.5rem",
                                                }}>
                                            <FontAwesomeIcon icon={faChevronsRight}/>
                                        </button>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>
                <div className="column full-size" style={{width: "20%", position: "relative", overflow: 'hidden'}}>
                    <div className="row" style={{
                        height: "3%",
                        position: "relative",
                        alignItems: "center",
                        borderBottom: "1px solid #ffffff50"
                    }}>
                        <p className="bold" style={{width: "20%", marginLeft: 16, fontSize: 13, color: "white"}}>개요</p>
                    </div>
                    <MyCalendar events={[
                        {
                            start: new Date(searchedValues.start_date),
                            end: new Date(searchedValues.end_date),
                            title: "검색된 데이터",
                            resource: {
                                count: sensorDatas.total_count,
                            },
                        },
                    ]} />
                    <div className="column full-size-width" style={{position: "relative"}}>
                        <p className="less-important-info" style={{padding: "0 0.5rem", margin: 0}}>※검색된 범위(현재페이지)만 추출하여 저장합니다.</p>
                        <button
                            className="download-button"
                            onClick={handleExportExcel}
                        >
                            엑셀 다운로드
                        </button>

                    </div>
                </div>
            </div>
            {isAlert === 0 && (
                <>
                    <BaseAlert msg={alertMsg}></BaseAlert>
                </>
            )}
            {isAlert === 1 && (
                <>
                    <BaseAlertWarn msg={alertMsg}></BaseAlertWarn>
                </>
            )}
            {isAlert === 2 && (
                <>
                    <BaseAlertDanger msg={alertMsg}></BaseAlertDanger>
                </>
            )}
        </>
    );
}

export default SensorData;
