import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./page/Main";
import RTSPPlayer from "./BaseComponents/RTSPPlayer";
import React, {useEffect, useState} from "react";
import SignIn from "./page/SignIn";


export const backendServer = "nhnetworks.nwc-hosting.com:4567"
export const AppContext = React.createContext();

function App() {
    const [profileImage, setProfileImage] = useState('');
    const [isDefaultImage, setIsDefaultImage] = useState(false);

    return (
      <Router>
          <AppContext.Provider value={{
              profileImage, setProfileImage,
              isDefaultImage, setIsDefaultImage,
          }}>
              <Routes>
                  <Route path="/" element={
                      <Main />
                  } />
                  <Route path="/signin" element={
                      <SignIn />
                  } />
              </Routes>
          </AppContext.Provider>
      </Router>
    );
}

export default App;
