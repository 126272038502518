import React, {useContext, useEffect, useRef, useState} from 'react';

import '../styles/CCTV.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {GlobalContext} from "../page/Main";
import {faCalendarCheck, faLineHeight, faPhone, faUserCog} from "@fortawesome/pro-duotone-svg-icons";
import {BaseLoadingInContainer} from "../BaseComponents/BaseLoading";
import BaseNoData from "../BaseComponents/BaseNoData";
import {ITEM_SIZE, TableRow, widthPercentage} from "../tablerows/sensorData_TableRow";
import {
    faBarChart,
    faChevronDown,
    faChevronUp,
    faClock, faEye, faEyeSlash, faLock,
    faTint,
    faUser,
    faWater
} from "@fortawesome/free-solid-svg-icons";
import {FixedSizeList as List} from "react-window";
import {AppContext, backendServer} from "../App";
import userImage from "../images/free-icon-user-5264565.png";
import {Bar, BarChart, Cell, LabelList, Pie, PieChart, ReferenceLine, XAxis} from "recharts";
import Needle from "../BaseComponents/Needle";
import {
    faBells, faBookmark,
    faBuildings, faClockEight,
    faIdCard,
    faPencil,
    faTimes,
    faTrash,
    faUserAstronaut
} from "@fortawesome/pro-regular-svg-icons";
import {formatDate, formatPhoneNumber} from "../modules/lib/tool";

import {Box, Slider} from "@mui/material";
import OnOffSlider from "../Slider/OnOffSlider";
import {BaseAlert, BaseAlertDanger, BaseAlertWarn} from "../BaseComponents/BaseAlert";
import {faSave} from "@fortawesome/pro-solid-svg-icons";
import MyCalendar from "../modules/MyCalendar";
import ScheduleCalendar from "../modules/ScheduleCalendar";
import locationsData from "../modules/locations.json";
import {BaseAutocompleteInput, BaseAutocompleteInput2} from "../BaseComponents/BaseAutocompleteInput";
import UserListModal from "../Modal/userListModal";

function Setting() {
    const { profileImage, setProfileImage, isDefaultImage, setIsDefaultImage } = useContext(AppContext);
    const [ alertTimeout, setAlertTimeout ] = useState(null);
    const [ isAlert, setIsAlert] = useState(-1);
    const [ alertMsg, setAlertMsg] = useState("");
    const setIsAlertWithTimeout = (alertType, timeOut = 3500) => {
        // 이미 설정된 setTimeout이 있다면 취소합니다.
        if (alertTimeout) {
            clearTimeout(alertTimeout);
        }

        setIsAlert(alertType);

        // 3.5초 후에 알림을 사라지게 만듭니다.
        const timeoutId = setTimeout(() => {
            setIsAlert(-1);
        }, timeOut);

        // 새로 설정된 setTimeout의 ID를 저장합니다.
        setAlertTimeout(timeoutId);
    }

    const initLocation = {
        code: -99,
        name: '',
        node: -99,
        dx: -99,
        dy: -99
    };
    const [ selectLocation, setSelectLocation ] = useState(initLocation);

    const { focusLocation, setFocusLocation } = useContext(GlobalContext);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ userInfo, setUserInfo ] = useState({
        name: '',
        phone: '',
        affiliation: '',
        active: 0,
        role: -1,
    });
    const [ values, setValues ] = useState({
        message: false,
        kakao: false,
        phone: '',
        limit: 120,
        active: false,
        name: '',
        affiliation: '',
    })

    const [ userValue, setUserValue ] = useState({
        user_id: '',
        user_pwd: '',
        user_name: '',
        user_affiliation: '',
    })

    const [ adminValues, setAdminValues ] = useState({
        CCTV: false,
        dataChart: false,
        sensorData: false,
        objectData: false,
    })

    const [ userModifyValues, setUserModifyValues ] = useState({
        id: '',
        pwd: '',
    })

    const [ isModal, setIsModal ] = useState(false);
    const [ modalType, setModalType ] = useState(0);
    const [ userList, setUserList ] = useState([]);

    const [ offsetValue, setOffsetValue ] = useState(0)

    const [ saveHover, setSaveHover] = useState(false);
    const [ saveClick, setSaveClick] = useState(false);
    const [ PWDType, setPWDType] = useState("password");
    const [ autoCompleteFocus , setAutoCompleteFocus] = useState(false);

    const [ scheduleDate , setScheduleDate ] = useState(formatDate("YYYY-MM-dd"));
    const [ scheduleData, setScheduleData ] = useState([]);

    const [ datas, getDatas ] = useState([]);

    const fileInputRef = useRef(null);

    const triggerFileInput = () => {
        fileInputRef.current.click(); // 숨겨진 파일 입력을 클릭하여 파일 선택기를 엽니다.
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
            handleUpload(file);
        } else {
            setAlertMsg("올바른 파일을 선택해주세요. (JPEG, PNG만 허용)");
            setIsAlertWithTimeout(2);
        }
    };

    const [ alertSet, setAlertSet ] = useState(0);

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        const prevValue = values[name];

        if(name === "alertSet"){
            setAlertSet(value);
            return;
        }

        if (type === "checkbox") {
            setValues(prevValues => ({
                ...prevValues,
                [name]: checked
            }));
        } else {
            if (name === "phone") {
                // 입력값이 줄어들었을 때는 형식화를 건너뛰기
                if (value.length < prevValue.length) {
                    setValues(prevValues => ({
                        ...prevValues,
                        phone: value
                    }));
                } else {
                    if(prevValue.replaceAll(/-/g,"").length === 11) return;

                    setValues(prevValues => ({
                        ...prevValues,
                        phone: formatPhoneNumber(value)
                    }));
                }
            } else if(name === "limit"){
                let numsStr = value.replace(/\D/g, "");
                numsStr = numsStr.substring(0, 5);
                const nums = parseInt(numsStr, 10);

                setValues(prevValues => ({
                    ...prevValues,
                    [name]: isNaN(nums) ? 0 : nums
                }));
            } else {
                setValues(prevValues => ({
                    ...prevValues,
                    [name]: value
                }));
            }
        }
    }

    const handleChangeOffset = (event) => {
        const { value } = event.target;

        let numsStr = value.replace(/\D/g, "");
        numsStr = numsStr.substring(0, 5);
        const nums = parseInt(numsStr, 10);

        setOffsetValue(isNaN(nums) ? 0 : nums);
    }

    const handleChangeAdmin = (event) => {
        const { name, value, type, checked } = event.target;

        if (type === "checkbox") {
            setAdminValues(prevValues => ({
                ...prevValues,
                [name]: checked
            }));
        }
    }

    const handleChangeUserModifyValue = (event) => {
        const { name, value } = event.target;

        setUserModifyValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    }

    const handleChangeUser = (event) => {
        const { name, value } = event.target;

        if (name === "user_pwd") {
            // 한글 입력 방지 및 대문자를 소문자로 변환
            const processedValue = value.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g, '').toLowerCase();

            // 입력 길이 제한 (최대 12자)
            const finalValue = processedValue.slice(0, 12);

            setUserValue(prevValues => ({
                ...prevValues,
                [name]: finalValue
            }));
        } else {
            // 다른 입력 필드의 경우 기존 로직 유지
            setUserValue(prevValues => ({
                ...prevValues,
                [name]: value
            }));
        }
    }

    const handleUpload = async (file) => {
        const formData = new FormData();
        formData.append('profileImg', file);

        try {
            const response = await fetch(`https://${backendServer}/profile/upload`, {
                method: 'POST',
                body: formData,
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('File upload failed');
            }

            const pngUrl = `https://${backendServer}/profiles/profile_image_${userInfo.user_id}.png`
            const jpegUrl = `https://${backendServer}/profiles/profile_image_${userInfo.user_id}.jpg`

            if (file.type === "image/png") {
                setProfileImage(pngUrl);
            } else if (file.type === "image/jpeg") {
                setProfileImage(jpegUrl);
            }
            setIsDefaultImage(false);
            window.location.reload();

            setAlertMsg("프로필 사진을 업데이트 했습니다.");
            setIsAlertWithTimeout(0);
        } catch (error) {
            setAlertMsg("서버와 통신중 에러가 발생했습니다.");
            setIsAlertWithTimeout(2);
        }
    }

    const handleSave = async () => {
        if((values.phone === '' && values.active) || (values.phone === '' && values.message) || (values.phone === '' && values.kakao)){
            setAlertMsg("문자나 카카오 알림을 받으려면 번호를 작성해야 합니다.");
            setIsAlertWithTimeout(1);
            return;
        }
        if(values.limit < 30){
            setAlertMsg("임계점은 최소 30이상 설정해 주세요. (기본값 : 120)");
            setIsAlertWithTimeout(1);
            return;
        }
        if(values.name === ''){
            setAlertMsg("이름을 작성해 주세요.");
            setIsAlertWithTimeout(1);
            return;
        }
        if(values.affiliation === ''){
            setAlertMsg("소속을 작성해 주세요.");
            setIsAlertWithTimeout(1);
            return;
        }

        try {
            const response = await fetch(`https://${backendServer}/settings/save`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(values),
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('File upload failed');
            }

            if(userInfo.role === 1){
                const responseAdmin = await fetch(`https://${backendServer}/settings/save/admin`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(adminValues),
                    credentials: 'include'
                });
            }

            setAlertMsg("정보를 성공적으로 저장했습니다.");
            setIsAlertWithTimeout(0);
        } catch (error) {
            setAlertMsg("서버와 통신중 에러가 발생했습니다.");
            setIsAlertWithTimeout(2);
        }
    }

    const handleSaveOffset = async () => {

        if(selectLocation.code === -99){
            setAlertMsg("선택된 위치가 없습니다.");
            setIsAlertWithTimeout(1);
            return;
        }

        try {
            const response = await fetch(`https://${backendServer}/offset/save`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({value: offsetValue, location_code: selectLocation.code, camera_code: selectLocation.node.toString().padStart(3, '0')}),
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Save Offset failed');
            }
            GetOffsetDatas();
            setAlertMsg("정보를 성공적으로 저장했습니다.");
            setIsAlertWithTimeout(0);
        } catch (error) {
            setAlertMsg("서버와 통신중 에러가 발생했습니다.");
            setIsAlertWithTimeout(2);
        }
    }

    const handleInitOffset = async () => {
        const confirm = window.confirm("오프셋 값을 초기값으로 되돌리시겠습니까?")
        if(confirm){
            try {
                const response = await fetch(`https://${backendServer}/offset/init`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include'
                });

                if (!response.ok) {
                    throw new Error('Save Offset failed');
                }
                GetOffsetDatas();
                setAlertMsg("오프셋 값을 초기화 하였습니다.");
                setIsAlertWithTimeout(0);
            } catch (error) {
                setAlertMsg("서버와 통신중 에러가 발생했습니다.");
                setIsAlertWithTimeout(2);
            }
        }
    }

    const handleAddUser = async () => {
        if(userValue.user_name === ''){
            setAlertMsg("사용자를 추가하려면 이름을 적어주세요.");
            setIsAlertWithTimeout(1);
            return;
        }

        if(userValue.user_id === ''){
            setAlertMsg("사용자를 추가하려면 아이디를 적어주세요.");
            setIsAlertWithTimeout(1);
            return;
        }

        if(userValue.user_pwd === ''){
            setAlertMsg("사용자를 추가하려면 패스워드를 적어주세요.(6-12자)");
            setIsAlertWithTimeout(1);
            return;
        }else{
            if(userValue.user_pwd.length < 6 || userValue.user_pwd.length > 12) {
                setAlertMsg("비밀번호는 6-12자 사이여야 합니다.");
                setIsAlertWithTimeout(1);
                return;
            }

            const koreanPattern = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
            if(koreanPattern.test(userValue.user_pwd)) {
                setAlertMsg("비밀번호에 한글을 사용할 수 없습니다.");
                setIsAlertWithTimeout(1);
                return;
            }
        }

        if(userValue.user_affiliation === ''){
            setAlertMsg("사용자를 추가하려면 소속을 작성해 주세요.");
            setIsAlertWithTimeout(1);
            return;
        }

        try {
            const response = await fetch(`https://${backendServer}/user/reg`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(userValue),
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('File upload failed');
            }

            setAlertMsg("유저를 성공적으로 추가했습니다.");
            setIsAlertWithTimeout(0);

            setUserValue({
                user_id: '',
                user_pwd: '',
                user_name: '',
                user_affiliation: '',
            })
        } catch (error) {
            setAlertMsg("서버와 통신중 에러가 발생했습니다.");
            setIsAlertWithTimeout(2);
        }
    }

    const handleModifyUser = async () => {
        if(userModifyValues.id === ''){
            setAlertMsg("아이디를 적어주세요.");
            setIsAlertWithTimeout(1);
            return;
        }

        if(userModifyValues.pwd === ''){
            setAlertMsg("사용자 비밀번호를 변경하려면 비밀번호 칸을 적어주세요.(6-12자)");
            setIsAlertWithTimeout(1);
            return;
        }else{
            if(userModifyValues.pwd.length < 6 || userModifyValues.pwd.length > 12) {
                setAlertMsg("비밀번호는 6-12자 사이여야 합니다.");
                setIsAlertWithTimeout(1);
                return;
            }

            const koreanPattern = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
            if(koreanPattern.test(userModifyValues.pwd)) {
                setAlertMsg("비밀번호에 한글을 사용할 수 없습니다.");
                setIsAlertWithTimeout(1);
                return;
            }
        }

        try {
            const response = await fetch(`https://${backendServer}/user/modify`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(userModifyValues),
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('File upload failed');
            }

            const result = await response.json();

            if(result.message === "User Not Founded"){
                setAlertMsg("유저 정보를 찾을 수 없습니다.");
                setIsAlertWithTimeout(0);
            }else{
                setAlertMsg("유저를 성공적으로 수정했습니다.");
                setIsAlertWithTimeout(0);

                setUserModifyValues({
                    id: '',
                    pwd: '',
                })
            }

        } catch (error) {
            setAlertMsg("서버와 통신중 에러가 발생했습니다.");
            setIsAlertWithTimeout(2);
        }
    }

    const handleDeleteUser = async () => {
        if(userModifyValues.id === ''){
            setAlertMsg("삭제할 아이디를 적어주세요.");
            setIsAlertWithTimeout(1);
            return;
        }

        // eslint-disable-next-line no-restricted-globals
        const isConfirmed = confirm(`정말 ${userModifyValues.id} 유저를 삭제하시겠습니까?`);
        if (!isConfirmed) {
            return;
        }

        try {
            const response = await fetch(`https://${backendServer}/user/delete`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(userModifyValues),
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('File upload failed');
            }

            const result = await response.json();

            if(result.message === "User Not Founded"){
                setAlertMsg("유저 정보를 찾을 수 없습니다.");
                setIsAlertWithTimeout(0);
            }else{
                setAlertMsg("유저를 성공적으로 삭제했습니다.");
                setIsAlertWithTimeout(0);

                setUserModifyValues({
                    id: '',
                    pwd: '',
                })
            }

        } catch (error) {
            setAlertMsg("서버와 통신중 에러가 발생했습니다.");
            setIsAlertWithTimeout(2);
        }
    }

    const handleShowUsers = async () => {
        try {
            const response = await fetch(`https://${backendServer}/users/get`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('File upload failed');
            }

            const result = await response.json();
            setUserList(result);
            setIsModal(true);
        } catch (error) {
            setAlertMsg("서버와 통신중 에러가 발생했습니다.");
            setIsAlertWithTimeout(2);
        }
    }

    const getSchedule = async () => {
        try {
            const response = await fetch(`https://${backendServer}/schedule/get`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({range: scheduleDate}),
            });

            if (response.ok) {
                const result = await response.json();
                setScheduleData(result);
            } else {
                const result = await response.json();
                throw new Error(result.message);
            }
        }catch(e){
            setAlertMsg("서버와 통신중 에러가 발생했습니다.");
            setIsAlertWithTimeout(2);
        }
    }
    const GetOffsetDatas = async () => {
        try {
            const response = await fetch(`https://${backendServer}/offset/get`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
            });

            if (response.ok) {
                const result = await response.json();

                const offsetData = locationsData.locations.reduce((acc, location) => {
                    for (let i = 1; i <= location.nodeCount; i++) {
                        const cameraCode = String(i).padStart(3, '0');
                        const offsetItem = result.find(offset => offset.camera_code === cameraCode && offset.location_code === location.code);
                        acc.push({
                            code: location.code,
                            name: location.name,
                            node: i,
                            dx: location.dx,
                            dy: location.dy,
                            offset: offsetItem ? offsetItem.raider_offset : '오프셋 미등록'
                        });
                    }
                    return acc;
                }, []);

                getDatas(offsetData);
            } else {
                const result = await response.json();
                throw new Error(result.message);
            }
        }catch(e){
            setAlertMsg("서버와 통신중 에러가 발생했습니다.");
            setIsAlertWithTimeout(2);
        }
    }

    useEffect(() => {
        const checkRole = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(`https://${backendServer}/check-role`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include',
                    body: JSON.stringify({flag: true}),
                });

                if (response.ok) {
                    const result = await response.json();
                    setUserInfo(result.userDatas);
                    setValues({
                        phone: result.userDatas.phone ? formatPhoneNumber(result.userDatas.phone) : '',
                        name : result.userDatas.name,
                        affiliation: result.userDatas.affiliation,
                        active: result.settings.active !== 0,
                        message: result.settings.message !== 0,
                        kakao: result.settings.kakao !== 0,
                    });
                    setAlertSet(result.settings.active !== 0 ? 1 : 0);
                    if(result.userDatas.role === 1){
                        setAdminValues(result.admin)
                        GetOffsetDatas();
                    }
                } else {
                    const result = await response.json();
                    throw new Error(result.message);
                }
            }catch(e){
                setAlertMsg("서버와 통신중 에러가 발생했습니다.");
                setIsAlertWithTimeout(2);
            }finally {
                setIsLoading(false);
            }
        }

        checkRole();


    }, [])

    useEffect(() => {
        getSchedule();
    }, [scheduleDate]);

    return (
        <>
            <div className="row full-size" style={{
                position: "relative",
                overflowY: "hidden",
                background: "linear-gradient(to top right, #455162 0%, #e5e9ff 100%)"
            }}>
                <div className="column full-size" style={{position: "relative"}}>
                    <div className="row full-size-width" style={{
                        height: "3%",
                        position: "relative",
                        alignItems: "center",
                        borderBottom: "1px solid #ffffff50"
                    }}>
                        <FontAwesomeIcon icon={faUserCog} size="xs" style={{margin: 8}}/>
                        <p className="bold"
                           style={{
                               width: "calc(80% + 32px)",
                               margin: "16px 16px 16px 0",
                               fontSize: 13,
                               color: "white"
                           }}>
                            설정
                        </p>
                    </div>
                    <div className="column full-size"
                         style={{
                             padding: "1rem",
                             boxSizing: "border-box",
                             overflowY: "auto"
                         }}
                    >
                        {isLoading ? (
                            <>
                                <div className="column full-size align-center">
                                    <BaseLoadingInContainer/>
                                </div>
                            </>
                        ) : (

                            <>
                                <div className="column full-size" style={{
                                    padding: "1rem",
                                    boxSizing: "border-box",
                                    border: "1px solid #00000050",
                                    borderRadius: "0.5rem",
                                    boxShadow: "-2px 4px 4px rgba(255, 255, 255, 0.5)",
                                    background: "linear-gradient(to top right, #d5d9ef 0%, #4e4e4e 92%)"
                                }}>
                                    <div className="row full-size-width">
                                        <div className="row full-size-width align-center">
                                            <div className="row full-size-width" style={{alignItems: "flex-end"}}>
                                                <div className="column" style={{
                                                    position: "relative",
                                                    overflow: 'hidden',
                                                    width: 32,
                                                    height: 32,
                                                    minWidth: 32,
                                                    minHeight: 32,
                                                    borderRadius: "50%",
                                                    boxShadow: "-4px 4px 4px rgba(0, 0, 0, 0.4)",
                                                    background: "linear-gradient(to top right, #455162 15%, #e5e9ff 70%)"
                                                }}>
                                                    <img
                                                        src={profileImage}
                                                        alt={"user-image"}
                                                        style={{
                                                            position: "absolute",
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'cover',
                                                            borderRadius: '50%',
                                                            top: isDefaultImage ? 5 : 0 // 기본 이미지 여부에 따라 top 스타일 조절
                                                        }}
                                                    />
                                                </div>
                                                <div className="seperator-horz-half-left"/>
                                                <p className="bold"
                                                   style={{
                                                       whiteSpace: "nowrap",
                                                       fontSize: 28,
                                                       margin: 0,
                                                       padding: 0
                                                   }}>{userInfo.name}</p>
                                                <div className="seperator-horz-half-left"/>
                                                <div className="full-size-width">
                                                    <p style={{
                                                        fontSize: 14,
                                                        margin: 0,
                                                        padding: 0
                                                    }}>{userInfo.affiliation}</p>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon size={"2xl"}
                                                     onMouseEnter={() => setSaveHover(true)}
                                                     onMouseLeave={() => setSaveHover(false)}
                                                     onMouseDown={() => setSaveClick(true)}
                                                     onMouseUp={() => setSaveClick(false)}
                                                     onClick={handleSave}
                                                     style={{color: saveClick ? '#666666' : saveHover ? '#bbbbbb' : '#ffffff', cursor: "pointer"}}
                                                     icon={faSave}/>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="full-size-width seperator-half"
                                         style={{borderBottom: "1px solid #00000050"}}/>
                                    <div className="column full-size-width">
                                        <div className="column grid-container">
                                            <div className="column grid-form"
                                                 style={{position: "relative", margin: "0.5rem", background: "linear-gradient(to top right, #222222 0%, #364D79FF 100%)"}}>
                                                <div className="row grid-title align-center">
                                                    <FontAwesomeIcon style={{color: "white"}} icon={faBells}/>
                                                    <div className="seperator-horz-half"></div>
                                                    <p className="full-size-width bold">알람 설정</p>
                                                    <p style={{whiteSpace: "nowrap"}}>활성화</p>
                                                    <div className="seperator-horz-half"></div>
                                                    <OnOffSlider value={alertSet} handleChange={(value) => {
                                                        setValues(prevValues => ({
                                                            ...prevValues,
                                                            active: value !== 0
                                                        }));
                                                        setAlertSet(value);
                                                    }}/>
                                                </div>
                                                <div className="seperator-half"/>
                                                <div className="row" style={{padding: "0 0.5rem", overflowX: "auto"}}>
                                                    <div className="seperator-horz"/>
                                                    <div className="column full-size">
                                                        <p className="full-size-width"
                                                           style={{
                                                               whiteSpace: "nowrap",
                                                               color: "#dddddd",
                                                               fontSize: 10,
                                                               margin: 0,
                                                               padding: 0,
                                                           }}>알림 설정</p>
                                                        <div className="row input-container align-center full-size">
                                                            <input type="checkbox" name="message" id="message"
                                                                   style={{display: "none"}}
                                                                   checked={values.message} onChange={handleChange}
                                                                   className="custom-checkbox"
                                                                   disabled={!values.active}
                                                            />
                                                            <label htmlFor="message"
                                                                   className="checkbox-label">
                                                            </label>
                                                            <p className="full-size-width"
                                                               style={{
                                                                   whiteSpace: "nowrap",
                                                                   color: "white",
                                                                   fontSize: 12
                                                               }}>문자</p>

                                                            <input type="checkbox" name="kakao" id="kakao"
                                                                   style={{display: "none"}}
                                                                   checked={values.kakao} onChange={handleChange}
                                                                   className="custom-checkbox"
                                                                   disabled={!values.active}
                                                            />
                                                            <label htmlFor="kakao"
                                                                   className="checkbox-label"
                                                                   aria-disabled={!values.active}
                                                            >
                                                            </label>
                                                            <p className="full-size-width"
                                                               style={{
                                                                   whiteSpace: "nowrap",
                                                                   color: "white",
                                                                   fontSize: 12
                                                               }}>카카오톡</p>
                                                        </div>
                                                    </div>
                                                    <div className="seperator-horz"/>
                                                    <div className="column full-size-width">
                                                        <p className="full-size-width"
                                                           style={{
                                                               whiteSpace: "nowrap",
                                                               color: "#cccccc",
                                                               fontSize: 10,
                                                               margin: 0,
                                                               padding: 0,
                                                           }}>알림받을 번호</p>
                                                        <div
                                                            className="row input-container align-center full-size-width"
                                                            style={{
                                                                background: !values.active ? "#a9a9a9" : "white",
                                                                borderRadius: "4px",
                                                                border: "1px solid #ffffffb0",
                                                                margin: "0.5rem 0"
                                                            }}>
                                                            <FontAwesomeIcon
                                                                style={{color: "#cccccc", marginLeft: "1rem"}}
                                                                fontSize={12} icon={faPhone}/>
                                                            <input
                                                                style={{
                                                                    padding: "0.5rem",
                                                                    fontSize: 12,
                                                                    color: !values.active ? "white" : "black",
                                                                }}
                                                                disabled={!values.active}
                                                                autoComplete={"off"} name="phone"
                                                                onChange={handleChange}
                                                                value={values.phone} placeholder={"전화번호"}
                                                                className="full-size-width"/>
                                                        </div>
                                                    </div>
                                                    <div className="seperator-horz"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column grid-container">
                                            <div className="column grid-form"
                                                 style={{position: "relative", margin: "0.5rem", background: "linear-gradient(to top right, #222222 0%, #364D79FF 100%)"}}>
                                                <div className="row grid-title align-center">
                                                    <FontAwesomeIcon style={{color: "white"}} icon={faUserAstronaut}/>
                                                    <div className="seperator-horz-half"></div>
                                                    <p className="full-size-width bold">프로필 설정</p>
                                                </div>
                                                <div className="seperator-half"/>
                                                <div className="row" style={{padding: "0 0.5rem", overflowX: "auto"}}>
                                                    <div className="column full-size-width">
                                                        <p className="full-size-width"
                                                           style={{
                                                               whiteSpace: "nowrap",
                                                               color: "#dddddd",
                                                               fontSize: 10,
                                                               margin: 0,
                                                               padding: 0,
                                                           }}>이름</p>
                                                        <div
                                                            className="row input-container align-center full-size-width"
                                                            style={{
                                                                background: "white",
                                                                borderRadius: "4px",
                                                                border: "1px solid #ffffffb0",
                                                                margin: "0.5rem 0"
                                                            }}>
                                                            <FontAwesomeIcon
                                                                style={{color: "#cccccc", marginLeft: "1rem"}}
                                                                fontSize={12} icon={faPencil}/>
                                                            <input
                                                                style={{
                                                                    padding: "0.5rem",
                                                                    fontSize: 12,
                                                                    color: "black",
                                                                }}
                                                                autoComplete={"off"} name="name"
                                                                onChange={handleChange}
                                                                value={values.name} placeholder={"이름"}
                                                                className="full-size-width"/>
                                                        </div>
                                                    </div>
                                                    <div className="seperator-horz"/>
                                                    <div className="column full-size-width">
                                                        <p className="full-size-width"
                                                           style={{
                                                               whiteSpace: "nowrap",
                                                               color: "#cccccc",
                                                               fontSize: 10,
                                                               margin: 0,
                                                               padding: 0,
                                                           }}>소속</p>
                                                        <div
                                                            className="row input-container align-center full-size-width"
                                                            style={{
                                                                background: "white",
                                                                borderRadius: "4px",
                                                                border: "1px solid #ffffffb0",
                                                                margin: "0.5rem 0"
                                                            }}>
                                                            <FontAwesomeIcon
                                                                style={{color: "#cccccc", marginLeft: "1rem"}}
                                                                fontSize={12} icon={faBuildings}/>
                                                            <input
                                                                style={{
                                                                    padding: "0.5rem",
                                                                    fontSize: 12,
                                                                    color: "black",
                                                                }}
                                                                autoComplete={"off"} name="affiliation"
                                                                onChange={handleChange}
                                                                value={values.affiliation} placeholder={"소속"}
                                                                className="full-size-width"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="seperator-horz"/>
                                                    <div className="column full-size-width align-center">
                                                        <p className="full-size-width"
                                                           style={{
                                                               whiteSpace: "nowrap",
                                                               color: "#cccccc",
                                                               fontSize: 10,
                                                               margin: 0,
                                                               padding: 0,
                                                           }}>프로필 사진</p>
                                                        <input
                                                            type="file"
                                                            ref={fileInputRef}
                                                            style={{display: 'none'}} // 파일 입력을 숨깁니다.
                                                            accept="image/jpeg, image/png"
                                                            onChange={handleFileChange}
                                                        />
                                                        <button
                                                            onClick={triggerFileInput} // 클릭 시 숨겨진 파일 입력을 통해 파일 선택기를 엽니다.
                                                            className="full-size-width align-center bold upload-button">
                                                            업로드
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {userInfo.role === 1 && (
                                        <>
                                            <div className="column grid-container">
                                                <div className="column grid-form admin"
                                                     style={{
                                                         position: "relative",
                                                         margin: "0.5rem",
                                                         background: "linear-gradient(to top right, #222222 0%, #364D79FF 100%)"
                                                     }}>
                                                    <div className="row grid-title align-center admin">
                                                        <FontAwesomeIcon style={{color: "white"}}
                                                                         icon={faUserCog}/>
                                                        <div className="seperator-horz-half"></div>
                                                        <p className="full-size-width bold">관리자 설정</p>
                                                    </div>
                                                    <div className="seperator-half"/>
                                                    <div className="row"
                                                         style={{padding: "0 0.5rem", overflowX: "auto"}}>
                                                        <div className="column full-size">
                                                            <p className="full-size-width"
                                                               style={{
                                                                   whiteSpace: "nowrap",
                                                                   color: "#dddddd",
                                                                   fontSize: 10,
                                                                   margin: 0,
                                                                   padding: 0,
                                                               }}>노출 설정</p>
                                                            <div className="row input-container align-center full-size">
                                                                <input type="checkbox" name="CCTV" id="CCTV"
                                                                       style={{display: "none"}}
                                                                       checked={adminValues.CCTV}
                                                                       onChange={handleChangeAdmin}
                                                                       className="custom-checkbox"
                                                                />
                                                                <label htmlFor="CCTV"
                                                                       className="checkbox-label"
                                                                >
                                                                </label>
                                                                <p className="full-size-width"
                                                                   style={{
                                                                       whiteSpace: "nowrap",
                                                                       color: "white",
                                                                       fontSize: 12
                                                                   }}>CCTV보기</p>

                                                                <input type="checkbox" name="dataChart" id="dataChart"
                                                                       style={{display: "none"}}
                                                                       checked={adminValues.dataChart}
                                                                       onChange={handleChangeAdmin}
                                                                       className="custom-checkbox"
                                                                />
                                                                <label htmlFor="dataChart"
                                                                       className="checkbox-label"
                                                                >
                                                                </label>
                                                                <p className="full-size-width"
                                                                   style={{
                                                                       whiteSpace: "nowrap",
                                                                       color: "white",
                                                                       fontSize: 12
                                                                   }}>종합 데이터 보기</p>
                                                                <input type="checkbox" name="sensorData" id="sensorData"
                                                                       style={{display: "none"}}
                                                                       checked={adminValues.sensorData}
                                                                       onChange={handleChangeAdmin}
                                                                       className="custom-checkbox"
                                                                />
                                                                <label htmlFor="sensorData"
                                                                       className="checkbox-label"
                                                                >
                                                                </label>
                                                                <p className="full-size-width"
                                                                   style={{
                                                                       whiteSpace: "nowrap",
                                                                       color: "white",
                                                                       fontSize: 12
                                                                   }}>수위 데이터 보기</p>
                                                                <input type="checkbox" name="objectData" id="objectData"
                                                                       style={{display: "none"}}
                                                                       checked={adminValues.objectData}
                                                                       onChange={handleChangeAdmin}
                                                                       className="custom-checkbox"
                                                                />
                                                                <label htmlFor="objectData"
                                                                       className="checkbox-label"
                                                                >
                                                                </label>
                                                                <p className="full-size-width"
                                                                   style={{
                                                                       whiteSpace: "nowrap",
                                                                       color: "white",
                                                                       fontSize: 12
                                                                   }}>사물 인식 데이터 보기</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="seperator-half"/>
                                                    <p className="full-size-width"
                                                       style={{
                                                           whiteSpace: "nowrap",
                                                           color: "#cccccc",
                                                           fontSize: 12,
                                                           margin: "0 0 0 1rem",
                                                           padding: 0,
                                                       }}>유저 추가</p>
                                                    <div className="row"
                                                         style={{
                                                             border: "1px solid #ffffff80",
                                                             borderRadius: "0.5rem",
                                                             margin: "0.5rem",
                                                             padding: "0.5rem",
                                                             overflowX: "auto"
                                                         }}>
                                                        <div className="column full-size-width">
                                                            <div className="column">
                                                                <p className="full-size-width"
                                                                   style={{
                                                                       whiteSpace: "nowrap",
                                                                       color: "#cccccc",
                                                                       fontSize: 10,
                                                                       margin: 0,
                                                                       padding: 0,
                                                                   }}>이름</p>
                                                                <div
                                                                    className="row input-container align-center full-size-width"
                                                                    style={{
                                                                        background: "white",
                                                                        borderRadius: "4px",
                                                                        border: "1px solid #ffffffb0",
                                                                        margin: "0.5rem 0"
                                                                    }}>
                                                                    <FontAwesomeIcon
                                                                        style={{color: "#cccccc", marginLeft: "1rem"}}
                                                                        fontSize={12} icon={faPencil}/>
                                                                    <input
                                                                        style={{
                                                                            padding: "0.5rem",
                                                                            fontSize: 12,
                                                                            color: "black",
                                                                        }}
                                                                        autoComplete={"off"} name="user_name"
                                                                        onChange={handleChangeUser}
                                                                        value={userValue.user_name} placeholder={"이름"}
                                                                        className="full-size-width"
                                                                    />
                                                                </div>
                                                                <p className="full-size-width"
                                                                   style={{
                                                                       whiteSpace: "nowrap",
                                                                       color: "#cccccc",
                                                                       fontSize: 10,
                                                                       margin: 0,
                                                                       padding: 0,
                                                                   }}>아이디</p>
                                                                <div
                                                                    className="row input-container align-center full-size-width"
                                                                    style={{
                                                                        background: "white",
                                                                        borderRadius: "4px",
                                                                        border: "1px solid #ffffffb0",
                                                                        margin: "0.5rem 0"
                                                                    }}>
                                                                    <FontAwesomeIcon
                                                                        style={{color: "#cccccc", marginLeft: "1rem"}}
                                                                        fontSize={12} icon={faIdCard}/>
                                                                    <input
                                                                        style={{
                                                                            padding: "0.5rem",
                                                                            fontSize: 12,
                                                                            color: "black",
                                                                        }}
                                                                        autoComplete={"off"} name="user_id"
                                                                        onChange={handleChangeUser}
                                                                        value={userValue.user_id} placeholder={"아이디"}
                                                                        className="full-size-width"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="seperator-horz"/>
                                                        <div className="column full-size-width">
                                                            <div className="column">
                                                                <p className="full-size-width"
                                                                   style={{
                                                                       whiteSpace: "nowrap",
                                                                       color: "#cccccc",
                                                                       fontSize: 10,
                                                                       margin: 0,
                                                                       padding: 0,
                                                                   }}>소속</p>
                                                                <div
                                                                    className="row input-container align-center full-size-width"
                                                                    style={{
                                                                        background: "white",
                                                                        borderRadius: "4px",
                                                                        border: "1px solid #ffffffb0",
                                                                        margin: "0.5rem 0"
                                                                    }}>
                                                                    <FontAwesomeIcon
                                                                        style={{color: "#cccccc", marginLeft: "1rem"}}
                                                                        fontSize={12} icon={faBuildings}/>
                                                                    <input
                                                                        style={{
                                                                            padding: "0.5rem",
                                                                            fontSize: 12,
                                                                            color: "black",
                                                                        }}
                                                                        autoComplete={"off"} name="user_affiliation"
                                                                        onChange={handleChangeUser}
                                                                        value={userValue.user_affiliation}
                                                                        placeholder={"소속"}
                                                                        className="full-size-width"
                                                                    />
                                                                </div>
                                                                <p className="full-size-width"
                                                                   style={{
                                                                       whiteSpace: "nowrap",
                                                                       color: "#cccccc",
                                                                       fontSize: 10,
                                                                       margin: 0,
                                                                       padding: 0,
                                                                   }}>비밀번호</p>
                                                                <div
                                                                    className="row input-container align-center full-size-width"
                                                                    style={{
                                                                        background: "white",
                                                                        borderRadius: "4px",
                                                                        border: "1px solid #ffffffb0",
                                                                        margin: "0.5rem 0"
                                                                    }}>
                                                                    <FontAwesomeIcon
                                                                        style={{color: "#cccccc", marginLeft: "1rem"}}
                                                                        fontSize={12} icon={faEye}/>
                                                                    <input
                                                                        style={{
                                                                            padding: "0.5rem",
                                                                            fontSize: 12,
                                                                            color: "black",
                                                                        }}
                                                                        autoComplete={"off"} name="user_pwd"
                                                                        onChange={handleChangeUser}
                                                                        value={userValue.user_pwd}
                                                                        type={PWDType} placeholder={"패스워드"}
                                                                        className="full-size-width"/>
                                                                    <FontAwesomeIcon
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "#333333",
                                                                            marginRight: "1rem"
                                                                        }}
                                                                        fontSize={12}
                                                                        icon={PWDType === 'password' ? faEye : faEyeSlash}
                                                                        onClick={() => {
                                                                            if (PWDType === 'password') {
                                                                                setPWDType("text")
                                                                            } else {
                                                                                setPWDType("password")
                                                                            }
                                                                        }}/>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="seperator-horz"/>
                                                        <div className="column align-center">
                                                            <p className="full-size-width"
                                                               style={{
                                                                   whiteSpace: "nowrap",
                                                                   color: "#cccccc",
                                                                   fontSize: 10,
                                                                   margin: 0,
                                                                   padding: 0,
                                                               }}>{"　"}</p>
                                                            <button
                                                                onClick={handleAddUser}
                                                                className="full-size align-center bold upload-button"
                                                                style={{whiteSpace: "nowrap"}}
                                                            >
                                                                추가하기
                                                            </button>
                                                        </div>
                                                        <div className="seperator-horz"/>
                                                        <div className="column align-center">
                                                            <p className="full-size-width"
                                                               style={{
                                                                   whiteSpace: "nowrap",
                                                                   color: "#cccccc",
                                                                   fontSize: 10,
                                                                   margin: 0,
                                                                   padding: 0,
                                                               }}>{"　"}</p>
                                                            <button
                                                                onClick={handleShowUsers}
                                                                className="full-size align-center bold upload-button"
                                                                style={{whiteSpace: "nowrap"}}
                                                            >
                                                                목록보기
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <p className="full-size-width"
                                                       style={{
                                                           whiteSpace: "nowrap",
                                                           color: "#cccccc",
                                                           fontSize: 12,
                                                           margin: "0 0 0 1rem",
                                                           padding: 0,
                                                       }}>유저 삭제</p>
                                                    <div className="row"
                                                         style={{
                                                             border: "1px solid #ffffff80",
                                                             borderRadius: "0.5rem",
                                                             margin: "0.5rem",
                                                             padding: "0.5rem",
                                                             overflowX: "auto"
                                                         }}>
                                                        <div className="column full-size-width">
                                                            <div className="column">
                                                                <p className="full-size-width"
                                                                   style={{
                                                                       whiteSpace: "nowrap",
                                                                       color: "#cccccc",
                                                                       fontSize: 10,
                                                                       margin: 0,
                                                                       padding: 0,
                                                                   }}>아이디</p>
                                                                <div
                                                                    className="row input-container align-center full-size-width"
                                                                    style={{
                                                                        background: "white",
                                                                        borderRadius: "4px",
                                                                        border: "1px solid #ffffffb0",
                                                                        margin: "0.5rem 0"
                                                                    }}>
                                                                    <FontAwesomeIcon
                                                                        style={{color: "#cccccc", marginLeft: "1rem"}}
                                                                        fontSize={12} icon={faIdCard}/>
                                                                    <input
                                                                        style={{
                                                                            padding: "0.5rem",
                                                                            fontSize: 12,
                                                                            color: "black",
                                                                        }}
                                                                        autoComplete={"off"} name="id"
                                                                        onChange={handleChangeUserModifyValue}
                                                                        value={userModifyValues.id} placeholder={"아이디"}
                                                                        className="full-size-width"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="seperator-horz"/>
                                                        <div className="column full-size-width">
                                                            <div className="column">
                                                                <p className="full-size-width"
                                                                   style={{
                                                                       whiteSpace: "nowrap",
                                                                       color: "#cccccc",
                                                                       fontSize: 10,
                                                                       margin: 0,
                                                                       padding: 0,
                                                                   }}>비밀번호</p>
                                                                <div
                                                                    className="row input-container align-center full-size-width"
                                                                    style={{
                                                                        background: "white",
                                                                        borderRadius: "4px",
                                                                        border: "1px solid #ffffffb0",
                                                                        margin: "0.5rem 0"
                                                                    }}>
                                                                    <FontAwesomeIcon
                                                                        style={{color: "#cccccc", marginLeft: "1rem"}}
                                                                        fontSize={12} icon={faEye}/>
                                                                    <input
                                                                        style={{
                                                                            padding: "0.5rem",
                                                                            fontSize: 12,
                                                                            color: "black",
                                                                        }}
                                                                        autoComplete={"off"} name="pwd"
                                                                        onChange={handleChangeUserModifyValue}
                                                                        value={userModifyValues.pwd}
                                                                        type={PWDType} placeholder={"패스워드"}
                                                                        className="full-size-width"/>
                                                                    <FontAwesomeIcon
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "#333333",
                                                                            marginRight: "1rem"
                                                                        }}
                                                                        fontSize={12}
                                                                        icon={PWDType === 'password' ? faEye : faEyeSlash}
                                                                        onClick={() => {
                                                                            if (PWDType === 'password') {
                                                                                setPWDType("text")
                                                                            } else {
                                                                                setPWDType("password")
                                                                            }
                                                                        }}/>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="seperator-horz"/>
                                                        <div className="column full-size-width align-center">
                                                            <p className="full-size-width"
                                                               style={{
                                                                   whiteSpace: "nowrap",
                                                                   color: "#cccccc",
                                                                   fontSize: 10,
                                                                   margin: 0,
                                                                   padding: 0,
                                                               }}>{"　"}</p>
                                                            <button
                                                                onClick={handleDeleteUser}
                                                                className="full-size align-center bold upload-button">
                                                                삭제하기
                                                            </button>
                                                        </div>
                                                        <div className="seperator-horz"/>
                                                        <div className="column full-size-width align-center">
                                                            <p className="full-size-width"
                                                               style={{
                                                                   whiteSpace: "nowrap",
                                                                   color: "#cccccc",
                                                                   fontSize: 10,
                                                                   margin: 0,
                                                                   padding: 0,
                                                               }}>{"　"}</p>
                                                            <button
                                                                onClick={handleModifyUser}
                                                                className="full-size align-center bold upload-button">
                                                                변경하기
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <p className="full-size-width"
                                                       style={{
                                                           whiteSpace: "nowrap",
                                                           color: "#cccccc",
                                                           fontSize: 12,
                                                           margin: "0 0 0 1rem",
                                                           padding: 0,
                                                       }}>오프셋 변경</p>
                                                    <div className="row"
                                                         style={{
                                                             border: "1px solid #ffffff80",
                                                             borderRadius: "0.5rem",
                                                             margin: "0.5rem",
                                                             padding: "0.5rem",
                                                             overflowX: "auto"
                                                         }}>
                                                        <div className="column full-size-width" style={{
                                                            height: autoCompleteFocus ? 220 : "auto",
                                                            transition: "all 0.3s ease-in-out"
                                                        }}>
                                                            <div className="column">
                                                                <p className="full-size-width"
                                                                   style={{
                                                                       whiteSpace: "nowrap",
                                                                       color: "#cccccc",
                                                                       fontSize: 10,
                                                                       margin: 0,
                                                                       padding: 0,
                                                                   }}>위치</p>
                                                                <BaseAutocompleteInput2
                                                                    data={datas}
                                                                    displayFunc={data => {
                                                                        return `${data.name} : ${data.offset}`;
                                                                    }}
                                                                    suggestionFunc={data => {
                                                                        return `${data.name}\t${data.offset}`;
                                                                    }}
                                                                    suggestionClick={(data) => {
                                                                        if (data) {
                                                                            setSelectLocation(data);
                                                                        } else {
                                                                            setSelectLocation(initLocation);
                                                                        }
                                                                    }}
                                                                    name="location"
                                                                    value={selectLocation.name}
                                                                    onChange={() => {
                                                                    }}
                                                                    onFocus={() => {
                                                                        setAutoCompleteFocus(true);
                                                                    }}
                                                                    onBlur={() => {
                                                                        setAutoCompleteFocus(false);
                                                                    }}
                                                                    placeholder="지역명으로 검색해보세요."
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="seperator-horz"/>
                                                        <div className="column full-size-width" style={{
                                                            height: autoCompleteFocus ? 220 : "auto",
                                                            transition: "all 0.3s ease-in-out"
                                                        }}>
                                                            <div className="column align-center">
                                                                <p className="full-size-width"
                                                                   style={{
                                                                       whiteSpace: "nowrap",
                                                                       color: "#cccccc",
                                                                       fontSize: 10,
                                                                       margin: 0,
                                                                       padding: 0,
                                                                   }}>값</p>
                                                                <div
                                                                    className="row input-container align-center full-size-width"
                                                                    style={{
                                                                        background: "white",
                                                                        borderRadius: "4px",
                                                                        border: "1px solid #ffffffb0",
                                                                        margin: "0.5rem 0"
                                                                    }}>
                                                                    <FontAwesomeIcon
                                                                        style={{color: "#cccccc", marginLeft: "1rem"}}
                                                                        fontSize={12} icon={faLineHeight}/>
                                                                    <input
                                                                        style={{
                                                                            padding: "0.5rem",
                                                                            fontSize: 12,
                                                                            color: "black",
                                                                        }}
                                                                        autoComplete={"off"}
                                                                        onChange={handleChangeOffset}
                                                                        value={offsetValue}
                                                                        className="full-size"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="seperator-horz"/>
                                                        <div className="column full-size-width align-center">
                                                            <p className="full-size-width"
                                                               style={{
                                                                   whiteSpace: "nowrap",
                                                                   color: "#cccccc",
                                                                   fontSize: 10,
                                                                   margin: 0,
                                                                   padding: 0,
                                                               }}>{"　"}</p>
                                                            <button
                                                                onClick={handleSaveOffset}
                                                                className="full-size align-center bold upload-button">
                                                                등록 및 변경
                                                            </button>
                                                        </div>
                                                        <div className="seperator-horz"/>
                                                        <div className="column full-size-width align-center">
                                                            <p className="full-size-width"
                                                               style={{
                                                                   whiteSpace: "nowrap",
                                                                   color: "#cccccc",
                                                                   fontSize: 10,
                                                                   margin: 0,
                                                                   padding: 0,
                                                               }}>{"　"}</p>
                                                            <button
                                                                onClick={handleInitOffset}
                                                                className="full-size align-center bold upload-button">
                                                                초기화
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </div>

                </div>
                <div className="column full-size" style={{position: "relative", overflow: "hidden"}}>
                    <div className="row full-size-width" style={{
                        height: "3%",
                        position: "relative",
                        alignItems: "center",
                        borderBottom: "1px solid #ffffff50"
                    }}>
                        <FontAwesomeIcon icon={faCalendarCheck} size="xs" style={{margin: 8}}/>
                        <p className="bold"
                           style={{
                               width: "calc(80% + 32px)",
                               margin: "16px 16px 16px 0",
                               fontSize: 13,
                               color: "white"
                           }}>
                            일정
                        </p>
                    </div>
                    <div className="column full-size align-center"
                         style={{position: "relative", padding: "0.5rem", boxSizing: "border-box"}}>
                        <ScheduleCalendar
                            changeDate={async (year, month) => {
                                setScheduleDate(`${year}-${month.toString().padStart(2, '0')}-00`)
                            }}
                            role={userInfo.role}
                            events={scheduleData}
                            getSchedule={getSchedule}
                        />
                    </div>
                </div>
                {isModal && (
                    <>
                        <div className="column full-size" style={{position: "absolute", top: 0, left: 0, background: "#00000050", zIndex: 999}}>
                            <UserListModal handleClose={() => setIsModal(false)} userList={userList}/>
                        </div>
                    </>
                )}
            </div>
            {isAlert === 0 && (
                <>
                    <BaseAlert msg={alertMsg}></BaseAlert>
                </>
            )}
            {isAlert === 1 && (
                <>
                    <BaseAlertWarn msg={alertMsg}></BaseAlertWarn>
                </>
            )}
            {isAlert === 2 && (
                <>
                <BaseAlertDanger msg={alertMsg}></BaseAlertDanger>
                </>
            )}
        </>
    );
}

export default Setting;
