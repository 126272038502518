import React, {useContext, useEffect, useRef, useState} from "react";
import '../styles/Main.css';
import {
    faBell,
    faChevronDown, faChevronUp, faCircle, faCircleDot, faClock, faClockFour,
    faCloud, faCloudMeatball,
    faCloudRain,
    faCloudShowersHeavy,
    faCloudSun, faCog, faCogs, faDatabase, faDotCircle, faHome, faListDots, faSignIn,
    faSnowflake, faStar, faStarAndCrescent,
    faSun, faTruckLoading, faUser, faUserClock, faUserGear, faUserPlus, faWater
} from "@fortawesome/free-solid-svg-icons";

import { faClockRotateLeft, faSignInAlt } from "@fortawesome/pro-regular-svg-icons"
import {BaseLoadingInContainer} from "../BaseComponents/BaseLoading";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Cookies from 'js-cookie';
import CCTV from "../forms/cctv";
import Home from "../forms/home";
import Datachart from "../forms/datachart";
import DataChart from "../forms/datachart";
import {useNavigate} from "react-router-dom";
import {AppContext, backendServer} from "../App";
import logo from "../images/logo_noneback.png";
import {BaseImageCircle} from "../BaseComponents/BaseImage";
import userImage from "../images/free-icon-user-5264565.png";
import locationsData from '../modules/locations.json';
import SensorData from "../forms/sensordata";
import Setting from "../forms/setting";
import AlertSetting from "../forms/alert_setting";
import Objectdata from "../forms/objectdata";
import ObjectData from "../forms/objectdata";

export const GlobalContext = React.createContext();

function Main() {
    const { profileImage, setProfileImage, isDefaultImage, setIsDefaultImage } = useContext(AppContext);

    const navigate = useNavigate();

    const [ isLogged , setIsLogged ] = useState(false);
    const userInfo = useRef(null);

    const [ weatherIcon, setWeatherIcon ] = useState(faTruckLoading);
    const [ temp, setTemp ] = useState(0);
    const [ pop, setPop ] = useState(0);
    const [ weatherText, setWeatherText ] = useState("");

    const [showInfo, setShowInfo] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [showFavorite, setShowFavorite] = useState(false);
    const [activeForm, setActiveForm] = useState({form: "통합관제", param: {}});
    const [ isWeatherLoading, setIsWeatherLoading ] = useState(false);

    const locationRef = useRef({ nx: 59, ny: 76 });
    const loginCheckRef = useRef(null);
    const loggedCheckRef = useRef(false);

    const [favorites, setFavorites] = useState([]);
    const [focusLocation, setFocusLocation] = useState("001");

    const [ weatherData, setWeatherData ] = useState({});
    const [ tabs, setTabs ] = useState({
        CCTV: false,
        dataChart: false,
        sensorData: false,
    });

    const [ settings, setSettings ] = useState({
        CCTV: false,
        dataChart: false,
        sensorData: false,
        objectData: false,
    })

    // 즐겨찾기 상태 변경 핸들러
    const toggleFavorite = (item) => {
        let updatedFavorites = favorites.includes(item)
            ? favorites.filter(fav => fav !== item)
            : [...favorites, item];
        setFavorites(updatedFavorites);
        Cookies.set('favorites', JSON.stringify(updatedFavorites));
    };

    const getWeather = () => {
        setIsWeatherLoading(true);
        const { nx, ny } = locationRef.current;

        const now = new Date(Date.UTC(
            new Date().getUTCFullYear(),
            new Date().getUTCMonth(),
            new Date().getUTCDate(),
            new Date().getUTCHours(),
            new Date().getUTCMinutes(),
            new Date().getUTCSeconds()
        ));

        // UTC 시간에 9시간을 더해 KST 시간으로 변환
        const kstOffset = 9 * 60 * 60 * 1000; // 9시간을 밀리초로 변환
        const kstDate = new Date(now.getTime() + kstOffset);

        const currentHour = kstDate.getHours();
        const currentMinute = kstDate.getMinutes();

        const currentTime = parseInt(String(currentHour).padStart(2, '0') + String(currentMinute).padStart(2, '0'))

        let base_date;
        let base_date_yesterday;
        const base_time = `0200`;

        if(currentTime < 211){
            base_date = `${kstDate.getFullYear()}${String(kstDate.getUTCMonth() + 1).padStart(2, '0')}${String(kstDate.getUTCDate() - 1).padStart(2, '0')}`;
            base_date_yesterday = `${kstDate.getUTCFullYear()}${String(kstDate.getUTCMonth() + 1).padStart(2, '0')}${String(kstDate.getUTCDate() - 2).padStart(2, '0')}`;
        }else{
            base_date = `${kstDate.getFullYear()}${String(kstDate.getUTCMonth() + 1).padStart(2, '0')}${String(kstDate.getUTCDate()).padStart(2, '0')}`;
            base_date_yesterday = `${kstDate.getUTCFullYear()}${String(kstDate.getUTCMonth() + 1).padStart(2, '0')}${String(kstDate.getUTCDate() - 1).padStart(2, '0')}`;

        }

        // API 호출
        fetch(`https://${backendServer}/getWeather?base_date=${base_date}&base_time=${base_time}&base_date_yesterday=${base_date_yesterday}&nx=${nx}&ny=${ny}`)
            .then(response => response.json())
            .then(data => {
                setWeatherData(data);
                const todayItems = data.today.filter(item => item.fcstTime === (String(currentHour).padStart(2, '0') + '00'));

                todayItems.forEach(item => {
                    switch (item.category) {
                        case 'TMP':
                            setTemp(item.fcstValue);
                            break;
                        case 'SKY':
                            if(item.fcstValue === "1"){ // 맑은 날씨 아이콘
                                setWeatherIcon(faSun);
                                setWeatherText("맑음");
                            } else if(item.fcstValue === "3"){ // 구름 많음 아이콘
                                setWeatherIcon(faCloudSun);
                                setWeatherText("구름");
                            } else if(item.fcstValue === "4"){ // 흐림 아이콘
                                setWeatherIcon(faCloud);
                                setWeatherText("흐림");
                            }
                            break;
                        case 'PTY':
                            if(item.fcstValue === "1"){ // 비
                                setWeatherIcon(faCloudShowersHeavy);
                                setWeatherText("비");
                            } else if(item.fcstValue === "2"){ // 비와 눈
                                setWeatherIcon(faCloudMeatball);
                                setWeatherText("비와 눈");
                            } else if(item.fcstValue === "3"){ // 눈
                                setWeatherIcon(faSnowflake);
                                setWeatherText("눈");
                            } else if(item.fcstValue === "4"){ // 소나기
                                setWeatherIcon(faCloudRain);
                                setWeatherText("소나기");
                            }
                            break;
                        case 'POP':
                            setPop(item.fcstValue);
                            break;
                    }
                    setIsWeatherLoading(false);
                });
            })
            .catch(error => {
                console.error("Error fetching weather");
            }).finally(() => {
                setIsWeatherLoading(false);
        });
    }

    const [kakaoLoaded, setKakaoLoaded] = useState(false);
    useEffect(() => {
        const loadScript = (src) => {
            // 스크립트가 이미 로드되었는지 확인
            if (document.querySelector(`script[src="${src}"]`)) {
                return;
            }

            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = src;

            script.onload = () => {
                if (window.kakao && window.kakao.maps) {
                    window.kakao.maps.load(() => {
                        setKakaoLoaded(true)
                    });
                } else {
                    console.error('Failed to initialize Kakao maps');
                }
            };
            script.onerror = (e) => {
                console.error(`Failed to load script ${e}`);
            };

            document.head.appendChild(script);
        };

        loadScript('//dapi.kakao.com/v2/maps/sdk.js?appkey=23192a6a0925f090c4dbd858a69c7f77&autoload=false');
    }, []);

    const handleSelectorChange = (event) => {
        const value = event.target.value;
        setFocusLocation(value);

        const selectedLocation = locationsData.locations.find(location => location.code === value);

        if (selectedLocation) {
            // 찾은 위치에 해당하는 nx, ny 값을 locationRef.current에 저장
            locationRef.current = { nx: selectedLocation.nx, ny: selectedLocation.ny };
        } else {
            console.error('선택된 위치를 찾을 수 없습니다.');
        }

        getWeather();
    }

    const handleActiveForm = (name, param = {}) => {
        if(activeForm.form === name){
            setActiveForm({form: '통합관제', param: param});
        }else{
            setActiveForm({form: name, param: param});
        }
    }

    useEffect(() => {
        const checkLoginStatus = async () => {
            try {
                const response = await fetch(`https://${backendServer}/check-login`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include',
                    body: JSON.stringify({flag: true}),
                });

                if (response.ok) {
                    const data = await response.json();

                    if (!data.loggedIn) {
                        setSettings(data.setting);
                        if (loggedCheckRef.current) {
                            alert("로그아웃 되었습니다.");
                            window.location.reload();
                        }
                        return;
                    }

                    setIsLogged(data.loggedIn);
                    loggedCheckRef.current = data.loggedIn;
                    // 로그인 상태에 따른 추가 처리...
                    if (data.message) {
                        alert(data.message);
                        if (data.message === "User is in a dormant state." || data.message === "User has been deleted.") {
                            window.location.reload();
                        }
                    } else if (data.userData) {
                        userInfo.current = data.userData;
                        const pngUrl = `https://${backendServer}/profiles/profile_image_${data.userData.user_id}.png`
                        const jpegUrl = `https://${backendServer}/profiles/profile_image_${data.userData.user_id}.jpg`
                        const defaultImageUrl = process.env.PUBLIC_URL + '/images/free-icon-user-5264565.png';

                        const checkImage = (url) =>
                            new Promise((resolve) => {
                                const img = new Image();
                                img.onload = () => resolve(true);
                                img.onerror = () => resolve(false);
                                img.src = url;
                            });

                        const loadImage = async () => {
                            if (await checkImage(pngUrl)) {
                                setProfileImage(pngUrl);
                                setIsDefaultImage(false); // 기본 이미지가 아님
                            } else if (await checkImage(jpegUrl)) {
                                setProfileImage(jpegUrl);
                                setIsDefaultImage(false); // 기본 이미지가 아님
                            } else {
                                setProfileImage(defaultImageUrl);
                                setIsDefaultImage(true); // 기본 이미지임
                            }
                        };

                        await loadImage();
                        if(!loginCheckRef.current){
                            loginCheckRef.current = setInterval(checkLoginStatus, 10000);
                        }
                    } else {
                        alert("올바르지 않은 접근 입니다.");
                        window.location.reload();
                    }
                } else {
                    throw new Error('서버 응답 실패');
                }
            } catch (error) {
                alert("로그인 상태 확인 중 에러가 발생했습니다.");
                window.location.reload();
            }
        };

        // 최초 로그인 상태 확인
        checkLoginStatus();
        getWeather();

        const savedFavorites = Cookies.get('favorites');

        if (savedFavorites) {
            setFavorites(JSON.parse(savedFavorites));
        }

        // 컴포넌트 언마운트 시 인터벌 정리
        return () => {
            if (loginCheckRef.current) {
                clearInterval(loginCheckRef.current);
                loginCheckRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        getWeather();
    }, [focusLocation])

    const handleLogout = async () => {
        const confirmLogout = window.confirm('로그아웃 하시겠습니까?');

        if (confirmLogout) {
            fetch(`https://${backendServer}/logout`, {
                method: 'POST',
                credentials: 'include'
            }).then(() => {
                userInfo.current = null;
                window.location.reload();
            });
        } else {
            return;
        }
    }

    return (
        <div className="column full-size" style={{alignItems: "center"}}>
            <div className="row full-size-width" style={{height: "5%", position: "relative", justifyContent: "center", borderBottom: "1px solid #ffffff50"}}>
                <div className="row main-body full-size align-center" style={{padding: "0 1rem", boxSizing: "border-box", justifyContent: "center"}}>
                    <div className="full-size-width">
                        <p className="text-align-left bold" style={{fontSize: 18, color: "white"}}>지하차도 재난예보 모니터링</p>
                    </div>
                    { isLogged ? (
                        <>
                            <div className="row">
                                <FontAwesomeIcon style={{cursor: "pointer", color: "white"}} fontSize={20} icon={faBell} />
                                <div className="seperator-horz-half"/>
                                <div className="user-info-element" style={{position: "relative"}}>
                                    <FontAwesomeIcon
                                        style={{cursor: "pointer", color: "white"}}
                                        fontSize={20}
                                        icon={faUserPlus}
                                    />
                                    <div className="column user-info align-center profiles-container">
                                        <div className="column full-size-width align-center">
                                            <p className="full-size-width text-align-center bold" style={{margin: "0 0 0.5rem 0", fontSize: 12}}>
                                                유저 정보
                                            </p>
                                            <div className="column" style={{
                                                position: "relative",
                                                overflow: 'hidden',
                                                width: 64,
                                                height: 64,
                                                borderRadius: "50%",
                                                boxShadow: "-4px 4px 4px rgba(0, 0, 0, 0.4)",
                                                background: "linear-gradient(to top right, #455162 15%, #e5e9ff 70%)"
                                            }}>
                                                <img
                                                    src={profileImage}
                                                    alt={"user-image"}
                                                    style={{
                                                        position: "absolute",
                                                        width: '100%', // 이미지가 부모 컨테이너에 맞게 조절
                                                        height: '100%', // 이미지가 부모 컨테이너에 맞게 조절
                                                        objectFit: 'cover', // 이미지 비율 유지하며 컨테이너에 맞춤
                                                        borderRadius: '50%', // 이미지에도 원형 테두리 적용
                                                        top: isDefaultImage ? 10 : 0
                                                    }}
                                                />
                                            </div>
                                            <div className="seperator-half-top"/>
                                            <p className="full-size-width text-align-center bold" style={{margin: 0, fontSize: 14}}>
                                                {userInfo.current.name}
                                            </p>
                                            <p className="full-size-width text-align-center" style={{margin: "0.2rem", fontSize: 8}}>
                                                {userInfo.current.affiliation}
                                            </p>
                                        </div>

                                        <div className="column full-size-width profiles" style={{marginTop: "0.5rem", marginBottom: "0.5rem"}}>
                                            <div className="row align-center" style={{border: "1px solid #00000050", borderRadius: "2rem", padding: "0.5rem 1rem", boxShadow: "0px 4px 2px rgba(0, 0, 0, 0.3)",}}>
                                                <div className="column full-size-width">
                                                    <p className="full-size-width text-align-left bold" style={{margin: 0, fontSize: 10}}>
                                                        마지막 접속 시간
                                                    </p>
                                                    <p className="full-size-width text-align-left" style={{margin: 0, fontSize: 8}}>
                                                        {userInfo.current.last_login.split("T")[0]}
                                                    </p>
                                                </div>
                                                <FontAwesomeIcon size="xs" icon={faClockRotateLeft} />
                                            </div>
                                            <div className="seperator-half-top" />
                                            <div className="row align-center" style={{border: "1px solid #00000050", borderRadius: "2rem", padding: "0.5rem 1rem", boxShadow: "0px 4px 2px rgba(0, 0, 0, 0.3)",}}>
                                                <div className="column full-size-width">
                                                    <p className="full-size-width text-align-left bold" style={{margin: 0, fontSize: 10}}>
                                                        마지막 접속 아이피
                                                    </p>
                                                    <p className="full-size-width text-align-left" style={{margin: 0, fontSize: 8}}>
                                                        {userInfo.current.last_login_ip}
                                                    </p>
                                                </div>
                                                <FontAwesomeIcon size="xs" icon={faSignInAlt} />
                                            </div>
                                            <div className="seperator-half" />
                                            <button onClick={handleLogout} className="row align-center bold logout-button">
                                                로그아웃
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </>
                    ) : (
                        <>
                            <div className="row">
                                <FontAwesomeIcon style={{cursor: "pointer", color: "white"}} onClick={() => navigate("/signin")} fontSize={20}
                                                 icon={faSignIn} />
                            </div>
                        </>
                    )}

                </div>
            </div>
            <div className="column main-body">
                <div className="row full-size" style={{position: "relative"}}>
                    <div name="sidebar" className="column" style={{width: "12%", height: "100%", position: "relative", color: "white",boxSizing: "border-box", background: "#7c7c7c", paddingBottom: "1rem"}}>
                        <div className="column full-size align-center" style={{ margin: 0, boxSizing: "border-box"}}>
                            <div name="weatherGrid" className="column full-size-width align-center" style={{ width: "100%", padding: "0.5rem 0", boxSizing: "border-box", background: "#565555"}}>
                                <div className="column full-size align-center" style={{padding: "0.5rem 0"}}>
                                    <select style={{
                                        margin: 4,
                                        color: '#ffffff',
                                        fontSize: '16px',
                                        background: 'none',
                                        border: 'none',
                                        outline: 'none',
                                    }}
                                            value={focusLocation}
                                            onChange={handleSelectorChange}
                                    >
                                        {locationsData.locations.map((location) => (
                                            <option
                                                key={location.code}
                                                value={location.code}
                                                style={{color: 'black'}}
                                            >
                                                {location.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div style={{position: "relative"}}>
                                    {!isWeatherLoading ? (
                                        <>
                                            <div>
                                                <div className="column full-size align-center" style={{padding: "0.5rem 0"}}>
                                                    <FontAwesomeIcon fontSize={58} icon={weatherIcon} />
                                                    <p style={{margin: 4}}>{weatherText}</p>
                                                </div>
                                                <div className="column full-size-width align-center" style={{ padding: "0.5rem 0"}}>
                                                    <p style={{margin: 4, fontSize: 18}}>{temp}˚C</p>
                                                    <p style={{margin: 4, fontSize: 12}}>강수확률 {pop}%</p>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <BaseLoadingInContainer height={164}/>
                                        </>
                                    )}
                                </div>

                            </div>
                            <div name="buttons" className="column full-size" >
                                <div className="row menu-item align-center" onClick={()=>handleActiveForm("통합관제")} style={{padding: "0.5rem 1rem"}}>
                                    <div className="row" style={{flex: 1}} >
                                        <FontAwesomeIcon style={{margin: 4, width: 28}} size='lg' icon={faHome} />
                                        <p style={{margin: 4, fontSize: 18}}>통합관제</p>
                                    </div>
                                </div>
                                <div className="row menu-item align-center" onClick={() => setShowFavorite(!showFavorite)} style={{padding: "0.5rem 1rem"}}>
                                    <div className="row" style={{flex: 1}}>
                                        <FontAwesomeIcon style={{margin: 4, width: 28}} size='lg' icon={faStar} />
                                        <p style={{margin: 4, fontSize: 18}}>즐겨찾기</p>
                                    </div>
                                    <FontAwesomeIcon icon={showFavorite ? faChevronUp : faChevronDown} />
                                </div>
                                <div className="submenu" style={{
                                    maxHeight: showFavorite ? `${favorites.length * 40}px` : '0',
                                    overflow: 'hidden',
                                    transition: 'all 0.3s ease-in-out'
                                }}>
                                    {favorites.map((fav, index) => (
                                        <div key={index} className="row" style={{ alignItems: "center", cursor: "pointer" }}>
                                            <FontAwesomeIcon size="2xs" icon={faCircle} style={{ margin: "0 0.4rem" }}/>
                                            <p style={{ margin: 0, fontSize: 14, flex: 1 }} onClick={()=>handleActiveForm(fav)}>{fav}</p>
                                            <FontAwesomeIcon icon={faStar} style={{ marginRight: 12, color: favorites.includes(fav) ? '#fffb00' : '' }} onClick={() => toggleFavorite(fav)} />
                                        </div>
                                    ))}
                                </div>
                                <div className="row menu-item align-center" onClick={() => setShowInfo(!showInfo)} style={{padding: "0.5rem 1rem"}}>
                                    <div className="row" style={{flex: 1}}>
                                        <FontAwesomeIcon style={{margin: 4, width: 28}} size='lg' icon={faDatabase} />
                                        <p style={{margin: 4, fontSize: 18}}>정보</p>
                                    </div>
                                    <FontAwesomeIcon icon={showInfo ? faChevronUp : faChevronDown} />
                                </div>
                                <div className="submenu" style={{
                                    maxHeight: showInfo ? '160px' : '0',
                                    overflow: 'hidden',
                                    transition: 'all 0.3s ease-in-out'
                                }}>
                                    {(isLogged || settings.CCTV) && (
                                        <>
                                            <div className="row" style={{alignItems: "center", cursor: "pointer"}}>
                                                <FontAwesomeIcon size="2xs" icon={faCircle}
                                                                 style={{margin: "0 0.4rem"}}/>
                                                <p style={{margin: 0, fontSize: 14, flex: 1}}
                                                   onClick={() => handleActiveForm("CCTV 보기")}>CCTV 보기</p>
                                                <FontAwesomeIcon style={{
                                                    marginRight: 12,
                                                    color: favorites.includes('CCTV 보기') ? '#fffb00' : ''
                                                }} icon={faStar} onClick={() => toggleFavorite('CCTV 보기')}/>
                                            </div>
                                        </>
                                    )}

                                    {(isLogged || settings.dataChart) && (
                                        <>
                                            <div className="row" style={{alignItems: "center", cursor: "pointer"}}>
                                                <FontAwesomeIcon size="2xs" icon={faCircle}
                                                                 style={{margin: "0 0.4rem"}}/>
                                                <p style={{margin: 0, fontSize: 14, flex: 1}}
                                                   onClick={() => handleActiveForm("종합 데이터 보기")}>종합 데이터 보기</p>
                                                <FontAwesomeIcon style={{marginRight: 12, color: favorites.includes('종합 데이터 보기') ? '#fffb00' : ''}} icon={faStar} onClick={() => toggleFavorite('종합 데이터 보기')}/>
                                            </div>
                                        </>
                                    )}
                                    {(isLogged || settings.sensorData) && (
                                        <>
                                            <div className="row" style={{alignItems: "center", cursor: "pointer"}}>
                                                <FontAwesomeIcon size="2xs" icon={faCircle} style={{margin: "0 0.4rem"}}/>
                                                <p style={{margin: 0, fontSize: 14, flex: 1}} onClick={()=>handleActiveForm("수위 데이터 보기")}>수위 데이터 보기</p>
                                                <FontAwesomeIcon style={{marginRight: 12, color: favorites.includes('수위 데이터 보기') ? '#fffb00' : ''}} icon={faStar} onClick={() => toggleFavorite('수위 데이터 보기')}/>
                                            </div>
                                        </>
                                    )}
                                    {(isLogged || settings.objectData) && (
                                        <>
                                            <div className="row" style={{alignItems: "center", cursor: "pointer"}}>
                                                <FontAwesomeIcon size="2xs" icon={faCircle} style={{margin: "0 0.4rem"}}/>
                                                <p style={{margin: 0, fontSize: 14, flex: 1}} onClick={()=>handleActiveForm("사물 인식 데이터 보기")}>사물 인식 데이터 보기</p>
                                                <FontAwesomeIcon style={{marginRight: 12, color: favorites.includes('사물 인식 데이터 보기') ? '#fffb00' : ''}} icon={faStar} onClick={() => toggleFavorite('사물 인식 데이터 보기')}/>
                                            </div>
                                        </>
                                    )}
                                </div>
                                { isLogged && (
                                    <>
                                        <div className="row menu-item align-center" onClick={() => setShowSettings(!showSettings)} style={{padding: "0.5rem 1rem"}}>
                                            <div className="row" style={{flex: 1}}>
                                                <FontAwesomeIcon style={{margin: 4, width: 28}} size='lg' icon={faCog} />
                                                <p style={{margin: 4, fontSize: 18}}>설정</p>
                                            </div>
                                            <FontAwesomeIcon icon={showSettings ? faChevronUp : faChevronDown} />
                                        </div>
                                        <div className="submenu" style={{
                                            maxHeight: showSettings ? '80px' : '0',
                                            overflow: 'hidden',
                                            transition: 'all 0.3s ease-in-out'
                                        }}>
                                            <div className="row" style={{alignItems: "center", cursor: "pointer"}}>
                                                <FontAwesomeIcon size="2xs" icon={faCircle} style={{margin: "0 0.4rem"}}/>
                                                <p style={{margin: 0, fontSize: 14, flex: 1}} onClick={()=>handleActiveForm("유저 설정")}>유저 설정</p>
                                                <FontAwesomeIcon style={{marginRight: 12, color: favorites.includes('유저 설정') ? '#fffb00' : ''}} icon={faStar} onClick={() => toggleFavorite('유저 설정')}/>
                                            </div>
                                            { userInfo.current.role === 1 && (
                                                <div className="row" style={{alignItems: "center", cursor: "pointer"}}>
                                                    <FontAwesomeIcon size="2xs" icon={faCircle}
                                                                     style={{margin: "0 0.4rem"}}/>
                                                    <p style={{margin: 0, fontSize: 14, flex: 1}}
                                                       onClick={() => handleActiveForm("임계점 설정")}>임계점 설정</p>
                                                    <FontAwesomeIcon style={{
                                                        marginRight: 12,
                                                        color: favorites.includes('임계점 설정') ? '#fffb00' : ''
                                                    }} icon={faStar} onClick={() => toggleFavorite('임계점 설정')}/>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}

                            </div>
                            <div name="logo" className="column full-size-width"
                                 style={{padding: "2rem", boxSizing: "border-box"}}>
                                <img src={logo} alt="logo"/>
                            </div>
                        </div>
                    </div>
                    <div name="mainForm" className="column" style={{
                        width: "88%",
                        height: "100%", position: "relative", color: "white", background: "#4f4f4f", boxSizing: "border-box", paddingBottom: activeForm.form !== "유저 설정" ? "1rem" : 0}}>
                        <GlobalContext.Provider value={{
                            focusLocation, setFocusLocation,
                            weatherData, setWeatherData,
                            isWeatherLoading, setIsWeatherLoading,
                            kakaoLoaded,
                            activeForm, setActiveForm
                        }}>
                            {activeForm.form === 'CCTV 보기' && (
                                <CCTV param={activeForm.param}/>
                            )}
                            {activeForm.form === '수위 데이터 보기' && (
                                <SensorData/>
                            )}
                            {activeForm.form === '사물 인식 데이터 보기' && (
                                <ObjectData/>
                            )}
                            {activeForm.form === '통합관제' && (
                                <Home/>
                            )}
                            {activeForm.form === '종합 데이터 보기' && (
                                <Datachart/>
                            )}
                            {activeForm.form === '유저 설정' && (
                                <Setting/>
                            )}
                            {activeForm.form === '임계점 설정' && (
                                <AlertSetting />
                            )}
                        </GlobalContext.Provider>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Main;
